import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
// import axios from "axios";
import { connect } from "react-redux";
import {getAllScreens, addScreensWithExcel} from "../../actions/screenActions";

class AddScreensWithExcel extends Component {

    state = {
        file: '',
        fileName: '',
        show: false
    }

    onChange = (e) => {
        this.setState({
            file: e.target.files[0],
            fileName: e.target.files[0].name
        });
    }

    handleClose = () => {
        this.setState({
            show: false
        })
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    onSubmit = async e => {
        e.preventDefault();
        console.log("Submit :", this.state);

        const formData = new FormData();
        formData.append('file', this.state.file)

        // try {

        //     const res = await axios.post(`${URL}`, formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //             authorization: localStorage.getItem('token')
        //         }
        //     })

        // } catch (err) {
        //     console.log(err);
        // }

        // // console.log(formData);

        this.props.addScreensWithExcel(formData, () =>{
            this.props.getAllScreens();
            this.setState({show : false});
        })
    }

    render() {
        return (
            <div>
                <h4>Add Screen with Excel</h4>
                <button
                    className="btn btn-info btn-lg"
                    onClick={this.handleShow}
                >
                    <i className="fa fa-plus"></i>
                </button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header>
                        Screen Actions
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row">
                            <div className="col-md-8">
                                <p>Please use this excel data template to fill and upload.</p>
                            </div>
                            <div className="col-md-4">

                                <a 
                                    href="https://screenodata.s3-ap-southeast-1.amazonaws.com/ScreenUploadTemplate/template.xlsx" 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <button className="btn btn-success">
                                        <i className="fa fa-download"></i>
                                    </button>
                                </a>
                            </div>
                            
                        </div>
                        <hr />
                        <div>

                            <form onSubmit={this.onSubmit}>
                                <div className="custom-file">
                                    <label>Upload Excel</label>
                                    <input
                                        type="file"
                                        onChange={this.onChange}
                                    />
                                    <button className="btn btn-success mt-2">
                                        <i className="fa fa-upload"></i> Upload
                                    </button>
                                </div>
                            </form>
                        </div>


                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}


export default connect(null, {getAllScreens, addScreensWithExcel})(AddScreensWithExcel);
