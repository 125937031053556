import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { fetchAdvertiserCampaign, updateAdvertiserCampaign} from '../../actions/campaignActions';

function pad(n) {
	return n<10 ? '0'+n : n
}

class UpdateCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }

    handleFromChange(e) {
        this.setState({ startDate: e.target.value });
    }

    handleToChange(e) {
        this.setState({ endDate: e.target.value });
    }

    handleNameChange(e) {
        this.setState({ campaignName: e.target.value })
    }

    show() {
        var f_date = new Date(this.props.campaign_data.startDate);
        var t_date = new Date(this.props.campaign_data.endDate);
        var from_date = f_date.getFullYear() + "-" + pad(f_date.getMonth() + 1) + "-" + pad(f_date.getDate()) + "T" + pad(f_date.getHours()) + ":" + pad(f_date.getMinutes());
        var to_date = t_date.getFullYear() + "-" + pad(t_date.getMonth() + 1) + "-" + pad(t_date.getDate()) + "T" + pad(t_date.getHours()) + ":" + pad(t_date.getMinutes());
        this.setState({
            showModal: true,
            campaignName: this.props.campaign_data.campaignName,
            startDate: from_date,
            endDate: to_date,
            _id: this.props.campaign_data._id,
            screens: this.props.campaign_data.screens
        })
    }

    close() {
        this.setState({ showModal: false });
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.updateAdvertiserCampaign(this.state).then(() => {
            this.props.fetchAdvertiserCampaign();
            this.close();
        })
    }

    render() {
        return (
            <div>
                <button type="button" className="center-align btn btn-info" onClick={this.show.bind(this)}>
                    <i className="fa fa-edit"></i>
                </button>

                <Modal show={this.state.showModal} onHide={this.close.bind(this)}>
                    <Modal.Header className="bg-success">
                        <Modal.Title>
                            <div className="text-center mtb-0">Update Campaign Details</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <div>
                                <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group row">
                                            <label htmlFor="playlist_name" className="col-md-4 col-form-label"><b>PlayList Name:</b></label>
                                            <div className="col-md-6">
                                                <input name="playlist_name" value={this.state.campaignName} placeholder="PlayList Name" className="form-control" type="text" onChange={this.handleNameChange.bind(this)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="schedule_from" className="col-md-4 col-form-label"><b>Schedule From:</b></label>                                        
                                            <input type="datetime-local" className="form-control col-md-6" name="schedule_from" value={this.state.startDate}
                                            onChange={this.handleFromChange.bind(this)}  />
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="schedule_to" className="col-md-4 col-form-label"><b>Schedule To:</b></label>
                                            <input type="datetime-local" name="schedule_to" className="form-control col-md-6" value={this.state.endDate}
                                            onChange={this.handleToChange.bind(this)}  />
                                        </div>
                                    </div>                                 
                                </div>
                                <div className="row col-md-4 col-md-offset-4">
                                    <button className="btn btn-raised btn-success mr-15" onClick={this.onSubmit.bind(this)}><i className="glyphicon glyphicon-ok"></i></button>
                                    <button type="button" className="btn btn-warning" onClick={this.close.bind(this)}><i className="glyphicon glyphicon-remove"></i></button>
                                </div>
                                </form>
                            </div>
                         </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { fetchAdvertiserCampaign, updateAdvertiserCampaign })(UpdateCampaign);