import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchUserCampaigns, uploadCampaignInvoice } from "../../actions/campaignActions";
import Select from "react-select";

class InvoiceUpload extends Component {

  state = {
    campaign : null,
    invoice : "",
    filename : ""
  }

  componentDidMount(){
    this.props.fetchUserCampaigns();
  }

  onSubmit = async (e) => {
    e.preventDefault();
    // console.log("Data");

    const formData = new FormData();

    formData.append('invoice', this.state.invoice);
    formData.append('campaignName', this.state.campaign.campaignName);
    formData.append('startDate', this.state.campaign.startDate);
    formData.append('endDate', this.state.campaign.endDate);
    formData.append('id', this.state.campaign._id);
    

    this.props.uploadCampaignInvoice(formData, () => {
      this.setState({
        campaign : null,
        invoice : "",
        filename: ""
      });
    });


  }

  onChangeSelect = (value, reason) => {
    
    this.setState({
      ...this.state,
      campaign : value
    });
  }

  onChangeInvoice = (e) => {
    // console.log(e.target.files[0].name);
    this.setState({
      ...this.state,
      invoice : e.target.files[0],
      filename : ""
    });
  }

  render() {

    // console.log("This porps :", this.props.campaignForInvoice);
    return (
      <div>
        <h2>Campaign Invoice Upload</h2>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <Select
              value={this.state.campaign}
              options={this.props.campaignForInvoice}
              getOptionLabel={option => option.campaignName }
              getOptionValue={option => option.campaignName}
              onChange={this.onChangeSelect}
              // className="form-group-item"
              placeholder="Select Campaign"
            />
          </div>
          <div className="form-group">
            <label htmlFor="invoice">Select Invoice file</label>
            <input type="file" id="invoice" name="invoice" onChange={this.onChangeInvoice} value={this.state.filename}/>
              {/* <p className="help-block">Example block-level help text here.</p> */}
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>

        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {

  // console.log("State :", state);
  return {
    campaignForInvoice : state.campaign.campaignForInvoice
  }
}

export default connect(mapStateToProps, {fetchUserCampaigns, uploadCampaignInvoice})(InvoiceUpload);