import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ListGroupItem, Button, Tabs, Tab } from 'react-bootstrap';
import { getAllInventoryScreens, markerClickedAction, addToCart } from '../../actions/inventoryActions';
import { withRouter } from "react-router";

class InventoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_inventory_screens: []
        }
    }

    componentWillMount() {
        this.props.getAllInventoryScreens();
        this.props.addToCart(new Set());
    }

    handleMarketResetClick() {
        this.props.markerClickedAction("");
    }

    handleAddToCart(item, index, event) {
        // console.log("item :", item);
        const { isAuthenticated, addToCart } = this.props;
        if (!isAuthenticated) {
            this.props.router.push("/login");
        } else {
            addToCart(this.props.cart.add(item));
        }
    }

    handleRemoveCart(item, index, event) {

        // console.log("delete item", item);

        this.props.cart.delete(item)
        this.props.addToCart(this.props.cart);
    }

    returnMonthlyCost = (cost) => {
        if(parseInt(cost) === 0){
            return "TBA";
        } else {
            return cost;
        }
    }

    render() {

        const { all_inventory_screens, filtered_data, marker_data } = this.props;

        // console.log("marker_data",marker_data);
        // console.log("All Inventory :->", all_inventory_screens);

        return (
            <div className="all_inventory">
                <div className="row">
                    {marker_data === "" && filtered_data.length === 0 &&
                        all_inventory_screens.map((item, index) => (
                            <div key={index} className="col-md-12">
                                <div className="thumbnail">
                                    <img src={item.file_url ? item.file_url : "/img/no-image.jpg"} alt="screen_image" />
                                    <div className="caption">
                                        <h3>{item.screen_name}</h3>
                                        <Tabs defaultActiveKey={1} id="tab_inventory_details">
                                            <Tab eventKey={1} title="Info">
                                                <ListGroupItem bsStyle="success">FootFall : {item.footfall}</ListGroupItem>
                                                <ListGroupItem bsStyle="info">Impressions : {item.impressions}</ListGroupItem>
                                                <ListGroupItem bsStyle="success">Monthly cost : {this.returnMonthlyCost(item.monthly_cost)}</ListGroupItem>
                                            </Tab>
                                            <Tab eventKey={2} title="More Info">
                                                <ListGroupItem bsStyle="success">Location : {item.location}</ListGroupItem>
                                                <ListGroupItem bsStyle="info">Network : {item.network}</ListGroupItem>
                                                <ListGroupItem bsStyle="success">Category : {item.category}</ListGroupItem>
                                            </Tab>
                                        </Tabs>
                                        {!this.props.cart_array.find(cart_item => { return cart_item._id === item._id }) ?
                                            <Button bsClass="btn btn-block btn-primary mt-1" onClick={this.handleAddToCart.bind(this, item, index)}>Add to Cart</Button>
                                            : <Button bsClass="btn btn-block btn-danger mt-1" onClick={this.handleRemoveCart.bind(this, item, index)}>Remove from Cart</Button>}
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    {marker_data === "" && filtered_data.length !== 0 &&
                        filtered_data.map((item, index) => (
                            <div key={index} className="col-md-12">
                                <div className="thumbnail">
                                    <img src={item.file_url ? item.file_url : "/img/no-image.jpg"} alt="screen_image" />
                                    <div className="caption">
                                        <h3>{item.screen_name}</h3>
                                        <Tabs defaultActiveKey={1} id="tab_inventory_details">
                                            <Tab eventKey={1} title="Info">
                                                <ListGroupItem bsStyle="success">FootFall : {item.footfall}</ListGroupItem>
                                                <ListGroupItem bsStyle="info">Impressions : {item.impressions}</ListGroupItem>
                                                <ListGroupItem bsStyle="success">Monthly cost : {this.returnMonthlyCost(item.monthly_cost)}</ListGroupItem>
                                            </Tab>
                                            <Tab eventKey={2} title="More Info">
                                                <ListGroupItem bsStyle="success">Location : {item.location}</ListGroupItem>
                                                <ListGroupItem bsStyle="info">Network : {item.network}</ListGroupItem>
                                                <ListGroupItem bsStyle="success">Cluster : {item.category}</ListGroupItem>
                                            </Tab>
                                        </Tabs>
                                        {!this.props.cart_array.find(cart_item => { return cart_item._id === item._id }) ?
                                            <Button bsClass="btn btn-block btn-primary mt-1" onClick={this.handleAddToCart.bind(this, item, index)}>Add to Cart</Button>
                                            : <Button bsClass="btn btn-block btn-danger mt-1" onClick={this.handleRemoveCart.bind(this, item, index)}>Remove from Cart</Button>}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        this.props.marker_data &&
                        <div className="col-md-12">
                            <btn className="btn btn-block btn-danger mb-2" onClick={this.handleMarketResetClick.bind(this)}>Reset Marker Selection</btn>
                            <div className="thumbnail">
                                <img src={this.props.marker_data.file_url ? this.props.marker_data.file_url : "/img/no-image.jpg"} alt="screen_image" />
                                <div className="caption">
                                    <h3>{this.props.marker_data.screen_name}</h3>
                                    <Tabs defaultActiveKey={1} id="tab_inventory_details">
                                        <Tab eventKey={1} title="Info">
                                            <ListGroupItem bsStyle="success">FootFall : {this.props.marker_data.footfall}</ListGroupItem>
                                            <ListGroupItem bsStyle="info">Impressions : {this.props.marker_data.impressions}</ListGroupItem>
                                            <ListGroupItem bsStyle="success">Monthly cost : {this.returnMonthlyCost(this.props.marker_data.monthly_cost)}</ListGroupItem>
                                            
                                        </Tab>
                                        <Tab eventKey={2} title="More Info">
                                            <ListGroupItem bsStyle="success">Location : {this.props.marker_data.location}</ListGroupItem>
                                            <ListGroupItem bsStyle="info">Network : {this.props.marker_data.network}</ListGroupItem>
                                            <ListGroupItem bsStyle="success">Cluster : {this.props.marker_data.category}</ListGroupItem>
                                        </Tab>
                                    </Tabs>
                                    {!this.props.cart_array.find(cart_item => { return cart_item._id === this.props.marker_data._id }) ?
                                        <Button bsClass="btn btn-block btn-primary mt-1" onClick={this.handleAddToCart.bind(this, this.props.marker_data)}>Add to Cart</Button>
                                        : <Button bsClass="btn btn-block btn-danger mt-1" onClick={this.handleRemoveCart.bind(this, this.props.marker_data)}>Remove from Cart</Button>}
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
        )
    }
}

function mapStateToProps({ inventory, auth }) {
    // console.log(inventory)
    const { cart, get_filtered_screens, get_all_inventory_screens, marker_data } = inventory;
    let cart_array = Array.from(cart);

    return {
        filtered_data: get_filtered_screens,
        all_inventory_screens: get_all_inventory_screens,
        marker_data: marker_data,
        cart: cart,
        cart_array,
        isAuthenticated: auth.isAuthenticated
    }
}

const InventoryListWithRouter = withRouter(InventoryList);

export default connect(mapStateToProps, { getAllInventoryScreens, markerClickedAction, addToCart })(InventoryListWithRouter);