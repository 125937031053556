import axios from 'axios';
import extra from '../utils/extra';

import { GET_ALL_INVENTORY_SCREENS, GET_FILTERED_INVENTORY, MARKER_CLICKED, SET_ADVERTISERS_USER_SCREEN, ADD_TO_CART,
    SET_FILTER_LOCATION_STRUCT, FETCH_INVENTORY_FILTER_OPTIONS, SET_CITY_FILTER_OPTIONS,
    SET_STATE_FILTER_OPTIONS
} from './types';

// This one is not in use
export function get_all_inventory_screens(payload) {
    return {
        type: GET_ALL_INVENTORY_SCREENS,
        payload
    }
}

export function markerClicked(payload) {

    // console.log("MarketClicked :", payload);
    return {
        type: MARKER_CLICKED,
        payload
    }
}


export function setAdvertiserUserScreens(payload) {
    return {
        type: SET_ADVERTISERS_USER_SCREEN,
        payload
    }
}

export function get_filtered_inventory(payload) {
    return {
        type: GET_FILTERED_INVENTORY,
        payload
    }
}

// This method is is not in use.
export function add_to_cart(payload) {
    return {
        type: ADD_TO_CART,
        payload
    }
}


export function getAllInventoryScreens() {
    // console.log("Token :", localStorage.getItem('token'));
    return (dispatch => {
        return axios.post(extra.rootURL + '/api/getAllInventoryScreens', {},{
            headers : { authorization: localStorage.getItem('token') }
        }).then((response) => {

            let cities = new Set();
            let states = new Set();
            let networks = new Set();
            let categories = new Set();
            let network_names = new Set();
            
            // console.log("Inventory Response :", response.data);
            response.data.data.forEach(item => {
                cities.add(item.city);
                states.add(item.state);
                networks.add(item.network);
                categories.add(item.category)
                network_names.add(item.network_name)
            });

            cities = Array.from(cities).map(x => {
                return {
                    value : x,
                    label : x
                }
            });

            states = Array.from(states).map(x => {
                return {
                    value : x,
                    label : x
                }
            });

            networks = Array.from(networks).map(x => {
                return {
                    value : x,
                    label : x
                }
            });

            categories = Array.from(categories).map(x => {
                return {
                    value : x,
                    label : x
                }
            });

            network_names = Array.from(network_names).map(x => {
                return {
                    value : x,
                    label : x
                }
            });

            // dispatch({
            //     type : SET_INVENTORY_FILTER_OPTIONS,
            //     payload :{
            //         states,
            //         cities,
            //         networks,
            //         network_names,
            //         categories,
            //         defaultCity : cities
            //     }
            // });

            dispatch(get_all_inventory_screens(response.data.data));
            dispatch({
                type : SET_FILTER_LOCATION_STRUCT,
                payload : response.data.locationStruct
            });
        }).catch((error) => {
            console.log(error);
        });
    })
}
// export function getAllInventoryScreens(){
//     return (dispatch=>{
//         return axios.post(extra.rootURL+'/api/getAllInventoryScreens', null, {headers: { authorization: localStorage.getItem('token')}}).then((response)=>{
//             dispatch(get_all_inventory_screens(response.data.data));
//         }).catch((error)=>{
//             console.log(error);
//         });
//     })
// }

export const updateInventoryDetails = (screen_id, details, file_url, cb) => async dispatch =>  {

    await  axios.post(`${extra.rootURL}/api/updateInventoryDetails`, {screen_id, details, file_url}, {
        headers : { authorization: localStorage.getItem('token') }
     });

    //  console.log(response.data);
     cb();
}

export function markerClickedAction(markerdata) {
    return dispatch => {
        dispatch(markerClicked(markerdata));
    }
}

export function addInventoryScreenToUserScreen(inventory_id) {
    return dispatch => {
        return axios.post(extra.rootURL + '/api/addInventoryScreenToUserScreen', { inventory_id: inventory_id }, { headers: { authorization: localStorage.getItem('token') } })
    }
}

export function addToCart(item) {
    return dispatch => {
        dispatch({
            type: ADD_TO_CART,
            payload: item
        })
    }
}

export function removeInventoryScreenToUserScreen(inventory_id) {
    return dispatch => {
        return axios.post(extra.rootURL + '/api/removeInventoryScreenToUserScreen', { inventory_id: inventory_id }, { headers: { authorization: localStorage.getItem('token') } })
    }
}

export function fetchAdvertiserUserScreens() {
    return dispatch => {
        return axios.post(extra.rootURL + '/api/fetchAdvertiserUserScreens', null, { headers: { authorization: localStorage.getItem('token') } }).then(response => {
            if (response.data.data) {
                dispatch(setAdvertiserUserScreens(response.data.data));
            }
        })
    }
}

export function getFilteredInventory(country, state, city, network, network_name, category, impressions, footfall, all_inventory) {

    // console.log("impressions :", impressions);
    // console.log("category :", category);
    // console.log("network :", network);
    // console.log("network_name :", network_name);
    // console.log("State", state);
    // console.log("city :", city);
    
    return (dispatch => {
        if (all_inventory) {
            let temp;

            temp = all_inventory.filter(o => {
                return (category !== "" ? o.category === category : true)
                    && (network !== "" ? o.network === network : true)
                    && (country !== "" ? o.country === country : true)
                    && (network_name !== "" ? o.network_name === network_name : true)
                    && (state !== "" ? o.state === state : true)
                    && (city !== "" ? o.city === city : true)
                    && (impressions !== "" ? parseInt(o.impressions, 10) >= parseInt(impressions, 10) : true)
                    && (footfall !== "" ? parseInt(o.footfall, 10) >= parseInt(footfall, 10) : true);
            })

            // console.log("temp :", temp);

            return dispatch(get_filtered_inventory(temp));
        }
    })
}

export const fetchInventoryFilter = () => async dispatch =>{

    try {
        const response = await axios.get(`${extra.rootURL}/api/fetchInventoryFilterOptions`, {
            headers: { authorization: localStorage.getItem('token')}
        });
        // console.log("Reposnes", response.data);

        const { category, cities, country, network, network_name, states } = response.data;

        const payload = {
            category : category.map(c => { 
                return { label : c , value : c} 
            }),
            cities : cities.map(c => {
                return { label : c, value : c } 
            }),
            country : country.map(c => {
                return { label : c, value : c } 
            }),
            network : network.map(c => {
                return { label : c, value : c } 
            }),
            network_name : network_name.map(c => {
                return { label : c, value :c  } 
            }),
            states : states.map(c => {
                return { label : c , value : c} 
            })
        }

        // payload.ca

        dispatch({
            type: FETCH_INVENTORY_FILTER_OPTIONS,
            payload
        });
        
    } catch(err){
        console.log(err);
    }
}

export const setCityFilterOptions = (data) => dispatch => {

    dispatch({
        type : SET_CITY_FILTER_OPTIONS,
        payload : data
    });


}

export const setStateFilterOptions = (data) => dispatch => {

    dispatch({
        type : SET_STATE_FILTER_OPTIONS,
        payload : data
    });
}

