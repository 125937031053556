import axios from "axios";
import { rootURL } from "../utils/extra";
import { PLAYLIST_SYNC_UPDATE } from "./types";

export const playlistSyncUpdate = (data, cb) => async dispatch => {

    console.log("{...data}", {...data});

    try {
        
        const result = await axios.post(`${rootURL}/api/copyto-screens`, {...data}, {
            headers : {
                authorization : localStorage.getItem("token")
            }
        });
    
        dispatch({
            type: PLAYLIST_SYNC_UPDATE,
            playload : result.data
        });
    
        cb();

    } catch(e){
        console.log(e);
    }


}