import axios from "axios";
import extra, { rootURLAppLogs } from "../utils/extra";
import { GET_ALL_SCREENSHOT } from "./types";

export function get_all_screenshot(payload) {
	return {
		type: GET_ALL_SCREENSHOT,
		payload,
	};
}

export function getImpressionsExcel(screen_name, start_date, end_date) {
	return (dispatch) => {
		return axios.post(
			extra.rootURL + "/api/getImpressionsExcel",
			{ screen_name: screen_name, start_date: start_date, end_date: end_date },
			{ headers: { authorization: localStorage.getItem("token") } }
		);
	};
}

export function getImpressionData(screen_name) {
	return (dispatch) => {
		return axios.post(
			extra.rootURL + "/api/getImpressionData",
			{ screen_name: screen_name },
			{ headers: { authorization: localStorage.getItem("token") } }
		);
	};
}

export function getAllScreenshots(screen_name, user_id, cb) {
	return (dispatch) => {
		return axios
			.post(
				extra.rootURL + "/api/getAllScreenshots",
				{ screen_name: screen_name, user_id: user_id },
				{ headers: { authorization: localStorage.getItem("token") } }
			)
			.then((data) => {
				dispatch(get_all_screenshot(data.data));
				cb();
			});
	};
}

export function getlastLog(screen_name) {
	return (dispatch) => {
		const state = JSON.parse(localStorage.getItem("state"));

		console.log(state.auth.user.company_name);

		let url = rootURLAppLogs + "/screeno_api/lastLog";

		if (state.auth.user.company_name === "Indus") {
			url = "https://indus-screeno-server.loca.lt/api/lastLog";
		}

		return axios.post(url, { screen_name }, { headers: { authorization: localStorage.getItem("token") } });
	};
}
