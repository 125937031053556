import React, { Component } from "react";
import mqttClient from "../../utils/mqtt_config";
import { connect } from "react-redux";
import { getlastLog } from "../../actions/screenControllerAction";

let id;

class CheckStatusButton extends Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
	}

	state = {
		screen_status: "Check Status"
	};

	componentDidMount() {
		this._isMounted = true;
		this.autoCheckStatusMQTT();
	}

	componentWillUnmount() {
		this._isMounted = false;
		clearTimeout(id);
	}

	autoCheckStatusMQTT = () => {
		const { screen_name, user_id } = this.props;

		this.setState({ screen_status: "Check Status" });

		mqttClient.subscribe(`cmd_check_status_${user_id}_${screen_name}`);

		mqttClient.on("message", (topic, iotData) => {

			if (topic.includes(screen_name)) {
				const iotDataJSON = JSON.parse(iotData.toString());
				if (iotDataJSON.status === "Online") {
					if (this._isMounted) {
						this.setState({ screen_status: "Online" });
					}
				}
			}
		});

		mqttClient.publish(
			`cmd_${screen_name}_${this.props.user_id}`,
			JSON.stringify({ message: { cmd: "check_status" } })
		);
	}

	handleCheckStatusClick = () => {
		const { screen_name, getlastLog } = this.props;

		this.autoCheckStatusMQTT();

		getlastLog(screen_name).then(result => {
			let { lastLog } = result.data;
			lastLog = lastLog === "" ? "" : new Date(lastLog).toLocaleString();
			this.setState({ lastLog });
		});
	};

	render() {
		const { screen_status, lastLog } = this.state;

		return (
			<button
				onClick={this.handleCheckStatusClick.bind(this)}
				className={`auto_margin2 btn ${screen_status === "Online" &&
					"btn-success"}`}
			>
				<i className="fa fa-signal" />{" "}
				{lastLog === "" ? screen_status : lastLog}
			</button>
		);
	}
}

function mapStateToProps(state) {
	return {
		user_id: state.auth.user.token.sub
	};
}

export default connect(
	mapStateToProps,
	{ getlastLog }
)(CheckStatusButton);
