import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
// import extra from '../../utils/extra';
import { CSVLink } from "react-csv";
import { getAllScreens } from "../../actions/screenActions";
import mqttClient from "../../utils/mqtt_config";

// var generateLogs=[['Date', 'Screen Name', 'Status' ]];
let subscribe = false;
// var counter = 0;
function event_name(event_id) {
	const id = Number(event_id);
	let result = "";
	switch (id) {
		case 0: {
			result = "Player Paired";
			break;
		}
		case 1: {
			result = "Unpairing Player";
			break;
		}
		case 2: {
			result = "Player Refreshed";
			break;
		}

		case 3: {
			result = "Unpaired Player Loaded";
			break;
		}
		case 4: {
			result = "Playlist In Progress";
			break;
		}
		case 5: {
			result = "Playlist Started";
			break;
		}
		case 6: {
			result = "Playlist Stopped";
			break;
		}
		case 7: {
			result = "Playing Content";
			break;
		}
		case 8: {
			result = "Paired Screen";
			break;
		}
		default:
			break;
	}

	return result;
}

class ScreenLogsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			modalData: "",
			msg: [],
			subscribe: false
		};
	}

	show() {
		this.setState({ modal: true });
		mqttClient.publish(
			`cmd_${this.props.screen_name}_${this.props.user_id}`,
			JSON.stringify({ message: { cmd: "start_live_logs" } })
		);

		if (subscribe === false) {
			mqttClient.subscribe(
				`cmd_sendlog_${this.props.user_id}_${this.props.screen_name}`,
				() => {
					console.log(`subscribed to receive logs`);
				}
			);
			subscribe = true;
		}

		mqttClient.on("message", (topic, iotData) => {
			if (
				topic === `cmd_sendlog_${this.props.user_id}_${this.props.screen_name}`
			) {
				const data = JSON.parse(iotData.toString());
				if (data.msg) {
					let msg = [...this.state.msg];
					if (this.props.screen_name === data.msg[1]) {
						let localMsg = data.msg;
						localMsg[2] = event_name(localMsg[2]);
						// new addition
						let flag = true;

						msg.forEach(msg => {
							if (msg[0] === localMsg[0]) {
								flag = false;
							}
						});

						if (flag) {
							msg.unshift(localMsg);
						}
						// till here
						// msg.push(localMsg);    // old logic
						this.setState({ msg: msg });
					}
				}
			}
		});
	}

	close() {
		this.setState({ modal: false, msg: [] });
		mqttClient.publish(
			`cmd_${this.props.screen_name}_${this.props.user_id}`,
			JSON.stringify({ message: { cmd: "stop_live_logs" } })
		);
	}

	handleRefresh() {
		mqttClient.publish(
			`cmd_${this.props.screen_name}_${this.props.user_id}`,
			JSON.stringify({ message: { cmd: "refresh" } })
		);
	}

	render() {
		return (
			<div>
				<button
					className="auto_margin2 btn btn-primary"
					onClick={this.show.bind(this)}
				>
					<i className="fa fa-files-o" />
				</button>
				<Modal
					className="logsModal"
					show={this.state.modal}
					onHide={this.close.bind(this)}
				>
					<Modal.Header className="bg-success padding_zero">
						<h3>
							<i className="fa fa-copy" /> Logs
						</h3>
					</Modal.Header>
					<Modal.Body className="bgcolor2">
						<div className="row">
							<div className="col-md-12">
								<div className="col-md-6">
									<button
										className="btn refreshlog"
										onClick={this.handleRefresh.bind(this)}
									>
										<i className="fa fa-refresh" /> Refresh
									</button>
								</div>
								<div className="col-md-6">
									<button className="btn btn-primary">
										<i className="fa fa-files-o" />
									</button>
									<CSVLink data={this.state.msg}>
										<span className="downexcel">
											<i className="fa fa-download" /> Download logs
										</span>
									</CSVLink>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="mfooter">
									<div className="container-fluid">
										<table className="table table-hover table-bordered table-full-width">
											<thead>
												<tr>
													<th>#</th>
													<th>Date</th>
													<th>Playlist Name</th>
													<th>Status</th>
													<th>Content</th>
												</tr>
											</thead>
											<tbody>
												{this.state.msg.map((item, id) => (
													<tr className="first-child" key={id}>
														<td className="pr-5">
															<i className="fa fa-arrow-circle-right" />
														</td>
														<td> {item[0]}</td>
														<td> {item[4]}</td>
														<td> {item[2]}</td>
														<td> {item[3]}</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div className="text-center">
							<button
								type="button"
								className="btn btn-warning"
								onClick={this.close.bind(this)}
							>
								<b>Close</b>
							</button>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		screen_data_array: state.screen.get_all_screen_data,
		user_id: state.auth.user.token.sub
	};
}

export default connect(
	mapStateToProps,
	{ getAllScreens }
)(ScreenLogsModal);
