import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, FormGroup } from 'react-bootstrap';
import {removeAdvertiserCampaign, fetchAdvertiserCampaign} from '../../actions/campaignActions';

class RemoveCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }

    openModal() {
        this.setState({ showModal: true });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    onSubmit(e) {
        this.props.removeAdvertiserCampaign(this.props.campaign_data._id).then(() => {
            this.closeModal();
            this.props.fetchAdvertiserCampaign();
        });
    }

    render() {
        return (
            <div>
                <button type="button" className="center-align btn btn-danger" onClick={this.openModal.bind(this)}>
                    <i className="fa fa-close"></i>
                </button>

                <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)}>
                    <Modal.Header className="bg-success">
                        <Modal.Title>
                            <div className="text-center mtb-0">Please confirm that you want to remove Campaign</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="pull-right">
                                        <button type="submit" className="btn btn-lg btn-info" onClick={this.onSubmit.bind(this)}>Confirm</button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="col-md-offset-1">
                                        <button type="button" className="btn btn-lg btn-warning" onClick={this.closeModal.bind(this)}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </FormGroup>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { removeAdvertiserCampaign, fetchAdvertiserCampaign })(RemoveCampaign);