import { 
	GET_REPORTS, SET_SELECTED_TAB, GET_LIVE_LOGS_REPORT, SET_SCREEN_CREATIVE_LIST, SET_CREATIVE_SCREEN_LIST,
	UPDATE_SCREEN_IMPRESSION_COUNT, UPDATE_CREATIVE_COUNT_IN_SCREEN
} from "../actions/types";

const initState = { 
	reports_array: [],
	selected_tab: "list-report",
	liveLogs : [],
	contentCount : "",
	screenCreativeList : [],
	creativeScreenList : []
};

export default (state = initState, action) => {

	const { payload  } = action;
	switch (action.type) {
		case GET_REPORTS: {
			return Object.assign({}, state, { reports_array: action.payload });
		}
		case SET_SELECTED_TAB: {
			return Object.assign({}, state, { selected_tab: action.payload });
		}

		case GET_LIVE_LOGS_REPORT :
			return {
				...state,
				liveLogs : payload.liveLogs,
				contentCount : payload.contentCount
			}
		case SET_SCREEN_CREATIVE_LIST :
			return {
				...state,
				screenCreativeList : payload
			}
		case SET_CREATIVE_SCREEN_LIST :
			return {
				...state,
				creativeScreenList : payload
			}
		case UPDATE_SCREEN_IMPRESSION_COUNT :
			return {
				...state,
				screenCreativeList : state.screenCreativeList.map( s => s.screen_name === payload.screen_name ? payload : s)
			}
		case UPDATE_CREATIVE_COUNT_IN_SCREEN:{
			
			// console.log("payalod :", payload);
			return {
				...state,
				screenCreativeList : state.screenCreativeList.map(s => {

					if(s.screen_name === payload.screen_name){

						let creatives = [...s.creatives];
						let { count } = payload;

						let time;

						creatives = creatives.map(cr => {

							if(cr.original_name === payload.content_name){
								time = parseFloat(cr.duration) * count;
								return {
									...cr,
									impression_count : count,
									publisher_impression_count : count,
									total_playtime : parseFloat(cr.duration) * count,
									publisher_playtime : parseFloat(cr.duration) * count

								}
							} else {
								return cr;
							}
						});

						// console.log("payload :", payload.content_name, "creatives", creatives);
						// console.log("payload :", payload.content_name);

						if(s.total_playtime !== ""){
							return {
								...s,
								total_playtime : s.total_playtime + time,
								publisher_playtime : s.total_playtime + time,
								creatives
							}
						} else {
							return {
								...s,
								total_playtime : time,
								publisher_playtime : time,
								creatives
							}
						}

					} else {
						return s;
					}
				})
			}
		}
		default:
			return state;
	}
};
