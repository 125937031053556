import axios from "axios";
// import Reports_new from "../components/Reports/Reports_new";
import extra, { rootURL, rootURLAppLogs } from "../utils/extra";
import {
	GET_REPORTS, SET_SELECTED_TAB, GET_LIVE_LOGS_REPORT, SET_SCREEN_CREATIVE_LIST, UPDATE_SCREEN_IMPRESSION_COUNT, UPDATE_CREATIVE_COUNT_IN_SCREEN
 } from "./types";

export const getReports = () => dispatch => {
	axios
		.get(extra.rootURL + "/api/getReports", {
			headers: { authorization: localStorage.getItem("token") }
		})
		.then(({ data }) => {
			dispatch({
				type: GET_REPORTS,
				payload: data
			});
		})
		.catch(err =>
			dispatch({
				type: GET_REPORTS,
				payload: []
			})
		);
};

export const generateReportV2 = (reportType, values, start, end) => dispatch => {
	
	axios.post(rootURLAppLogs + "/screeno_api/generateReportV2", { reportType, values, start, end }, { headers: { authorization: localStorage.getItem("token") } })
		.then(result => {
			console.log(result.data);
			dispatch({
				type: SET_SELECTED_TAB,
				payload: "list-report"
			});
		}, error => {
			console.log(error);
		});
};

export const handleReportTabChange = seleted_tab => dispatch => {
	dispatch({
		type: SET_SELECTED_TAB,
		payload: seleted_tab
	});
};

export const deleteReport = reportId => dispatch => {
	axios.post(extra.rootURL + "/api/deleteReport", { reportId }, { headers: { authorization: localStorage.getItem("token") } })
		.then(result => {
			getReports()(dispatch);
		}, error => {
			console.log(error);
		});
}

export const fetchLiveStats = (data) => async dispatch => {

	// console.log("Data", data);

	try {
		const response = await axios.post(`${rootURLAppLogs}/screeno_api/getLiveLogStatsForCreative`, {
			data
		}, {
			headers : { authorization: localStorage.getItem("token") }
		});

		// console.log("responseEl :", response.data);

		dispatch({
			type : GET_LIVE_LOGS_REPORT,
			payload : {
				contentCount : response.data.count.count,
				liveLogs : response.data.data
			}
		});
	} catch(e){
		console.log(e);
	}
}

export const getScreenCreativeList = () => async dispatch => {

	try {

		const response = await axios.get(`${rootURL}/api/getScreenCreativeList`, {
			headers : { authorization: localStorage.getItem("token") }
		});

		// console.log("Rdata :", response.data);

		dispatch({
			type : SET_SCREEN_CREATIVE_LIST,
			payload : response.data
		});

	} catch(e) {
		console.log(e);
	}
}



export const fetchScreenImpressionCount = (data) => async dispatch => {

	// console.log("Data to fetch", data);

	try {

		const response = await axios.post(`${rootURLAppLogs}/screeno_api/getImpressionCountsForScreen`, {
			data
		}, {
			headers : { authorization: localStorage.getItem("token") }
		});

		// console.log(data.screen_name, response.data.count);

		let payload = {
			...data,
			impression_count : response.data.count,
			publisher_impression_count : response.data.count
		};

		// console.log("Payload :", payload);

		dispatch({
			type : UPDATE_SCREEN_IMPRESSION_COUNT,
			payload
		});

		const { count } = response.data;

		
		if(data.creatives && count > 0){

			data.creatives.forEach(creative => {
	
				// let obj = {
				// 	...creative,
				// 	...data
				// };

				let obj = {
					content_name : creative.original_name,
					screen_name : data.screen_name,
					start_date : data.start_date,
					end_date : data.end_date
				};

				// console.log("Obj Screative", obj);
	
				dispatch(getScreenCreativeImpressionCount(obj));
			});
			
		}

	} catch(e){
		console.log(e);
	}
}

export const getScreenCreativeImpressionCount = (data) => async dispatch => {

	// getLiveImpressionCount

	// console.log("Cdata",data);

	try {

		const response = await axios.post(`${rootURLAppLogs}/screeno_api/getLiveImpressionCount`, {
			data
		}, {
			headers : { authorization: localStorage.getItem("token") }
		});

		// console.log(data.content_name, response.data.count);
		dispatch({
			type : UPDATE_CREATIVE_COUNT_IN_SCREEN,
			payload : {
				...data,
				count : response.data.count
			}
		});

	} catch(e){
		console.log(e);
	}

}


export const getImpressionCountForScreen = (items) => async dispatch => {

	// console.log("item", items);

	items.screenCreativeList.forEach(item => {
		let obj = {
			...item,
			start_date : items.start_date,
			end_date : items.end_date
		}
		dispatch(fetchScreenImpressionCount(obj));
	});


}