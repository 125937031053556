import axios from "axios";
import { rootURL } from "../utils/extra";
import { GET_CLIENTS, DELETE_CLIENT, EDIT_CLIENT, ADD_CLIENT } from "./types";


export const fetchClients = () => dispatch => {
  axios.get(`${rootURL}/api/get-clients`, { headers: { authorization: localStorage.getItem('token') }})
    .then(response => {
      dispatch({
        type: GET_CLIENTS,
        payload: response.data
      })
      // console.log(response.data);

    })
    .catch( err => console.log(err));
}

export const addClient = (user, cb) => dispatch => {
  dispatch({
    type : ADD_CLIENT,
    payload: user
  });

  cb();
}


export const editClient = (user, cb) => dispatch => {
  dispatch({
    type: EDIT_CLIENT,
    payload: user
  });

  cb();

}

export const deleteClient = (user, cb) => dispatch => {
  dispatch({
    type: DELETE_CLIENT,
    payload: user
  });

  cb();
}