import {GET_ALL_APP_DETAILS} from '../actions/types';

export default (state = { get_all_app_details: '' }, action = {}) => {
    switch(action.type){
        case GET_ALL_APP_DETAILS: {
            return Object.assign({}, state, {get_all_app_details: action.payload})
        }
        default: {
            return state;
        }
    }
}