import axios from "axios";
import extra from "../utils/extra";
import jwtDecode from "jwt-decode";
import setAuthorizationToken from "../utils/setAuthorizationToken";
import { SET_CURRENT_USER } from "./types";

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user
  };
}

export function logout() {
  return dispatch => {
    localStorage.clear();
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
  };
}

export function login(data) {
  return dispatch => {
    return axios
      .post(extra.rootURL + "/api/signin", {
        email: data.email,
        password: data.password
      })
      .then(res => {
        const token = res.data.token;
        // localStorage.setItem("signinData", JSON.stringify(res.data));
        localStorage.setItem("token", token);
        setAuthorizationToken(token);
        dispatch(
          setCurrentUser({ token: jwtDecode(token), role: res.data.role, company_name : res.data.company_name })
        );
      });
  };
}
