import {GET_ALL_SCREEN_COUNT,GET_ALL_ACTIVE_SCREEN_COUNT, DASHBOARD_STATS} from '../actions/types';
import {GET_ALL_PLAYLIST_COUNT,GET_ALL_INVANTORY_COUNT, GET_ALL_CONTENT_COUNT, GET_ALL_LOCATIONS_COUNT} from '../actions/types';

const initalState = { 
    get_all_screen_count_data: '',  
    get_all_playlist_count_data: '', 
    get_all_invantory_count_data:'', 
    get_all_content_count_data:'', 
    get_all_active_screen_count_data: '', 
    get_all_locations_count: "",
    dashboard_stats : {
        totalScreens: "",
        totalLocations: ""
    }
}

export default (state=initalState, action={})=>{
    switch(action.type){
        case GET_ALL_SCREEN_COUNT:
            return Object.assign({}, state,{
                get_all_screen_count_data: action.payload
            });

        case GET_ALL_PLAYLIST_COUNT:
            return Object.assign({}, state,{
                get_all_playlist_count_data: action.payload
            });

        case GET_ALL_INVANTORY_COUNT:
            return Object.assign({}, state,{
                get_all_invantory_count_data: action.payload
            });

        case GET_ALL_CONTENT_COUNT:
            return Object.assign({}, state,{
                get_all_content_count_data: action.payload
            });

        case GET_ALL_ACTIVE_SCREEN_COUNT:
            return Object.assign({}, state,{
                get_all_active_screen_count_data: action.payload
            });

        case GET_ALL_LOCATIONS_COUNT:
            return {
                ...state,
                get_all_locations_count : action.payload
            }
        case DASHBOARD_STATS : {
            return {
                ...state,
                dashboard_stats : action.payload
            }
        }
        
            
        default: return state;
    }
}