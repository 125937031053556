import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, FormGroup } from 'react-bootstrap';
import { getPlaylistsForScreen } from '../../actions/playlistActions';

class ShowPlaylistsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            playlist_array: []
        }
    }

    openModal() {
        this.setState({ showModal: true });
        this.props.getPlaylistsForScreen(this.props.screen_data._id).then((result, error) => {
            if (error) {
                console.log(error)
            } else {
                this.setState({
                    playlist_array: result.data.data
                })
            }
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        return (
            <div>
                <button type="button" className="center-align btn btn-success" onClick={this.openModal.bind(this)}>
                    <i className="fa fa-folder-open"></i>
                </button>

                <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)}>
                    <Modal.Header className="bg-success">
                        <Modal.Title>
                            <div className="text-center mtb-0">{this.props.screen_data.screen_name}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table mrgn table-hover table-bordered">
                            <thead>
                                <tr className="bg-success">
                                    <th>Playlist Name</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Content</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.playlist_array.length>0 ? this.state.playlist_array.map((item, index) => (
                                    <tr key={index} className="table-info fcolor-black">
                                        <td>{item.playlist_name}</td>
                                        <td>{new Date(item.to_be_played_from).toLocaleString()}</td>
                                        <td>{new Date(item.to_be_played_to).toLocaleString()}</td>
                                        <td><a href="/playlistsNschedule" className="btn btn-success">Go to playlist</a></td>
                                    </tr>
                                )) : <tr>
                                        <td>No Playlist Present</td>
                                    </tr>}
                            </tbody>
                        </table>
                        <FormGroup>
                            <div className="row">
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-danger mt-2" onClick={this.closeModal.bind(this)}>Close</button>
                                </div>
                            </div>
                        </FormGroup>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { getPlaylistsForScreen })(ShowPlaylistsModal);