import { NEW_FILE_ADDED, GET_ALL_FILES, MSG, PROGRESS_PERCENT } from '../actions/types';

export default (state = {
    new_file_added: false,
    get_all_files_data: [],
    msg: {},
    percent: 0
}, action = {}) => {
  switch(action.type) {
    case NEW_FILE_ADDED:
        return Object.assign({}, state, {
            new_file_added: action.payload,
        });
    case GET_ALL_FILES:
        return Object.assign({}, state, {
            get_all_files_data: action.payload
        });
    case MSG: 
        return Object.assign({}, state, {
            msg: action.payload
        });
    case PROGRESS_PERCENT:
        return Object.assign({}, state, {
            percent : action.payload
        })
    default: return state;
  }
}