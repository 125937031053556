import React from "react";
import { connect } from "react-redux";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { getAllPlayLists, removePlayList } from "../../actions/playlistActions";
import DeletePLaylistShowModal from "./DeletePlaylistModal";
import ShowPreviewModal from "./previewModal";
import UpdatePlaylistComponent from "./UpdatePlaylistComponent";

class ShowPlayListAndSchedules extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
			showModal: false,
			playlist_name: "",
			search_text: "",
			searched_data: "",
			showUpdateModal: false,
			showPreviewModal: false,
		};
	}

	componentWillMount() {
		this.props.getAllPlayLists();
	}

	handleModal(playlist_name) {
		this.setState({
			showModal: true,
			playlist_name: playlist_name,
		});
	}

	handlePreview(data) {
		this.setState({
			data: data,
			showPreviewModal: true,
		});
	}
	handleUpdate(data) {
		this.setState({
			showUpdateModal: true,
			data: data,
		});
	}

	toDate(date_string) {
		let date = new Date(date_string).toLocaleString();
		return <div>{date}</div>;
	}

	handleUpdateButtonClick = (item) => {
		this.setState({ showUpdatePlaylists: true, updateData: item });
	};

	handleCancelUpdateButtonClick = (command) => {
		if (command === "updated") {
			this.props.getAllPlayLists();
		}
		this.setState({ showUpdatePlaylists: false, updateData: "" });
	};

	previewFormatter = (cell, row) => {
		// console.log("Row :", row.screen_name);
		return (
			<ShowPreviewModal
				previewData={row.playlist_data}
				key={row._id}
				screen={row.screen_name}
				pCode={row.screen_pairing_code}
			/>
		);
	};

	removeFormatter = (cell, row) => {
		return (
			<DeletePLaylistShowModal
				modalData={row.playlist_name}
				screen_name={row.screen_name}
			/>
		);
	};

	updateFormatter = (cell, row) => {
		return (
			<button
				className="auto_margin2 btn btn-primary"
				onClick={this.handleUpdateButtonClick.bind(this, row)}
			>
				<i className="fa fa-sliders"></i>
			</button>
		);
	};

	dateFormatter = (cell, row) => {
		return <div>{this.toDate(cell)}</div>;
	};

	cellFormatter = (cell, row) => {
		return <div>{cell}</div>;
	};

	loopDurationFormatter = (cell, row) => {
		const { playlist_data } = row;
		// console.log(playlist_data);

		let totalDuration = 0;

		playlist_data.forEach((p) => {
			totalDuration = totalDuration + parseFloat(p.duration);
		});
		totalDuration = Math.floor(totalDuration);

		if (totalDuration && totalDuration > 0) {
			return <div>{totalDuration}</div>;
		} else {
			return <div>{parseInt(0)}</div>;
		}
	};

	occupancyRate = (cell, row) => {
		const { playlist_data } = row;
		const loopMaxCount = 360;
		let totalDuration = 0;

		playlist_data.forEach((p) => {
			totalDuration = totalDuration + parseFloat(p.duration);
		});
		totalDuration = Math.floor(totalDuration);

		let percentage = parseFloat((totalDuration / loopMaxCount) * 100).toFixed(
			2
		);

		if (percentage >= 100.0) {
			return <div>100.00 %</div>;
		} else {
			return <div>{percentage} %</div>;
		}
	};

	render() {
		const { get_all_playlists_data_array } = this.props;

		if (this.state.showUpdatePlaylists) {
			return (
				<UpdatePlaylistComponent
					data={this.state.updateData}
					handleCancelUpdateButtonClick={this.handleCancelUpdateButtonClick}
				/>
			);
		} else {
			if (get_all_playlists_data_array) {
				return (
					<div>
						<div className="col-md-6 padding_zero">
							<h2 className="inline">
								<i className="fa fa-list-alt"></i> PlayList And Schedules
							</h2>
						</div>
						<div className="table-width mb-20">
							<BootstrapTable
								keyField="id"
								data={get_all_playlists_data_array}
								options={this.options}
								pagination
								search
							>
								<TableHeaderColumn
									dataField="playlist_name"
									dataSort
									width="150"
								>
									Playlist Name
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="updated_at"
									dataSort
									dataFormat={this.dateFormatter}
								>
									Updated At
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="to_be_played_from"
									dataSort
									dataFormat={this.dateFormatter}
								>
									To Be Played From
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="to_be_played_to"
									dataSort
									dataFormat={this.dateFormatter}
								>
									To Be Played To
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="screen_name"
									dataSort
									dataFormat={this.cellFormatter}
								>
									Screen Name
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="_id"
									dataSort
									dataFormat={this.loopDurationFormatter}
								>
									Loop Duration(Sec)
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="_id"
									dataSort
									dataFormat={this.occupancyRate}
								>
									Occupancy Rate(%)
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="_id"
									dataSort
									dataFormat={this.previewFormatter}
								>
									Preview
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="_id"
									dataSort
									dataFormat={this.removeFormatter}
								>
									Remove Playlist
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="_id"
									dataSort
									dataFormat={this.updateFormatter}
								>
									Update Playlist
								</TableHeaderColumn>
							</BootstrapTable>
						</div>
					</div>
				);
			} else {
				return (
					<img
						className="Nloader"
						height="40"
						alt="preloader"
						src="img/screeno_preloader.gif"
					/>
				);
			}
		}
	}
}

function mapStateToProps(state) {
	return {
		get_all_playlists_data_array: state.playlist.get_all_playlists_data_array,
	};
}

export default connect(mapStateToProps, { getAllPlayLists, removePlayList })(
	ShowPlayListAndSchedules
);
