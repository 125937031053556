import React, {Component} from 'react';
import { uploadFile, getAllFiles } from '../../actions/contentActions';
import {getAllScreens} from '../../actions/screenActions';
import {connect} from 'react-redux';
import { ProgressBar } from "react-bootstrap";
import AlertContainer from 'react-alert';

class AddFile extends Component {

    constructor(props){
        super(props);
        this.state={
            file: '',
            filefeild: '',
            filename: '',
            progressBar: false,
            resolution: "invalid",
            video_url : "",
            duration : 5 ,
            file_size : 0
        }
    }
    
    componentWillMount() {
        // this.props.getAllScreens();
    }

    handleFileSelect(e){
        e.preventDefault();
        let file = e.target.files[0];
        this.setState({filename:file.name, file: file, filefeild: e.target.value, file_size: file.size});

        if(file.type.search("video")===0){
            if(file.type.search("video/mp4")===0){
                this.setState({ video_url : URL.createObjectURL(file)}, ()=>{
                    this.videoTag.load();
                    this.videoTag.onloadeddata = e => {
                        this.setState({
                            duration : this.videoTag.duration
                        })
                    }
                });
            } else {
                this.msg.show('Please Select Mp4 File or File Uploading Will Taske Time', {time: 10000, type: 'info'})
            }
        }
    }

    handleFileOptimize(e){
        this.setState({
            resolution: e.target.value
        })
    }

    onSubmit(e){
        e.preventDefault();
        this.setState({progressBar:true});
        if(this.state.file!=='' && (this.state.file.type.search("video")===0 || this.state.file.type.search("image")===0)){
            this.props.uploadFile(this.state.file, this.state.resolution, this.state.duration, this.state.file_size, this.props.user_id);
        } else{
            this.msg.show('select valid file', {time: 2000, type: 'error'})
            this.setState({progressBar:false});
        }
    }


    componentWillReceiveProps(nextProps){

        if(nextProps.msg){
            if(nextProps.msg.msg_data){
                this.msg.show(nextProps.msg.msg_data, {type: nextProps.msg.msg_type});
            }

            if(nextProps.msg.msg_data==="Success" || nextProps.msg.msg_type==="error" ){
                this.setState({filefeild: "", filename: "", file: "", video_url: "", duration : 5});
                this.props.getAllFiles();
                this.setState({progressBar:false});
            }
        }
    }


    render(){
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                <h3 className="mt-0"><i className="fa fa-upload"></i> Upload Content</h3>
                <form method="post" encType="multipart/form-data" action="#" onSubmit={this.onSubmit.bind(this)}>
                    <div className="col-md-12 form-group mt-10">
                        <div className="col-md-12">
                            <div className="col-md-6">
                                <label className="btn btn-raised btn-primary max-width550" htmlFor="InputFile">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="17" viewBox="0 0 20 17">
                                    <path fill="#fff" d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                    </svg>
                                    <span className="uplaoded-file">{this.state.filename}</span>
                                </label>
                                <input type="file" value={this.state.filefeild} name="file" 
                                    className="dsply" id="InputFile" aria-describedby="fileHelp" 
                                    onChange={this.handleFileSelect.bind(this)} ref={ ref=>{ this.uploadInput = ref; }} />
                                <small id="fileHelp" className="form-text text-muted">
                                    Maximum upload file size: 100 MB (Only JPG/JPEG/PNG/MP4 Format)
                                </small>
                                <div className="col-sm-6">
                                    { this.state.progressBar && <ProgressBar active now={this.props.percent} label={`${this.props.percent} %`}/> }
                                </div>
                            </div>
                            <div className="col-md-3">
                                <video height="100"  autoPlay src={this.state.video_url} ref={ ref=>{ this.videoTag = ref; } }></video>
                            </div>
                            {/* <div className="col-md-3">
                                {this.state.file?this.state.file.type.search("video")===0?
                                <div>
                                    {this.state.file.type.search("video/mp4")===0?null:
                                    <h6>Mp4 not selected video will be converted to mp4</h6>}
                                    <label>Select Resolution</label>
                                    <select name="dimension" id="file_dimension" className="form-control" onChange={this.handleFileOptimize.bind(this)}>
                                        <option >Dont set resolution</option>
                                        {this.props.screen_data_array?this.props.screen_data_array.map((item, key)=>(
                                            <option key={key}>{item.screen_width+"x"+item.screen_height}</option>
                                        )):null}
                                    </select>
                                </div>
                                :null:null}
                            </div> */}
                        </div>
                        <div className="col-md-12">
                            <div className="col-md-6">
                                <button className="btn btn-raised btn-info mt-10" type="submit" value="Upload">Upload</button>
                            </div>
                        </div>
                    </div>
                </form>
                <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
                </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state){
    
    return {
        msg: state.content.msg,
        screen_data_array: state.screen.get_all_screen_data,
        percent : state.content.percent,
        // user_id : state.auth.user.token.sub
    }
}


export default connect(mapStateToProps, {uploadFile, getAllFiles, getAllScreens})(AddFile);