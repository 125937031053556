import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAdvertiserCampaign, manageScreenData } from '../../actions/campaignActions';
import RemoveScreenModal from './RemoveScreenModal';
import ShowPlaylistsModal from './ShowPlaylistsModal';

class CampaignScreens extends Component {

    handleBack() {
        this.props.manageScreenData("")
    }

    render() {
        let selected_campaign = "";
        if(this.props.campaign_list){
            this.props.campaign_list.map((item, index)=>{
                if(item._id===this.props.manage_screen_data._id){
                    selected_campaign = item;
                }
                return null;
            })
        }
        return (
            <div className="row">
                <div className="col-md-12 text-center bg-primary"><h1>{this.props.manage_screen_data.campaignName}</h1></div>
                <div className="col-md-12 mt-3">
                    <div className="col-md-4">
                        <button className="btn btn-info" onClick={this.handleBack.bind(this)}><i className="fa fa-arrow-left"></i></button>
                    </div>
                </div>
                <div className="col-md-12 mt-1">
                    <table className="table mrgn table-hover table-bordered">
                        <thead>
                            <tr className="bg-success">
                                <th className="text-center">Screen Name</th>
                                <th className="text-center">View Playlists</th>
                                <th className="text-center">Remove Screen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selected_campaign?selected_campaign.screens.map((item, index) => (
                                <tr key={index} className="table-info fcolor-black">
                                    <td className="text-center">{item.screen_name}</td>
                                    <td className="text-center"><ShowPlaylistsModal screen_data={item}/></td>
                                    <td className="text-center"><RemoveScreenModal manage_screen_data={selected_campaign} selected_item={item}/></td>
                                </tr>
                            )):null}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default connect(state=>{
    return {
        campaign_list : state.campaign.campaign_list
    }
}, { manageScreenData, fetchAdvertiserCampaign })(CampaignScreens);