/* eslint-disable no-undef */
import React, { Component } from 'react';
import { Modal, Form, FormGroup, Col } from 'react-bootstrap';
import { addScreen, getAllScreens } from '../../actions/screenActions'
import { connect } from 'react-redux';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import AlertContainer from 'react-alert';



const geolocation = (
  navigator.geolocation ?
    navigator.geolocation :
    ({
      getCurrentPosition(success, failure) {
        failure(`Your browser doesn't support geolocation.`);
      },
    })
);

const INPUT_STYLE = {
  boxSizing: `border-box`,
  MozBoxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `240px`,
  height: `32px`,
  marginTop: `27px`,
  padding: `0 12px`,
  borderRadius: `1px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `14px`,
  outline: `none`,
  textOverflow: `ellipses`,
};

const MapSelect = (withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={8}
    onClick={props.onMapClick}
    center={props.center}
    zoom={props.zoom}
    onZoomChanged={props.onZoomChanged}
  // onBoundsChanged={props.onBoundsChanged}
  >
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
      controlPosition={google.maps.ControlPosition.TOP}>
      <input
        type="text"
        placeholder="Search Location"
        style={INPUT_STYLE}
      />
    </SearchBox>
    <Marker position={props.center} />
  </GoogleMap>
)));

class AddScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      screen_name: '',
      address: 'Picked from map',
      zoom: 11,
      bounds: null,
      layout_code: '',
      image_select: '',
      screen_type: 'normal_screen',
      latitude: 20,
      longitude: 78,
      screen_count :1,
      ad_slot : "",
      category : "",
      country : "",
      city : "",
      state : "",
      footfall : "",
      impressions : "",
      loop_time : "",
      monthly_cost : "",
      network: "",
      network_name: "",
      operating_hours : "",
      screen_resolution: "",
      cpm : 1,
      currency : "",
      specification: "",
      purchase_type : "",
      availability : "",
      final_score : ""

    }
  }

  componentDidMount() {
    geolocation.getCurrentPosition((position) => {
      if (this.isUnmounted) {
        return;
      }
      this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });
    }, (failure) => {
      this.setState({
        latitude: 20,
        longitude: 78
      });
    })
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  onChange(e) {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "latitude" || name === "longitude" || name === "screen_count") {
      value = parseFloat(value);
    }

    if(name === "screen_count"){
      value = parseInt(value)
    }
    
    this.setState({ [name]: value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { screen_name, address: location, latitude, longitude, screen_type, screen_count,
      impressions, footfall, ad_slot, network, state, city, category, monthly_cost, loop_time,
      screen_resolution, operating_hours, country, network_name, cpm, currency, purchase_type, specification, availability, final_score
    } = this.state;

    let latlng = JSON.stringify({ lat: latitude, lng: longitude });
    let layout_code = 0;

    const data = {
      screen_count, latlng, layout_code, screen_type, screen_name, location, impressions,
      footfall, ad_slot, network, state, city, category, monthly_cost, loop_time,
      screen_resolution, operating_hours, country, network_name, cpm, currency, purchase_type, specification, availability, final_score
    };

    

    if (Object.values(data).every(d => d !== "")) {
    // if (screen_name !== '' && location !== '' && screen_type !== '') {
      // this.props.addScreen(screen_name, location, latlng, layout_code, screen_type, screen_count).then(() => {
      this.props.addScreen(data).then(() => {
        this.setState({ screen_name: '' });
        this.closeModal();
        this.props.getAllScreens();
        this.msg.show(this.props.msg.msg_data, { type: this.props.msg.msg_type })
      })
    } else {
      // if (screen_type === '') {
      //   this.msg.show('Enter Screen TYpe', { time: 2000, type: 'error' })
      // }
      // if (screen_name === '') {
      //   this.msg.show('Enter Screen Name', { time: 2000, type: 'error' })
      // }

      this.msg.show("Check Your form data no empty fields allowed");
    }

    console.log("State :", this.state);
  }

  onMapClick(event) {
    this.setState({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
      address: "Picked From Map"
    });
  }

  handleMapMounted(map) {
    this._map = map;
  }

  handleSearchBoxMounted(searchBox) {
    this._searchBox = searchBox;
  }

  handlePlacesChanged() {
    const places = this._searchBox.getPlaces();
    const markers = places.map(place => ({
      position: place.geometry.location,
    }));
    let { latitude, longitude } = this.state;
    latitude = markers.length > 0 ? markers[0].position.lat() : latitude;
    longitude = markers.length > 0 ? markers[0].position.lng() : longitude;
    const address = places[0].name + ", " + places[0].formatted_address;
    this.setState({ latitude, longitude, markers, address });
  }


  onZoomChanged() {
    const nextZoom = this._map.getZoom();
    this.setState({
      zoom: nextZoom,
    });
  }

  handleLayout(e) {
    this.setState({ layout_code: e })
  }

  render() {

    const { screen_name, address, latitude, longitude, screen_count, state, city, ad_slot, impressions,
      footfall, category, monthly_cost, network, operating_hours, screen_resolution, loop_time, country, network_name
      , cpm, currency, purchase_type, specification, availability, final_score
    } = this.state;

    return (
      <div >
        <h3 className="inline"><i className="fa fa-tv"></i> Add New Screen</h3>
        <button type="button" className="btn btn-lg btn-info" onClick={this.openModal.bind(this)}>
          <i className="fa fa-plus"></i>
        </button>

        <Modal className="top-5" show={this.state.showModal} onHide={this.closeModal.bind(this)}>
          <Modal.Header className="bg-success">
            <Modal.Title><b>Add Screen</b></Modal.Title>
          </Modal.Header>
          <Modal.Body className="screen_modal pt-0" >
            <Form horizontal onSubmit={this.onSubmit.bind(this)}>
              <div className="form-group mt-20">
                <label htmlFor="screen_name" className="col-md-4 control-label mt-0"><h5 className="mb-0 mt-0">Screen Name</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="screen_name" type="text" placeholder="ScreenName" onChange={this.onChange.bind(this)} value={screen_name} />
                </div>
              </div>
              <div className="form-group mt-20">
                <label htmlFor="address" className="col-md-2 control-label mt-0"><h5 className="mb-0 mt-0">Address</h5></label>
                <div className="col-md-9">
                  <input className="form-control" name="address" type="text" placeholder="Address" onChange={this.onChange.bind(this)} value={address} />
                </div>
              </div>
              <div className="form-group col-md-6 padding_zero">
                <label htmlFor="latitude" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Latitude</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="latitude" type="number" placeholder="Latitude" onChange={this.onChange.bind(this)} value={latitude} />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="longitude" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Longitude</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="longitude" type="number" placeholder="Longitude" onChange={this.onChange.bind(this)} value={longitude} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Screen Count</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="screen_count" type="number" placeholder="No Of screens" onChange={this.onChange.bind(this)} value={screen_count} />
                </div>
              </div>

              <FormGroup controlId="formHorizontalScreenMap">
                <Col sm={8} >
                  <MapSelect
                    containerElement={
                      <div style={{ height: `350px`, width: `50vw` }} />
                    }
                    mapElement={
                      <div style={{ height: `350px`, width: `50vw` }} />
                    }
                    center={{ lat: latitude, lng: longitude }}
                    onMapClick={this.onMapClick.bind(this)}
                    zoom={this.state.zoom}
                    onZoomChanged={this.onZoomChanged.bind(this)}
                    onMapMounted={this.handleMapMounted.bind(this)}
                    bounds={this.state.bounds}
                    // onBoundsChanged={this.handleBoundsChanged.bind(this)}
                    onSearchBoxMounted={this.handleSearchBoxMounted.bind(this)}
                    onPlacesChanged={this.handlePlacesChanged.bind(this)}
                  />
                </Col>
              </FormGroup>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Country</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="country" type="text" placeholder="Country" onChange={this.onChange.bind(this)} value={country} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">State</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="state" type="text" placeholder="State" onChange={this.onChange.bind(this)} value={state} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Network</h5></label>
                <div className="col-md-6">
                  {/* <input className="form-control" name="network" type="text" placeholder="Network" onChange={this.onChange.bind(this)} value={network} /> */}
                  <select className="form-control" name="network" onChange={this.onChange.bind(this)} value={network}>
                      <option value="">Please select</option>
                      <option value="Business">Business</option>
                      <option value="In-Store">In-Store</option>
                      <option value="Leisure">Leisure</option>
                      <option value="Outdoor">Outdoor</option>
                      <option value="Residential">Residential</option>
                      <option value="Transit">Transit</option>
                  </select>
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Network Name </h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="network_name" type="text" placeholder="Network Name" onChange={this.onChange.bind(this)} value={network_name} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">City</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="city" type="text" placeholder="City" onChange={this.onChange.bind(this)} value={city} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Category</h5></label>
                <div className="col-md-6">
                  {/* <input className="form-control" name="category" type="text" placeholder="Category" onChange={this.onChange.bind(this)} value={category} /> */}
                  <select className="form-control" name="category" onChange={this.onChange.bind(this)} value={category}>
                      <option value="">Please select</option>
                      <option value="Airport">Airport</option>
                      <option value="Automotive Retail">Automotive Retail</option>
                      <option value="Cafe Network">Cafe Network</option>
                      <option value="Corporate">Corporate</option>
                      <option value="Electronic Retail">Electronic Retail</option>
                      <option value="Metro Station">Metro Station</option>
                      <option value="Outdoor Digital">Outdoor Digital</option>
                      <option value="Premium">Premium</option>
                      <option value="Pub Network">Pub Network</option>
                      <option value="Railway Station">Railway Station</option>
                      <option value="Residential">Residential</option>
                      <option value="Restaurant">Restaurant</option>
                      <option value="Store">Store</option>
                      {/* <option value=""></option> */}
                      
                  </select>
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Impressions</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="impressions" type="number" placeholder="Impressions" onChange={this.onChange.bind(this)} value={impressions} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Footfall</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="footfall" type="number" placeholder="FootFall" onChange={this.onChange.bind(this)} value={footfall} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Monthly Cost</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="monthly_cost" type="number" placeholder="Monthly Cost" onChange={this.onChange.bind(this)} value={monthly_cost} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Ad Slot(Sec)</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="ad_slot" type="number" placeholder="Ad Slot" onChange={this.onChange.bind(this)} value={ad_slot} />
                </div>
              </div>

              <div className="form-group col-md-6">
                  <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Operating Hours</h5></label>
                  <div className="col-md-6">
                      <input className="form-control" name="operating_hours" type="number" placeholder="Operating Hours" onChange={this.onChange.bind(this)} value={operating_hours} />
                  </div>
              </div>


              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Loop Time(min)</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="loop_time" type="number" placeholder="Loop Time" onChange={this.onChange.bind(this)} value={loop_time} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="cpm" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">CPM</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="cpm" type="number" placeholder="CPM" onChange={this.onChange.bind(this)} value={cpm} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="currency" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Currency (Ex. INR, USD)</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="currency" type="text" placeholder="Currency" onChange={this.onChange.bind(this)} value={currency} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="specification" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Specification</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="specification" type="text" placeholder="Specification" onChange={this.onChange.bind(this)} value={specification} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="Purchase Type" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Purchase Type</h5></label>
                <div className="col-md-6">
                  {/* <input className="form-control" name="purchase_type" type="text" placeholder="Purchase Type" onChange={this.onChange.bind(this)} value={purchase_type} /> */}
                  <select className="form-control" name="purchase_type" onChange={this.onChange.bind(this)} value={purchase_type}>
                    <option value="">Choose Type</option>
                    <option value="individual">Individual</option>
                    <option value="network">Network</option>
                  </select>
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="final_score" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Final Score</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="final_score" type="number" placeholder="Final Score" onChange={this.onChange.bind(this)} value={final_score} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Availability</h5></label>
                <div className="col-md-6">
                  {/* <input className="form-control" name="availability" type="text" placeholder="Availability" onChange={this.onChange.bind(this)} value={availability} /> */}
                  <select className="form-control" name="availability" onChange={this.onChange.bind(this)} value={availability}>
                      <option value="">Please select</option>
                      <option value="available">Available</option>
                      <option value="unavailable">Unavailable</option>
                  </select>
                </div>
              </div>


              <div className="form-group col-md-6">
                <label htmlFor="operating_hours" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Operating Hours</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="operating_hours" type="number" placeholder="Operating Hours" onChange={this.onChange.bind(this)} value={operating_hours} />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="screen_count" className="col-md-6 control-label mt-0"><h5 className="mb-0 mt-0">Screen Resolution</h5></label>
                <div className="col-md-6">
                  <input className="form-control" name="screen_resolution" type="text" placeholder="Screen Resolution" onChange={this.onChange.bind(this)} value={screen_resolution} />
                </div>
              </div>

              <FormGroup>
                <div className="row col-md-4 col-md-offset-4">
                  <button type="submit" className="btn btn-lg btn-info mr-30">
                    <i className="glyphicon glyphicon-ok"></i>
                  </button>
                  <button type="button" className="btn btn-lg btn-warning" onClick={this.closeModal.bind(this)}>
                    <i className="glyphicon glyphicon-remove"></i></button>
                </div>
              </FormGroup>
            </Form>
          </Modal.Body>
        </Modal>
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    msg: state.screen.msg,
    role: state.auth.user.role
  }
}

export default connect(mapStateToProps, { addScreen, getAllScreens })(AddScreen);