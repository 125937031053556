import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../actions/authActions';
import LoginPage from '../components/login/LoginPage';
import { browserHistory } from 'react-router';

class Welcome extends Component {
  logout(e) {
    e.preventDefault();
    this.props.logout();
  }

  componentWillMount() {
    if (this.props.auth.isAuthenticated) {
      browserHistory.push("/dashboard")
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <div className="animated fadeIn">
        {isAuthenticated ? null : <LoginPage />}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, { logout })(Welcome);