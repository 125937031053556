import { combineReducers } from "redux";

import auth from "./reducers/auth";
import screen from "./reducers/screen";
import content from "./reducers/content";
import playlist from "./reducers/playlist";
import inventory from "./reducers/inventory";
import dashboardcount from "./reducers/dashboardcount";
import cabscreen from "./reducers/cabScreen";
import appController from "./reducers/appController";
import screenController from "./reducers/screenController";
import campaign from "./reducers/campaign";
import reportReducer from "./reducers/reportReducer";
import errorReducer from "./reducers/errorReducer";
import clients from "./reducers/clients";

export default combineReducers({
	auth,
	screen,
	content,
	playlist,
	inventory,
	dashboardcount,
	cabscreen,
	appController,
	screenController,
	campaign,
	clients,
	userReports: reportReducer,
	errors: errorReducer,
});
