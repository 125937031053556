import axios from 'axios';
import extra from '../utils/extra';
import { NEW_SCREEN_ADDED, GET_ALL_SCREEN, MSG } from './types';

export function generate_msg(payload) {
    return {
        type: MSG,
        payload
    }
}

export function new_screen_added(payload) {
    return {
        type: NEW_SCREEN_ADDED,
        payload
    }
}



export function get_all_screen(payload) {
    return {
        type: GET_ALL_SCREEN,
        payload
    }
}

export function addScreen(data) {
// export function addScreen(screen_name, location, latlng, layout_code, screen_type, screen_count) {

    // console.log("Screen Data", data);

    return (dispatch) => {
        return axios.post(extra.rootURL + '/api/addScreen', {
            ...data
        }, { headers: { authorization: localStorage.getItem('token') } }).then((res) => {

            if (res.data.error_code) {
                console.log(res.data.error_code)
                dispatch(generate_msg({ msg_data: "Screen Name Already Exits", msg_type: "error" }))
            } else {
                dispatch(generate_msg({ msg_data: "Success", msg_type: "success" }));
                localStorage.removeItem("all_screen");
            }

        }).catch((error) => {
            if (error) {
                console.log('addScreen Error', error);
            }
        });
    }
}

export function updateScreen(data) {
// export function updateScreen(screen_id, location, latlng, screen_count) {
    console.log("Update Dtata :", data);
    return dispatch => {
        return axios.post(extra.rootURL + "/api/updateScreen", { ...data },
            { headers: { authorization: localStorage.getItem('token') } })
    }
}

export function getAllScreens() {
    return (dispatch) => {
        return axios.get(extra.rootURL + '/api/getAllScreens', { headers: { authorization: localStorage.getItem('token') } }).then((res) => {
            dispatch(get_all_screen(res.data.screens));
        }).catch((error) => {
            if (error) {
                console.log(error);
            }
        });
    }
}

export function removeScreen(screen_name) {
    return (dispatch) => {
        return axios.post(extra.rootURL + '/api/removeScreen', { screen_name: screen_name }, { headers: { authorization: localStorage.getItem('token') } }).then(() => {
            localStorage.removeItem("all_screen");
        });
    }
}

export function unpairScreen(screen_name) {
    return (dispatch) => {
        return axios.post(extra.rootURL + '/api/unpairScreen', { screen_name: screen_name }, { headers: { authorization: localStorage.getItem('token') } }).then(() => {
            localStorage.removeItem("all_screen");
        });
    }
}

// export function addScreenToInventoryAction(state) {
//     console.log("screen :", state);
//     let formData = new FormData();
//     formData.append('file_feild', state.file);
//     formData.append('footfall', state.footfall);
//     formData.append('impressions', state.impressions);
//     formData.append('network', state.network);
//     formData.append('cluster', state.cluster);
//     formData.append('screen', JSON.stringify(state.screen))
//     return dispatch => {
//         return axios.post(extra.rootURL + '/api/addScreenToInventory', formData, { headers: { 
//             authorization: localStorage.getItem('token'),
//             'Content-Type': 'multipart/form-data'
//          } })
//     }
// }

export function addScreenToInventoryAction(screen) {
    // const { screen_name, _id } = screen;
    // console.log("Screen invent :", screen);

    return dispatch => {
        return axios.post(extra.rootURL + '/api/addScreenToInventory', {screen_name : screen}, { headers: { 
            authorization: localStorage.getItem('token')
         } })
    }
}

export function removeScreenFromInventory(screen_id) {
    return dispatch => {
        return axios.post(extra.rootURL + '/api/removeScreenFromInventory', { screen_id: screen_id }, { headers: { authorization: localStorage.getItem('token') } })
    }
}

export const addScreensWithExcel = (formData, cb) => async  dispatch => {
    
    try {

        await axios.post(`${extra.rootURL}/api/addScreensFromExcel`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: localStorage.getItem('token')
            }
        });

        cb();

    } catch (err) {
        console.log(err);
        cb();
    }

    // console.log("I was Called", formData);

}
