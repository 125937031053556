import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">
        <a target="blank" href="https://locad.net/screeno.html"><img alt="logo" height="20" src="../img/screeno_logo_design.png"/></a> &copy; 2020
        <span className="float-right">Powered by <a target="blank" href="http://locad.net">Locad OOH</a></span>
      </footer>
    )
  }
}

export default Footer;
