import { ADD_TO_CART, MARKER_CLICKED, GET_ALL_INVENTORY_SCREENS, GET_FILTERED_INVENTORY,
    SET_INVENTORY_FILTER_OPTIONS, SET_FILTER_LOCATION_STRUCT, FETCH_INVENTORY_FILTER_OPTIONS,
    SET_CITY_FILTER_OPTIONS, SET_STATE_FILTER_OPTIONS
} from '../actions/types';

const initState = {
    marker_data: '', 
    get_all_inventory_screens: [], 
    get_filtered_screens: [], 
    msg: '', 
    cart: new Set(),
    filterOptions : {
        country : [],
        states : [],
        cities : [],
        networks : [],
        network_names :[],
        categories : [],
        defaultCity : [],
        locationStruct : [],
        defaultStates : [],
    }
}

// export default (state = initState , action = {}) => {
//     switch (action.type) {
//         case GET_ALL_INVENTORY_SCREENS:
//             return Object.assign({}, state, {
//                 get_all_inventory_screens: action.payload
//             });
//         case GET_FILTERED_INVENTORY:
//             return Object.assign({}, state, {
//                 get_filtered_screens: action.payload
//             });
//         case MARKER_CLICKED:
//             return Object.assign({}, state, {
//                 marker_data: action.payload
//             });
//         case ADD_TO_CART:
//             return Object.assign({}, state, {
//                 cart: action.payload
//             });
//         case SET_INVENTORY_FILTER_OPTIONS :{
//             return {
//                 ...state,
//                 filterOptions : action.payload
//             }
//         }
//         default: return state;
//     }
// }

export default (state=initState, action ) => {

    const { payload } = action;

    switch(action.type){
        case GET_ALL_INVENTORY_SCREENS:
            return {
                ...state,
                get_all_inventory_screens: action.payload
            }
        case GET_FILTERED_INVENTORY:
            return {
                ...state,
                get_filtered_screens: action.payload
            }
        case MARKER_CLICKED:
            return {
                ...state,
                marker_data: action.payload
            }
        case ADD_TO_CART:
            return {
                ...state,
                cart: action.payload
            }
        case SET_INVENTORY_FILTER_OPTIONS :{
            return {
                ...state,
                filterOptions : action.payload
            }
        }
        case SET_FILTER_LOCATION_STRUCT :{
            return {
                ...state,
                filterOptions : {
                    ...state.filterOptions,
                    locationStruct : action.payload
                 }
            }
        }
        case FETCH_INVENTORY_FILTER_OPTIONS: {
            return {
                ...state,
                filterOptions : {
                    ...state.filterOptions,
                    country : [...payload.country],
                    states : [...payload.states],
                    cities : [...payload.cities],
                    defaultStates: [...payload.states],
                    defaultCities : [...payload.cities],
                    networks : [...payload.network],
                    network_names : [...payload.network_name],
                    categories : [...payload.category]
                 }
            }
        }
        case SET_CITY_FILTER_OPTIONS :
            return {
                ...state,
                filterOptions : {
                    ...state.filterOptions,
                    cities : payload
                }
                
            }
        
        case SET_STATE_FILTER_OPTIONS :
            return {
                ...state,
                filterOptions : {
                    ...state.filterOptions,
                    states : payload
                }
                
            }
        
        default: return state;
    }
}