import React from 'react';
import { render } from 'react-dom';
import { Router, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './rootReducer';
import setAuthorizationToken from './utils/setAuthorizationToken';
import jwtDecode from 'jwt-decode';
import { setCurrentUser } from './actions/authActions';
import { loadState, saveState } from './localStorage';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import './css/main.css';
import routes from './routes';

const persistedState = loadState();

const store = createStore(
  rootReducer,persistedState,
  compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

store.subscribe(() => {
  saveState({ auth: store.getState().auth});
});

if (localStorage.token) {
  setAuthorizationToken(localStorage.token);
  const state = JSON.parse(localStorage.getItem("state"));
  const { role, company_name } = state.auth.user;
  store.dispatch(setCurrentUser({token: jwtDecode(localStorage.token), role, company_name}));
}

render(
  <Provider store={store}>
    <Router history={browserHistory} routes={routes} />
  </Provider>, document.getElementById('app'));
