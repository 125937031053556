import React ,{Component} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {getAllScreens, unpairScreen} from '../../actions/screenActions';
// import extra from '../../utils/extra';
import mqttClient from "../../utils/mqtt_config";

 class UnpairWarningModal extends Component{
    constructor(props){
        super(props);
        this.state={
             modal: false,
             modalData:''
        }
    }
    
    handleScreenUnpair(){
        mqttClient.publish(`cmd_${this.props.screen_name}_${this.props.user_id}`, JSON.stringify({"message":{"cmd": "unpair"}} ));
        this.props.unpairScreen(this.props.screen_name).then(()=>{
            this.props.getAllScreens();
            this.close();
        });
    }
            

    show(){
        this.setState({modal: true})
    }

    close(){
        this.setState({modal: false});
    }

    render(){
        return(
                <div>
                    <button className="auto_margin2 btn btn-danger" onClick={this.show.bind(this)}>
                        <i className="fa fa-unlink"></i>
                    </button>
                    <Modal show={this.state.modal} onHide={this.close.bind(this)}>
                        <Modal.Body>
                            <h4><img alt="alert" src="img/warning-sign.png" /> &nbsp;
                                Are you sure that you want to unpair this screen?</h4>

                                <div className="row col-md-4 col-md-offset-4">
                                    <button className="btn btn-danger mr-30" onClick={this.handleScreenUnpair.bind(this)}>
                                <i className="glyphicon glyphicon-ok"></i></button>
                                <button type="button" className="btn btn-warning" onClick={this.close.bind(this)}>
                                <i className="glyphicon glyphicon-remove"></i></button>
                                </div>
                            </Modal.Body>
                    </Modal>
                </div>
        )
    }}
    function mapStateToProps(state){
        return {
            user_id: state.auth.user.token.sub
        }
    }

export default connect(mapStateToProps, {getAllScreens, unpairScreen})( UnpairWarningModal);