import React, { Component } from "react";
import AddScreen from "./AddScreen";
import ShowScreen from "./ShowScreen";
import { connect } from "react-redux";
import AddScreensWithExcel from "./AddScreensWithExcel";

class Screen extends Component {
	render() {
		return (
			<div>
				<div className="row">
					<div className="col-md-6 padding_zero">
						<AddScreen />
					</div>
					<div className="col-md-6 padding_zero">
						<AddScreensWithExcel />
					</div>
				</div>

				<div className="row">
					<div className="col-md-12 padding_zero">
						<ShowScreen />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		new_screen_added: state.screen.new_screen_added,
		role: state.auth.user.role
	};
}

export default connect(
	mapStateToProps,
	null
)(Screen);
