import React, { Component } from 'react';
// import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAllPlayLists } from '../../actions/playlistActions';
import utils,{ rootURL } from '../../utils/extra';
// import Iframe from 'react-iframe'
import axios from 'axios';

let timeout;
let i = 0;
class ShowPreviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: this.props.previewData,
            content: '',
            type: '',
            code : ""
        }
    }

    show() {
        this.setState({ modal: true })
        this.myLoop(this.state.data);
    }

    close() {
        this.setState({ modal: false });
        clearTimeout(timeout);
    }

    componentWillUnmount() {
        clearTimeout(timeout);
    }

    getScreenCode = async () => {
        // console.log("Screen :", this.props.screen);
        const { screen } = this.props;

        try {

            const data = await axios.post(`${rootURL}/api/getPairingCode`, {screen_name : screen},{
                headers :{
                    authorization : localStorage.getItem("token")
                }
            });
    
            // console.log("Data :", data.data.code);
            this.setState({
                ...this.state,
                code : data.data.code
            });
        } catch(err){
            console.log(err);

        }
    }

    componentDidMount(){
        // this.getScreenCode();
    }

    myLoop(temp) {
        console.log(temp, i)
        if (temp[i % temp.length]) {
            this.setState({ content : "", type : "" }, ()=>{
                let type = temp[i % temp.length].type;
                let content = temp[i % temp.length].content;
    
                if (type === "html") {
                    this.setState({ content: content, type: type });
                } else {
                    this.setState({ content: utils.fileUrl + content, type: type });
                }
    
                timeout = setTimeout(() => {
                    i++;
                    this.myLoop(temp);
                }, temp[i % temp.length].duration * 1000);
            })
        }
    }

    

    render() {
        // const { modal, type, content } = this.state;

        // console.log(modal, type, content)

        // const { muted } = this.props;

        return (
            <div>

                <a href={`https://screeno.locad.net/screeno-player/?pairing_code=${this.props.pCode}`} 
                    className="btn btn-info"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fa fa-eye" aria-hidden="true"></i>
                </a>
                
                {/* <button className="auto_margin2 btn btn-info" onClick={this.show.bind(this)}>
                    <i className="fa fa-eye"></i>
                </button>
                <Modal show={modal} onHide={this.close.bind(this)}>
                    <Modal.Body className="padding_zero">

                        {type === "image/jpeg" || type === "image/png" || type === "image/jpg" ?
                            <img className="showContent_modal_img" alt="preview" src={content} /> : <div></div>}

                        {type === "video/mp4" ?
                            <video className="showContent_modal_img" loop muted={muted} controls="true" autoPlay="true">
                                <source src={content} /></video>
                            : <div></div>}

                        {type === "html" ? <Iframe url={content}
                            width="800px"
                            height="450px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"
                            allowFullScreen /> : <div></div>}

                    </Modal.Body>
                    <button type="button" className="btn center-align2 btn-warning mt-15" onClick={this.close.bind(this)}><i className="glyphicon glyphicon-remove"></i></button>
                </Modal> */}
            </div>
        )
    }
}


export default connect(null, { getAllPlayLists })(ShowPreviewModal);


