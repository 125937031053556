// exports.rootURL = "https://screenoapi.locad.net";
// exports.rootURLAppLogs = "https://screenoapplogs.locad.net";


if(process.env.NODE_ENV === "production"){
	
	exports.rootURL = "https://screenoapi.locad.net";
	exports.rootURLAppLogs = "https://screenoapplogs.locad.net";
	
} else if(process.env.NODE_ENV === "development"){
	
	exports.rootURLAppLogs = "http://localhost:5000";
	exports.rootURL = "http://localhost:5000";
}

exports.axiosConfig = {
	headers: { authorization: localStorage.getItem("token") },
};

exports.fileUrl =
	"https://screenodata.s3-ap-southeast-1.amazonaws.com/screeno-uploads/";

exports.secondsToTotalTime = function (value) {
	const sec = parseInt(value, 10); // convert value to number if it's string
	let hours = Math.floor(sec / 3600); // get hours
	let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
	let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
	// add 0 if value < 10; Example: 2 => 02
	if (hours < 10) {
		hours = "0" + hours;
	}
	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}
	return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
};
