import React, { Component } from "react";
import { getAllScreens } from "../../actions/screenActions";
import { connect } from "react-redux";
import _ from "lodash";
import UnpairWarningModal from "./UnpairWarning";
import ScreenLogsModal from "./screenLogsModal";
import ScreenShotModal from "./screenshotModal";
import ScreenShotGallery from "./screenshotGallery";
import mqttClient from "../../utils/mqtt_config";
import CheckStatusButton from "./checkStatusButton";

class ScreenController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			unpairWarningModal: false,
			screen_name: "",
			msg: [["Date", "Screen Name", "Status"]],
			count: 1,
			screenshot_status: false,
			screenshot_url: "",
			search_text: ""
		};
	}

	componentWillMount() {
		this.props.getAllScreens();
	}

	componentWillReceiveProps(nextProps) {
		const { screen_data_array } = nextProps;
		this.setState({ screen_data_array });
	}

	handleUnpair(screen_name) {
		this.setState({
			unpairWarningModal: true,
			screen_name: screen_name
		});
	}

	handleRefresh(screen_name) {
		mqttClient.publish(
			`cmd_${screen_name}_${this.props.user_id}`,
			JSON.stringify({ message: { cmd: "refresh" } })
		);
	}

	onSearchValueChanged(event) {
		let search_text = event.target.value.toLowerCase();
		let { screen_data_array } = this.props;

		if (search_text === "") {
		} else {
			screen_data_array = _.filter(screen_data_array, data => {
				return (
					data.screen_name.toLowerCase().indexOf(search_text) > -1 ||
					data.location.toLowerCase().indexOf(search_text) > -1 ||
					data.created_at.toLowerCase().indexOf(search_text) > -1
				);
			});

			this.setState({ screen_data_array });
		}
	}

	render() {
		const { screen_data_array } = this.state;

		return (
			<div>
				<div className="row">
					<div className="col-md-12 padding_zero">
						<div className="screen_controller">
							<div className="row">
								<div className="col-md-6">
									<h2 className="inline">
										<i className="glyphicon glyphicon-check" /> Paired Screens
									</h2>
								</div>
								<div className="col-md-6">
									<input
										className="input-css"
										onChange={this.onSearchValueChanged.bind(this)}
										name="screen_name"
										type="text"
										placeholder="Search"
									/>
								</div>
							</div>

							<div className="table-width mb-20">
								<table className="table table-hover table-bordered">
									<thead>
										<tr className="bg-success">
											<th>Screen Name</th>
											<th>Location</th>
											<th>Paired</th>
											<th>Refresh</th>
											<th>UnPair</th>
											<th>Logs</th>
											<th>ScreenShot</th>
											<th>Screen Status</th>
											<th>ScreenShot Gallery</th>
										</tr>
									</thead>
									<tbody>
										{screen_data_array ? (
											screen_data_array.map((item, id) =>
												item.paired ? (
													<tr className="table-info fcolor-black" key={id}>
														<td>{item.screen_name}</td>
														<td>{item.location}</td>
														<td>{item["paired"].toString()}</td>
														<td>
															<button
																className="auto_margin2 btn btn-info"
																onClick={this.handleRefresh.bind(
																	this,
																	item.screen_name
																)}
															>
																<i className="fa fa-refresh" />
															</button>
														</td>
														<td>
															<UnpairWarningModal
																screen_name={item.screen_name}
															/>
														</td>
														<td>
															<ScreenLogsModal screen_name={item.screen_name} />
														</td>
														<td>
															<ScreenShotModal
																screen_name={item.screen_name}
																user_id={this.props.user_id}
															/>
														</td>
														<td>
															<CheckStatusButton
																screen_name={item.screen_name}
															/>
														</td>
														<td>
															<ScreenShotGallery
																screen_name={item.screen_name}
																user_id={this.props.user_id}
															/>
														</td>
													</tr>
												) : null
											)
										) : (
												<tr>
													<td>No Data</td>
												</tr>
											)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	if (state.auth.user.token) {
		return {
			screen_data_array: state.screen.get_all_screen_data,
			user_id: state.auth.user.token.sub
		};
	} else {
		return {

		}
	}
}

export default connect(
	mapStateToProps,
	{ getAllScreens }
)(ScreenController);
