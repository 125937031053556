import React, { Component } from "react";
import { getAllScreens, removeScreen } from "../../actions/screenActions";
import { connect } from "react-redux";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import DeleteWarningModal from "./DeleteScreenWarningModal";
import UpdateScreenModal from "./UpdateScreenModal";
// import AddScreenToInventoryModal from "./AddScreenToInventoryModal";
import AddScreenToInventoryModalNew from "./AddScreenToInventoryModalNew";
import RemoveScreenFromInventoryModal from "./RemoveScreenFromInventoryModal";
import AlertContainer from "react-alert";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import UpdateInventory from "./updateScreenInventoryDetails"

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportToCSV = (csvData, fileName) => {
	const ws = XLSX.utils.json_to_sheet(csvData);
	const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, fileName + fileExtension);
}


class ShowScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			row: "",
			showModal: false,
			screen_name: "",
			search_text: "",
			searched_data: ""
		};
	}

	componentWillMount() {
		this.props.getAllScreens();
	}

	handleModal(screen_name) {
		this.setState({
			showModal: true,
			screen_name: screen_name,
			screen_data: this.props.screen_data_array
		});
	}

	removeScreenFormatter = (cell, row) => {
		return <DeleteWarningModal modalData={row} />
	}

	updateScreenFormatter = (cell, row) => {
		return <UpdateScreenModal modalData={row} />
	}

	inventoryDataFromatter = (cell, row) => {
		return <div>
			{cell ? (
				<RemoveScreenFromInventoryModal
					alert={this.msg}
					screen={row}
				/>
			) : (
					<AddScreenToInventoryModalNew
						alert={this.msg}
						screen={row}
					/>
				)}
		</div>
	}

	updateInventoryDataFormatter = (cell, row) => {

		if(row.inventory){

			return <UpdateInventory data={row} key={row._id}/>
		} else {
			return <h5>NA</h5>
		}
		
	}

	customExportToCsv = (onClick) => {
		let { screen_data_array } = this.props;
		screen_data_array = screen_data_array.map(item => {
			let obj = {};
			obj["Screen Name"] = item.screen_name;
			obj["Location"] = item.location;
			obj["Pairing Code"] = item.pairing_code;
			return obj;
		})
		exportToCSV(screen_data_array, "Screen_Codes_" + new Date().toISOString());
	}

	createCustomExportCSVButton = (onClick) => {
		return (
			<ExportCSVButton btnText="Download CSV" onClick={() => this.customExportToCsv(onClick)} />
		)
	}

	options = {
		exportCSVBtn: this.createCustomExportCSVButton
	}

	render() {

		const { screen_data_array } = this.props;

		return (
			<div className="mt-15">
				<div className="row">
					<div className="col-md-12">
						<div className="col-md-6 padding_zero">
							<h2>
								<i className="fa fa-th-large" /> Added Screens
							</h2>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12 table-width mt-10">
						<BootstrapTable
							keyField="id"
							data={screen_data_array}
							options={this.options}
							pagination
							search
							exportCSV
						>
							<TableHeaderColumn dataField="screen_name" dataSort>
								Screen Name
								</TableHeaderColumn>
							<TableHeaderColumn dataField="location" dataSort>
								Location
								</TableHeaderColumn>
							<TableHeaderColumn dataField="screen_count" dataSort>
								Screen count
								</TableHeaderColumn>
							<TableHeaderColumn dataField="created_at" dataSort>
								Created At
								</TableHeaderColumn>
							<TableHeaderColumn dataField="screen_type" dataSort>
								Screen Type
								</TableHeaderColumn>
							<TableHeaderColumn dataField="screen_width" dataSort>
								Screen-Width
								</TableHeaderColumn>
							<TableHeaderColumn dataField="screen_height" dataSort>
								Screen-Height
								</TableHeaderColumn>
							<TableHeaderColumn dataField="pairing_code" dataSort>
								Pairing Code
								</TableHeaderColumn>
							<TableHeaderColumn dataField="paired" dataSort>
								Paired
								</TableHeaderColumn>
							<TableHeaderColumn dataField="screen_name" dataFormat={this.removeScreenFormatter}>
								Remove
								</TableHeaderColumn>
							<TableHeaderColumn dataField="screen_name" dataFormat={this.updateScreenFormatter}>
								Update
								</TableHeaderColumn>
							<TableHeaderColumn dataField="inventory" dataSort dataFormat={this.inventoryDataFromatter}>
								Inventory
								</TableHeaderColumn>
							{/* <TableHeaderColumn dataField="" dataSort dataFormat={this.updateInventoryDataFormatter}>
								Inventory Update
								</TableHeaderColumn> */}
						</BootstrapTable>
					</div>
				</div>
				<AlertContainer ref={a => (this.msg = a)} {...this.alertOptions} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	
	return {
		screen_data_array: state.screen.get_all_screen_data
	};
}

export default connect(
	mapStateToProps,
	{ getAllScreens, removeScreen }
)(ShowScreen);
