import React ,{Component} from 'react';
import {Modal} from 'react-bootstrap';
// import extra from '../../utils/extra';
import mqttClient from "../../utils/mqtt_config";

 class ScreenShotModal extends Component{
    constructor(props){
        super(props);
        this.state={
             modal: false,
             screenshot_url: '',
             screenshot_status: false,
             date: ''
        }
    }
    
    componentWillMount(){

        
    }
    
    componentWillUnmount() {
        this.setState({screenshot_status:false});
        mqttClient.unsubscribe(`cmd_screenshot_${this.props.user_id}_${this.props.screen_name}`)

    }

    handleScreenShot(screen_name){
        mqttClient.publish(`cmd_${screen_name}_${this.props.user_id}`, JSON.stringify({"message":{"cmd": "screen_shot"}} ));    
        mqttClient.subscribe(`cmd_screenshot_${this.props.user_id}_${this.props.screen_name}`);
        mqttClient.on('message', (topic, message) => {
            let data = JSON.parse(message.toString());

            if(topic === `cmd_screenshot_${this.props.user_id}_${this.props.screen_name}`){
                if(data.screenshot){
                    this.setState({screenshot_status:true, screenshot_url:data.screenshot.screenshot_url, date: data.screenshot.date, modal: true});
                }
            }
            
        });
    }

    handleDownload(){
        window.open(this.state.screenshot_url);
    }

    close(){
        this.setState({modal: false, screenshot_status:false});
        mqttClient.unsubscribe(`cmd_screenshot_${this.props.user_id}_${this.props.screen_name}`)

    }

    render(){
        return(
                <div>
                    <button className="auto_margin2 btn btn-info" onClick={this.handleScreenShot.bind(this, this.props.screen_name)}>
                        <i className="fa fa-unlink">{}</i>
                    </button>
                    <Modal show={this.state.modal} onHide={this.close.bind(this)}>
                        <Modal.Body >
                            <div>
                            <img className="screen_shot_modal" alt="preview" src={this.state.screenshot_url}/>
                            <div className="row">
                                <div className="col-md-2">
                                    <button className="btn btn-primary btn-lg btn-block" onClick={this.handleDownload.bind(this)}><i className="fa fa-download"></i></button>
                                </div>
                                <div className="col-md-10">
                                    <div className="alert alert-warning timestamp_class">{this.state.date}</div>
                                </div>
                            </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
        )
    }}
    

export default  ScreenShotModal;