import React, { Component } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { registerUser } from "../../actions/signupActions";
import classnames from "classnames";


class Register extends Component {

  state = {
    name: "",
    company_name: "",
    email: "",
    password: "",
    confirm_password: "",
    role: "",
    errors: {}
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {name, company_name, email, password, confirm_password, role} = this.state;

    if(name === ""){
      this.setState({ errors : { name : "Name is Required"}});
      return;
    }

    if(company_name === ""){
      this.setState({ errors : { company_name : "Company Name is Required"}});
      return;
    }

    if(email === ""){
      this.setState({ errors : { email : "Email is Required"}});
      return;
    }
    if(password === ""){
      this.setState({ errors : { password : "Password is Required"}});
      return;
    }
    if(confirm_password === ""){
      this.setState({ errors : { confirm_password : "Confirm Password is Required"}});
      return;
    }
    if(role === ""){
      this.setState({ errors : { role : "Role is Required"}});
      return;
    }

    const user = {
      name,
      company_name,
      email,
      password,
      confirm_password,
      role
    }
    this.props.registerUser(user, ()=>{
      console.log("Form Submitted");
      this.props.router.push("/login");
    })
    // console.log(this.state);
    // console.log("On Submit Called");
  }

  onChange = (e) => {

    this.setState({errors: {}});
    this.setState({[e.target.name] : e.target.value});
    
  }

  componentWillReceiveProps(nextProps){
    // console.log("nextProps :", nextProps.errors);
    if(nextProps.errors){
      this.setState({errors: nextProps.errors});
    }
  }

  render() {
    // const {name, company_name, email, password, confirm_password, role} = this.state;
    const { errors } = this.state
    return (
      <div className="container">

        <form onSubmit={this.onSubmit} style={{ width : "500px", margin: "5% auto"}}> 

          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              className={classnames("form-control", {'is-valid' : errors.name})}
              id="name" 
              name="name" 
              type="text" 
              placeholder="Enter Name"
              onChange={this.onChange}
              value={this.state.name}
            />
            { errors.name && <div className="invalid-feedback">{errors.name}</div> }
          </div>

          <div className="form-group">
            <label htmlFor="company_name">Company Name</label>
            <input 
              className={classnames("form-control", {'is-valid' : errors.company_name})} 
              id="company_name" 
              name="company_name" 
              type="text" 
              placeholder="Enter Company Name"
              onChange={this.onChange}
              value={this.state.company_name}
            />
            { errors.company_name && <div className="invalid-feedback">{errors.company_name}</div> }
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              className={classnames("form-control", {'is-valid' : errors.email})} 
              id="email" 
              name="email" 
              type="text" 
              placeholder="Enter Email"
              onChange={this.onChange}
              value={this.state.email}
            />
            { errors.email && <div className="invalid-feedback">{errors.email}</div> }
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              className={classnames("form-control", {'is-valid' : errors.password})}
              id="password" 
              name="password" 
              type="password" 
              placeholder="Enter password"
              onChange={this.onChange}
              value={this.state.password}
            />
            { errors.password && <div className="invalid-feedback">{errors.password}</div> }
          </div>

          <div className="form-group">
            <label htmlFor="confirm_pasword">Confirm Password</label>
            <input 
            className={classnames("form-control", {'is-valid' : errors.confirm_password})}
            id="confirm_password" 
            name="confirm_password" 
            type="password" 
            placeholder="Confirm Pasword"
            onChange={this.onChange}
            value={this.state.confirm_password}
            />
            { errors.confirm_password && <div className="invalid-feedback">{errors.confirm_password}</div> }
          </div>

          <div className="form-group">
            <label>Role</label>
            <select 
              className={classnames("form-control", {'is-valid' : errors.role})}
              name="role"
              onChange={this.onChange}
            >
              <option value="">Select Role</option>
              <option value="Media Owner">Media Owner</option>
              <option value="Advertiser">Advertiser</option>
            </select>
            { errors.role && <div className="invalid-feedback">{errors.role}</div> }
          </div>

          <input type="submit" className="btn btn-primary" value="submit"/>

        </form>
        
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    errors : state.errors
  }
}

const RegisterWithRouter = withRouter(Register);

export default connect(mapStateToProps, {registerUser})(RegisterWithRouter);