import React, { Component } from 'react';
import { Link } from 'react-router'
import { logout } from '../../actions/authActions';
import { connect } from 'react-redux';

class Sidebar extends Component {

  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
  }

  logout(e) {
    e.preventDefault();
    this.props.logout();
  }

  render() {
    return (

      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            <li className="nav-item">
              <Link to={'/dashboard'} className="nav-link" activeClassName="active"><i className="icon-speedometer"></i> Dashboard </Link>
            </li>
            {this.props.role === "Media Owner" ?
              <li className="nav-item">
                <Link to={'/screen'} className="nav-link" activeClassName="active"><i className="fa fa-tv"></i> Screens </Link>
              </li>
              : null}
            {this.props.role === "Advertiser" ?
              <li className="nav-item">
                <Link to={'/inventory'} className="nav-link" activeClassName="active"><i className="fa fa-tv"></i> Inventory </Link>
              </li>
              : null}

            {/* {this.props.role === "Media Owner" ?
              <li className="nav-item">
                <Link to={'/appstore'} className="nav-link" activeClassName="active"><i className="fa fa-table"></i> AppStore </Link>
              </li> : null
            } */}
            <li className="nav-item">
              <Link to={'/contentlibrary'} className="nav-link" activeClassName="active"><i className="fa fa-file-o"></i> Content Library </Link>
            </li>

            <li className="nav-item">
              <Link to={'/contentApprove'} className="nav-link" activeClassName="active"><i className="fa fa-file-o"></i> Content Approval </Link>
            </li>

            <li className="nav-item">
              <Link to={'/playlistsNschedule'} className="nav-link" activeClassName="active"><i className="fa fa-list"></i> Playlists And Schedules </Link>
            </li>

            <li className="nav-item">
                <Link to={'/campaigns'} className="nav-link" activeClassName="active"><i className="fa fa-tv"></i> Campaigns </Link>
            </li>
            
            {
              this.props.role === "Media Owner" &&
              <li className="nav-item">
                <Link to={'/approve'} className="nav-link" activeClassName="active"><i className="fa fa-list"></i> Approve Playlists </Link>
              </li>
            }
            <li className="nav-item">
              <Link to={'/reports'} className="nav-link" activeClassName="active"><i className="fa fa-list"></i> Reports </Link>
            </li>
            {
              this.props.role === "Media Owner" ?
                <li className="nav-item">
                  <Link to={'/screenController'} className="nav-link" activeClassName="active"><i className="fa fa-list"></i> Screen Controller </Link>
                </li>
                : null
            }
            <li className="nav-item">
              <a href="http://planov2-client.s3-website.ap-south-1.amazonaws.com" target="_blank" rel="noopener noreferrer" className="nav-link" ><i className="fa fa-list"></i> Plano </a>
            </li>

            <li className="nav-item">
              <a href="http://142.93.219.70:5000/" target="_blank" rel="noopener noreferrer" className="nav-link" ><i className="fa fa-line-chart"></i> Signage Analytics </a>
            </li>

            <li className="nav-item">
              <a href="https://pages.razorpay.com/screenopay" target="_blank" rel="noopener noreferrer" className="nav-link" ><i className="fa fa-list"></i> Payment </a>
            </li>
            {/* {
              this.props.role === "Media Owner" ?
                <li className="nav-item">
                  <Link to={'/appController'} className="nav-link" activeClassName="active"><i className="fa fa-list"></i> App Controller </Link>
                </li>
                : null
            } */}
            <li className="nav-item">
              <a href="#" onClick={this.logout.bind(this)} className="nav-link"><i className="fa fa-lock"></i> Logout </a>
            </li>
          </ul>

        </nav>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    role: state.auth.user.role
  }
}

export default connect(mapStateToProps, { logout })(Sidebar);
