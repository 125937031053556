import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Tab,
	Nav,
	NavItem,
	Col,
	Row,
	FormGroup,
	ControlLabel,
	FormControl,
	Well,
} from "react-bootstrap";
import {
	SortableContainer,
	SortableElement,
	arrayMove,
} from "react-sortable-hoc";
import AlertContainer from "react-alert";
import { getAllFiles } from "../../actions/contentActions";
import { updatePlayList } from "../../actions/playlistActions";
import mqttClient from "../../utils/mqtt_config";

function pad(n) {
	return n < 10 ? "0" + n : n;
}

class UpdatePlaylistComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			drag_start: "",
			drage_start_item_type: "",
			playlist_name: "",
			to_be_played_from: "",
			to_be_played_to: "",
			playlist_data: [],
			temp_url: "",
		};
	}

	componentWillMount() {
		this.props.getAllFiles();

		var f_date = new Date(this.props.data.to_be_played_from);
		var t_date = new Date(this.props.data.to_be_played_to);
		var from_date =
			f_date.getFullYear() +
			"-" +
			pad(f_date.getMonth() + 1) +
			"-" +
			pad(f_date.getDate()) +
			"T" +
			pad(f_date.getHours()) +
			":" +
			pad(f_date.getMinutes());
		var to_date =
			t_date.getFullYear() +
			"-" +
			pad(t_date.getMonth() + 1) +
			"-" +
			pad(t_date.getDate()) +
			"T" +
			pad(t_date.getHours()) +
			":" +
			pad(t_date.getMinutes());

		this.setState({
			playlist_name: this.props.data.playlist_name,
			to_be_played_from: from_date,
			to_be_played_to: to_date,
			playlist_data: this.props.data.playlist_data,
			screen_name: this.props.data.screen_name,
		});
	}

	handleDragStart(item, item_type, e) {
		this.setState({ drag_start: item, drage_start_item_type: item_type });
	}

	handleDropEvent(e) {
		e.preventDefault();
		if (
			this.state.drage_start_item_type === "image" ||
			this.state.drage_start_item_type === "video"
		) {
			this.setState({
				playlist_data: this.state.playlist_data.concat([
					{
						content: this.state.drag_start.file_name,
						duration: this.state.drag_start.duration,
						type: this.state.drag_start.file_type,
						original_name: this.state.drag_start.original_name,
						file_url: this.state.drag_start.file_url,
						file_type: this.state.drag_start.file_type,
					},
				]),
			});
		} else if (this.state.drage_start_item_type === "html") {
			const { drag_start, drage_start_item_type } = this.state;

			const original_name = drag_start
				.replace("//", "_dslash_")
				.replace("/", "_slash_");

			this.setState({
				playlist_data: this.state.playlist_data.concat([
					{
						content: drag_start,
						duration: 5.0,
						type: drage_start_item_type,
						original_name: original_name,
						file_url: drag_start,
						file_type: drage_start_item_type,
					},
				]),
			});
		}
	}

	handleDragOver(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	handleItemDelete(item, e) {
		let table_item = this.state.playlist_data;
		let index = table_item.indexOf(item);
		table_item.splice(index, 1);
		this.setState({ playlist_data: table_item });
	}

	handleOnPlaylistNameChange(e) {
		this.setState({
			playlist_name: e.target.value,
		});
	}

	handleFromChange(e) {
		this.setState({ to_be_played_from: e.target.value });
	}

	handleToChange(e) {
		this.setState({ to_be_played_to: e.target.value });
	}

	handleDurationChange(original_item, e) {
		this.setState({
			playlist_data: this.state.playlist_data.map((playlist_item) => {
				if (playlist_item.original_name === original_item.original_name) {
					original_item.duration = e.target.value;
					return original_item;
				} else {
					return playlist_item;
				}
			}),
		});
	}

	handleFormUpdate(e) {
		e.preventDefault();
		if (this.state.playlist_name === "") {
			this.msg.show("playlist name cannot be empty");
		} else if (this.state.playlist_name.length < 5) {
			this.msg.show("playlist name length should be greater than 5 characters");
		} else if (
			new Date(this.state.to_be_played_to) <=
			new Date(this.state.to_be_played_from)
		) {
			this.msg.show("select valid duration");
		} else if (this.state.playlist_data.length === 0) {
			this.msg.show("Please add some contents");
		} else {
			this.props
				.updatePlayList(
					this.props.data._id,
					this.state.playlist_name,
					new Date(this.state.to_be_played_from).toISOString(),
					new Date(this.state.to_be_played_to).toISOString(),
					this.state.playlist_data
				)
				.then(() => {
					mqttClient.publish(
						`cmd_${this.props.data.screen_name}_${this.props.user_id}`,
						JSON.stringify({ message: { cmd: "refresh" } })
					);
					this.props.handleCancelUpdateButtonClick("updated");
				});
		}
	}

	handleUrlChange(e) {
		this.setState({ temp_url: e.target.value });
	}

	SortableItem = SortableElement(({ value }) => (
		<div className="col-sm-12 well well-sm">
			<div className="col-md-5 text-truncate">{value.original_name}</div>
			<input
				className="col-md-2"
				value={value.duration}
				onChange={this.handleDurationChange.bind(this, value)}
				min="1"
				type="number"
			/>
			<div className="col-md-1">
				<button
					className="auto_margin2 btn btn-danger"
					onClick={this.handleItemDelete.bind(this, value)}
				>
					<i className="fa fa-trash"></i>
				</button>
			</div>
		</div>
	));

	SortableList = SortableContainer(({ items }) => {
		return (
			<ul>
				{items.map((value, index) => (
					<this.SortableItem
						key={`item-${index}`}
						index={index}
						value={value}
					/>
				))}
			</ul>
		);
	});

	onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			this.setState({
				playlist_data: arrayMove(this.state.playlist_data, oldIndex, newIndex),
			});
		}
	};

	choosenElementsSortableHOC = (items) => {
		console.log("items in sort", items);
		return (
			<div className="mt-3 playlist-choosen-scroll hoc-root">
				<this.SortableList items={items} onSortEnd={this.onSortEnd} />
			</div>
		);
	};

	render() {
		return (
			<div>
				<div className="col-md-12">
					<div className="col-md-8">
						<Row>
							<Col sm={12}>
								<form>
									<h2>Update PlayList</h2>
									<Col sm={6}>
										<div className="col-md-12">
											<FormGroup>
												<ControlLabel>Enter PlayList Name</ControlLabel>
												<FormControl
													type="text"
													value={this.state.playlist_name}
													onChange={this.handleOnPlaylistNameChange.bind(this)}
													placeholder="Enter PlayList Name"
												/>
											</FormGroup>
										</div>
									</Col>
								</form>
							</Col>
							<div className="col-md-12">
								<div className="form-group col-md-6">
									<label
										htmlFor="schedule_from"
										className="col-form-label col-md-4"
									>
										Schedule From:
									</label>
									<input
										type="datetime-local"
										className="form-control col-md-8"
										name="schedule_from"
										value={this.state.to_be_played_from}
										onChange={this.handleFromChange.bind(this)}
									/>
								</div>
								<div className="form-group col-md-6">
									<label
										htmlFor="schedule_to"
										className="col-form-label col-md-4"
									>
										Schedule To:
									</label>
									<input
										type="datetime-local"
										name="schedule_to"
										className="form-control col-md-8"
										value={this.state.to_be_played_to}
										onChange={this.handleToChange.bind(this)}
									/>
								</div>
							</div>
							<Col
								onDragOver={this.handleDragOver.bind(this)}
								onDrop={this.handleDropEvent.bind(this)}
								sm={12}
							>
								{this.state.playlist_data.length === 0 && (
									<Well bsSize="large">Drag Content Here</Well>
								)}
								{this.state.playlist_data.length !== 0 &&
									this.choosenElementsSortableHOC(this.state.playlist_data)}
							</Col>
						</Row>
						<Row>
							<Col sm={12} className="mt-2">
								<Col sm={6}>
									<button
										className="btn btn-primary btn-block"
										onClick={this.handleFormUpdate.bind(this)}
									>
										Update
									</button>
								</Col>
								<Col sm={6}>
									<button
										className="btn btn-danger btn-block"
										onClick={this.props.handleCancelUpdateButtonClick.bind(
											this,
											"cancel"
										)}
									>
										Cancel
									</button>
								</Col>
							</Col>
						</Row>
					</div>
					<div className="col-md-4">
						<Tab.Container id="tabs-with-dropdown" defaultActiveKey="image">
							<Row className="clearfix">
								<Col sm={12}>
									<Nav bsStyle="tabs">
										<NavItem eventKey="image">Images </NavItem>
										<NavItem eventKey="video">Videos </NavItem>
										<NavItem eventKey="html">html</NavItem>
									</Nav>
								</Col>
								<Col sm={12}>
									<Tab.Content animation>
										<Tab.Pane eventKey="image">
											<Row>
												<Col sm={12} className="playlist-gallery-scroll">
													{this.props.content_data ? (
														this.props.content_data.map(
															(item, key) =>
																item.file_type.search("image") === 0 && (
																	<Col
																		sm={12}
																		key={key}
																		style={{ cursor: "move", margin: "12px" }}
																	>
																		<Row
																			draggable="true"
																			onDragStart={this.handleDragStart.bind(
																				this,
																				item,
																				"image"
																			)}
																		>
																			<Col sm={8}>{item.original_name}</Col>
																		</Row>
																	</Col>
																)
														)
													) : (
														<Col sm={6}> No Images </Col>
													)}
												</Col>
											</Row>
										</Tab.Pane>
										<Tab.Pane eventKey="video">
											<Row>
												<Col sm={12} className="playlist-gallery-scroll">
													{this.props.content_data ? (
														this.props.content_data.map(
															(item, key) =>
																item.file_type.search("video") === 0 && (
																	<Col
																		sm={12}
																		key={key}
																		style={{ cursor: "move", margin: "12px" }}
																	>
																		<Row
																			draggable="true"
																			onDragStart={this.handleDragStart.bind(
																				this,
																				item,
																				"video"
																			)}
																		>
																			<Col sm={8}>
																				<div>{item.original_name}</div>
																			</Col>
																		</Row>
																	</Col>
																)
														)
													) : (
														<Col sm={6}> No Videos </Col>
													)}
												</Col>
											</Row>
										</Tab.Pane>
										<Tab.Pane eventKey="html">
											<Row>
												<Col sm={12}>
													<div className="col-md-12">
														<FormGroup>
															<ControlLabel>Enter URL</ControlLabel>
															<FormControl
																type="text"
																onChange={this.handleUrlChange.bind(this)}
																placeholder="Enter URL"
															/>
														</FormGroup>
														{this.state.temp_url && (
															<button
																draggable="true"
																className="btn btn-primary btn-block"
																onDragStart={this.handleDragStart.bind(
																	this,
																	this.state.temp_url,
																	"html"
																)}
															>
																Drag me to content
															</button>
														)}
													</div>
												</Col>
											</Row>
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</div>
				</div>
				<AlertContainer ref={(a) => (this.msg = a)} {...this.alertOptions} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		content_data: state.content.get_all_files_data,
		campaign_list: state.campaign.campaign_list,
		user_id: state.auth.user.token.sub,
	};
}

export default connect(mapStateToProps, { getAllFiles, updatePlayList })(
	UpdatePlaylistComponent
);
