import React, { Component } from 'react';
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { getAllInventoryScreens, updateInventoryDetails } from "../../actions/inventoryActions";

class updateScreenInventoryDetails extends Component {

    state = {
        show: false,
        screen_id: '',
        details: {
            cluster: "",
            network: "",
            footfall: 0,
            impressions: 0
        },
        file_url: ""
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    componentDidMount() {
        this.props.getAllInventoryScreens();
    }

    componentWillReceiveProps(newProps) {

        const { inventory } = newProps;
        const { data } = this.props;
        const screen = inventory.find(inventory => inventory.screen_id === data._id);

        if (typeof (screen) !== "undefined") {
            const { cluster, network, footfall, impressions } = screen.details;
            const { file_url } = screen;

            this.setState({
                ...this.state,
                details: {
                    cluster,
                    network,
                    footfall,
                    impressions
                },
                screen_id: screen.screen_id,
                file_url
            })
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { details, screen_id, file_url } = this.state;
        // console.log(this.state);

        this.props.updateInventoryDetails(screen_id, details, file_url, () => {
            this.setState({
                ...this.state,
                show: false
            });
        });

    }

    onChange = (e) => {
        // console.log("On Chnage");

        this.setState({
            ...this.state,
            details: {
                ...this.state.details,
                [e.target.name]: e.target.value
            }
        })
    }

    onFileUrlChange = (e) => {
        this.setState({
            ...this.state,
            file_url: e.target.value
        })
    }


    render() {
        // console.log(this.props);

        if (this.props.inventory.length > 0) {


            return (
                <div>
                    <button className="btn btn-primary" onClick={this.handleShow}>
                        <i className="fa fa-sliders" />
                    </button>

                    <Modal show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header>
                            <Modal.Title>Update Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={this.onSubmit}>

                                <div className="form-group">
                                    <label>Cluster</label>
                                    <input
                                        type="text"
                                        name="cluster"
                                        onChange={this.onChange}
                                        value={this.state.details.cluster}
                                        className="form-control"
                                        placeholder="Cluster name"
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Network</label>
                                    <input
                                        type="text"
                                        name="network"
                                        onChange={this.onChange}
                                        value={this.state.details.network}
                                        className="form-control"
                                        placeholder="Network name"
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Impression Count</label>
                                    <input
                                        type="number"
                                        name="impressions"
                                        onChange={this.onChange}
                                        value={this.state.details.impressions}
                                        className="form-control"
                                        placeholder="Impression count"
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Footfall Count</label>
                                    <input
                                        type="number"
                                        name="footfall"
                                        onChange={this.onChange}
                                        value={this.state.details.footfall}
                                        className="form-control"
                                        placeholder="Footfall count"
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Image url</label>
                                    <input
                                        type="text"
                                        name="file_url"
                                        onChange={this.onFileUrlChange}
                                        value={this.state.file_url}
                                        className="form-control"
                                        placeholder="Image Url"
                                    />
                                </div>

                                <button className="btn btn-primary">
                                    Update
                            </button>

                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            )
        } else {
            return (
                <button className="btn btn-primary" onClick={this.handleShow} disabled>
                    <i className="fa fa-sliders" />
                </button>
            )
        }
    }
}

const mapStateToProps = (state) => {

    // console.log(state.inventory.get_all_inventory_screens);
    return {
        inventory: state.inventory.get_all_inventory_screens
    }
}

export default connect(mapStateToProps, { getAllInventoryScreens, updateInventoryDetails })(updateScreenInventoryDetails);