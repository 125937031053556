import React, { Component } from 'react';
import InventoryFilters from './InventoryFilters';
import InventoryMap from './InventoryMap';
// import InventoryInfo from './InventoryInfo';
import InventoryList from './InventoryList';
import CreateCampaignFromInventory from './CreateCampaignFromInventory';
import { connect } from 'react-redux';

class Inventory extends Component {
    render() {
        const { isAuthenticated } = this.props;

        return (
            <div className="row animated fadeIn">
                <div className="col-md-10 padding_zero">
                    <InventoryFilters />
                </div>
                <div className="col-md-2 padding_zero">
                    {isAuthenticated && <CreateCampaignFromInventory />}
                </div>
                <div className="col-md-12 padding_zero">
                    <div className="col-md-9 padding_zero"><InventoryMap /></div>
                    <div className="col-md-3 "><InventoryList /></div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ auth }) {
    return {
        isAuthenticated: auth.isAuthenticated
    }
}

export default connect(mapStateToProps)(Inventory);