import React, { Component } from 'react';
import AlertContainer from 'react-alert';
import CreateNewPlaylist from './createNewPlayList';
import ShowPlayListAndSchedules from './showPlayListAndSchedules';
import ShowPlayListCalender from './showPlayListCalender';

class Playlists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createPlaylistClicked: false,
      view_chooser: "table"
    }
  }

  onCreateButtonClick() {
    this.setState({
      createPlaylistClicked: true
    })
  }

  handleTableButtonClick() {
    this.setState({
      view_chooser: 'table'
    })
  }

  handleCalenderButtonClick() {
    this.setState({
      view_chooser: 'calender'
    })
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-md-12"> 
            { this.state.createPlaylistClicked ?
              <CreateNewPlaylist /> :
              <div>
                <div className="row mb-30 padding_zero">

                  <div className="col-md-5 padding_zero">
                    <h3 className="inline"><i className="fa fa-file-text-o"></i> Create New PlayList</h3>
                    <button type="button" className="btn btn-info" onClick={this.onCreateButtonClick.bind(this)}>
                      <i className="fa fa-file-text-o"></i> Create New PlayList</button>
                  </div>

                  <div className="col-md-7 padding_zero">
                    <h3 className="inline"><i className="fa fa-columns"></i> View Mode</h3>
                    <button className="btn btn-success mr-5" onClick={this.handleTableButtonClick.bind(this)}>
                      <i className="fa fa-list-alt"></i> Table View Playlist</button>
                    <button className="btn btn-primary" onClick={this.handleCalenderButtonClick.bind(this)}><i className="fa fa-calendar"></i> Calender View Playlist</button>
                  </div>
                </div>

                {this.state.view_chooser === "table" ? <ShowPlayListAndSchedules /> : null}
                {this.state.view_chooser === "calender" ? <ShowPlayListCalender /> : null}

              </div>}

          </div>
        </div>
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
      </div>
    )
  }
}

export default Playlists;