import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    markerClicked, getFilteredInventory, fetchInventoryFilter, setStateFilterOptions, setCityFilterOptions
 } from '../../actions/inventoryActions';
import Select from "react-select";
import AlertContainer from 'react-alert';

class InventoryFilters extends Component {
    state = {
        country : '',
        network: '',
        category: '',
        impressions: '',
        state : '',
        city : '',
        footfalls: '',
        network_name :''
    }

    handleReset() {
        const { getFilteredInventory, markerClicked, all_inventory_screens, setCityFilterOptions, setStateFilterOptions } = this.props;
        getFilteredInventory("", "", "", "", "", "", "", all_inventory_screens);
        const { defaultCities, defaultStates  } = this.props.filterOptions;
        markerClicked("");
        this.setState({
            ...this.state,
            country : "",
            state : '',
            city : '',
            network: '',
            category: '',
            impressions: '',
            footfalls: '',
            network_name: "",
        });

        setCityFilterOptions(defaultCities);
        setStateFilterOptions(defaultStates);
    }

    handleNetworkFilterChange(e) {
        this.setState({ network: e.target.value })
    }

    handlecategoryFilterChange(e) {
        this.setState({ category: e.target.value })
    }

    handleFootFalls(e) {
        
        this.setState({ footfalls: e.target.value });
    }

    handleimpressions(e) {
    
        this.setState({ impressions: e.target.value });
    }

    onFilter() {

        let { country, network, category, impressions, footfalls, state, city, network_name } = this.state;
        country = country !== "" ? country.value : "";
        state = state !== "" ? state.value : "";
        city = city !== "" ? city.value : "";
        network = network !== "" ? network.value : "";
        category = category !== "" ? category.value : "";
        network_name = network_name !== "" ? network_name.value : "";

        const { getFilteredInventory, markerClicked, all_inventory_screens } = this.props;
        getFilteredInventory(country , state, city, network, network_name, category, impressions, footfalls, all_inventory_screens);
        markerClicked("");
    }



    handleCountryChange = async (value) => {

        // this.setState({
        //     ...this.state,
        //     country : value.value,
        //     state : "",
        //     city : ""
        // });

        this.setState({
            ...this.state,
            country : value,
            state : "",
            city : ""
        });

        const { locationStruct : struct } = this.props.filterOptions;
        const { setStateFilterOptions, setCityFilterOptions } = this.props;

        // console.log("Country :", value);

        let states =  Object.keys(struct[value.value]).map( v => {
            return {
                label : v,
                value : v
            }
        });

        let cities = [];

        Object.keys(struct[value.value]).map(s => {

            struct[value.value][s].forEach(c => {

                cities = [...cities, { value : c, label :c }]
            });
        })

        // console.log("States :", states);
        // console.log("Cities :", cities);
        setStateFilterOptions(states);
        setCityFilterOptions(cities);

        
    }

    handleStateChange = async (value) => {

        await this.setState({
            ...this.state,
            state : value,
            city : ""
        });

        // console.log("State ::,",this.state);

        const { locationStruct : struct } = this.props.filterOptions;
        const { setStateFilterOptions, setCityFilterOptions } = this.props;

        let country = "";

        Object.keys(struct).forEach(c => {
            
            if(Object.keys(struct[c]).includes(value.value)){
                country = c;
            }
        })

        let cities = [];

        struct[country][value.value].forEach(c => {

            cities = [...cities, { value : c, label :c }]
        });

        setCityFilterOptions(cities);

        

    }

    handleCityChange = (value) => {
        // console.log("City ", value)
        this.setState({
            ...this.state,
            city : value
        })
    }
    handleNetworkChange = (value) => {
        // console.log("City ", value)
        this.setState({
            ...this.state,
            network : value
        })
    }
    handleCategoryChange = (value) => {
        // console.log("City ", value)
        this.setState({
            ...this.state,
            category : value
        })
    }

    handleNetworkNameChange = (value) => {

        this.setState({
            ...this.state,
            network_name: value
        });

    }

    componentDidMount(){
        this.props.fetchInventoryFilter();
    }

    render() {
    
        const { city, state, country , network, category, network_name  } = this.state;
        const { 
            country : countries, cities, networks, network_names, states, categories
         } = this.props.filterOptions;
        // console.log("StateP :", this.props.filterOptions)

        return (
            <div className="col-md-12 m-2">
                <h3 className="inline"><i className="glyphicon glyphicon-map-marker"></i> Search For Screens</h3>
                <div className="row">
                <div className="form-group col-md-2">
                    <Select
                        value={country}
                        onChange={this.handleCountryChange}
                        options={countries}
                        placeholder="Country"
                    
                    />
                </div>
                <div className="form-group col-md-2">
                    <Select
                        value={state}
                        onChange={this.handleStateChange}
                        options={states}
                        placeholder="State"
                    
                    />
                </div>
                <div className="form-group col-md-2">
                    <Select
                        value={city}
                        onChange={this.handleCityChange}
                        options={cities}
                        placeholder="City"
                        ref={ref => {
                            this.select = ref;
                        }}
                        styles={{ menu: base => ({ ...base, zIndex: 1000 }) }}
                    
                    />
                </div>
                <div className="form-group col-md-2">
                    {/* <select name="networkfilter" className="form-control" value={this.state.network} onChange={this.handleNetworkFilterChange.bind(this)}>
                        <option value="">Select Network</option>
                        {netwrok_arr.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select> */}
                    <Select
                        value={network}
                        onChange={this.handleNetworkChange}
                        options={networks}
                        placeholder="Network"
                        ref={ref => {
                            this.select = ref;
                        }}
                        styles={{ menu: base => ({ ...base, zIndex: 1000 }) }}
                    
                    />
                </div>
                <div className="form-group col-md-2">
                    
                    <Select
                        value={network_name}
                        onChange={this.handleNetworkNameChange}
                        options={network_names}
                        placeholder="Network Name"
                    
                    />
                </div>
                <div className="form-group col-md-2">
                    {/* <select name="categoryfilter" className="form-control" value={this.state.category} onChange={this.handlecategoryFilterChange.bind(this)}>
                        <option value="">Select category</option>
                        {category_arr.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select> */}
                    <Select
                        value={category}
                        onChange={this.handleCategoryChange}
                        options={categories}
                        placeholder="Category"
                    
                    />
                </div>
                <div className="form-group col-md-2">
                    <input type="number" className="form-control" value={this.state.footfalls} placeholder="Min Footfalls/week" onChange={this.handleFootFalls.bind(this)} />
                </div>
                <div className="form-group col-md-2">
                    <input type="number" className="form-control" value={this.state.impressions} placeholder="Min impressions/week" onChange={this.handleimpressions.bind(this)} />
                </div>
                <div className="form-group col-md-2">
                    <span className="input-group-btn">
                        <button className="btn btn-raised btn-info btn-block" type="submit" onClick={this.onFilter.bind(this)}>Filter</button>
                    </span>
                </div>
                <div className="form-group col-md-2">
                    <span className="input-group-btn">
                        <button onClick={this.handleReset.bind(this)} className="btn btn-danger btn-block">Reset</button>
                    </span>
                </div>
                </div>
                <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    // console.log("Inventrory :", state.inventory);
    return {
        filterOptions :state.inventory.filterOptions,
        all_inventory_screens: state.inventory.get_all_inventory_screens
    }
}

export default connect(mapStateToProps, {
    getFilteredInventory, markerClicked, fetchInventoryFilter, setCityFilterOptions, setStateFilterOptions
})(InventoryFilters);