
import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import { connect } from 'react-redux';
import { getAllPlayLists, removePlayList } from '../../actions/playlistActions';
import ShowPlaylistModal from './showPlaylistModal';

BigCalendar.setLocalizer(
    BigCalendar.momentLocalizer(moment)
);

class showPlayListCalender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            row: '',
            showModal: false,
            data: ''
        }
    }

    componentWillMount() {
        this.props.getAllPlayLists();
    }

    dataFetcher() {
        let array = [];
        if (this.props.get_all_playlists_data_array) {
            this.props.get_all_playlists_data_array.map((item, index) =>
                array.push({
                    'title': item.playlist_name,
                    'start': new Date(item.to_be_played_from),
                    'end': new Date(item.to_be_played_to),
                    'screen_name': item.screen_name,
                    'playlist_data': item.playlist_data
                })
            );
        }
        return array;
    }

    render() {
        return (
            <div>
                <h2 className="inline"><i className="fa fa-calendar"></i> Calender Schedules</h2>
                <div className="table-width mb-20 table-info">
                    <BigCalendar
                        events={this.dataFetcher()}
                        onSelectEvent={(data) => {
                            this.setState({ showModal: true, data: data });
                        }}
                    />
                    {this.state.showModal ? <ShowPlaylistModal modaldata={this.state.data} showModal={this.state.showModal} /> : <div></div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        get_all_playlists_data_array: state.playlist.get_all_playlists_data_array
    }
}


export default connect(mapStateToProps, { getAllPlayLists, removePlayList })(showPlayListCalender);