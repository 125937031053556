import axios from 'axios';
import extra from '../utils/extra';
import {GET_ERRORS} from "./types";

export function userSignupRequest(userData) {
  return dispatch => {
    return axios.post(extra.rootURL+'/api/signup', {
      name: userData.name,
      email: userData.email,
      password: userData.password,
      role:userData.role
    });
  }
}

export function isUserExists(email) {
  return dispatch => {
    return axios.post(extra.rootURL+'/api/isUserExists', {email: email});
  }
}


export const registerUser = (userData, callback) => dispatch => {

  axios.post(`${extra.rootURL}/api/signup`, userData)
    .then( res => {
      callback();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
} 