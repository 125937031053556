import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Tab,
	Nav,
	NavItem,
	Col,
	Row,
	FormGroup,
	ControlLabel,
	FormControl,
	Well,
} from "react-bootstrap";
import {
	SortableContainer,
	SortableElement,
	arrayMove,
} from "react-sortable-hoc";
import Select from "react-select";
import AlertContainer from "react-alert";
import { getAllFiles } from "../../actions/contentActions";
import {
	createPlayList,
	clearPlaylistMessage,
} from "../../actions/playlistActions";
import { getAllScreens } from "../../actions/screenActions";
import { fetchAdvertiserCampaign } from "../../actions/campaignActions";
import PlayList from "./Playlists";
import mqttClient from "../../utils/mqtt_config";

var myDate = new Date();
var date = myDate.getDate();
var month = myDate.getMonth();
var year = myDate.getFullYear();
var hour = myDate.getHours();
var minute = myDate.getMinutes();

function pad(n) {
	return n < 10 ? "0" + n : n;
}

let formated_date =
	year +
	"-" +
	pad(month + 1) +
	"-" +
	pad(date) +
	"T" +
	pad(hour) +
	":" +
	pad(minute);

class CreateNewPlayList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hideForm: false,
			drag_start: "",
			drage_start_item_type: "",
			before: "",
			after: "",
			playlist_name: "",
			to_be_played_from: formated_date,
			to_be_played_to: formated_date,
			selected_screens: [],
			selected_screens_id: [],
			playlistdata: [],
			temp_url: "",
			role: "",
			campaign_screens: [],
		};
	}

	componentDidMount() {
		const { getAllFiles, fetchAdvertiserCampaign, getAllScreens } = this.props;
		getAllFiles();
		let role = JSON.parse(localStorage.getItem("state")).auth.user.role;
		this.setState({ ...this.state, role: role });
		role === "Advertiser" ? fetchAdvertiserCampaign() : getAllScreens();
	}

	componentWillReceiveProps(nextProps) {
		// console.log("Next", nextProps);
		if (nextProps.msg !== "") {
			this.msg.show(nextProps.msg.data, { type: nextProps.msg.type });
			this.props.clearPlaylistMessage();
		}
	}

	handleDragStart(item, item_type, e) {
		this.setState({ drag_start: item, drage_start_item_type: item_type });
	}

	handleDropEvent(e) {
		e.preventDefault();
		let { drage_start_item_type, drag_start, playlistdata } = this.state;
		const {
			file_name,
			duration,
			file_type,
			original_name,
			file_url,
		} = drag_start;

		if (
			drage_start_item_type === "image" ||
			drage_start_item_type === "video"
		) {
			this.setState({
				...this.state,
				playlistdata: playlistdata.concat([
					{
						content: file_name,
						duration: duration,
						type: file_type,
						original_name: original_name,
						file_url: file_url,
						file_type: file_type,
					},
				]),
			});
		} else if (drage_start_item_type === "html") {
			const original_name = drag_start
				.replace("//", "_dslash_")
				.replace("/", "_slash_");
			this.setState({
				...this.state,
				playlistdata: playlistdata.concat([
					{
						content: drag_start,
						duration: 5.0,
						type: drage_start_item_type,
						original_name: original_name,
						file_url: drag_start,
						file_type: drage_start_item_type,
					},
				]),
			});
		}
	}

	handleDragOver(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	handleCancelForm() {
		this.setState({
			hideForm: true,
		});
	}

	handleItemDelete(item, e) {
		let table_item = this.state.playlistdata;
		let index = table_item.indexOf(item);
		table_item.splice(index, 1);
		this.setState({ playlistdata: table_item });
	}

	handleOnPlaylistNameChange(e) {
		this.setState({
			...this.state,
			playlist_name: e.target.value,
		});
	}

	handleFromChange(e) {
		this.setState({ ...this.state, to_be_played_from: e.target.value });
	}

	handleToChange(e) {
		this.setState({ ...this.state, to_be_played_to: e.target.value });
	}

	handleScreenSelected(choosen_screen_id_array) {
		const selected_screens = choosen_screen_id_array.map((item) => {
			return item.label;
		});
		const selected_screens_id = choosen_screen_id_array.map((item) => {
			return item.value;
		});
		this.setState({ ...this.state, selected_screens, selected_screens_id });
	}

	handleDurationChange(item, e) {
		const { value } = e.target;
		let { playlistdata } = this.state;
		let index = playlistdata.indexOf(item);
		let temp_item = item;
		temp_item.duration = value;
		playlistdata.splice(index, 1, temp_item);
		this.setState({ ...this.state, playlistdata });
	}

	handleCampaignSelected(e) {
		let index = e.target.value;
		if (index !== "-1") {
			let screens = this.props.campaign_list[index].screens;
			this.setState({ campaign_screens: screens });
		} else {
			this.setState({ ...this.state, campaign_screens: [] });
		}
	}

	handleFormSubmit(e) {
		e.preventDefault();

		const {
			playlist_name,
			playlistdata,
			to_be_played_from,
			to_be_played_to,
			selected_screens,
			selected_screens_id,
		} = this.state;
		const { createPlayList } = this.props;
		if (playlist_name === "") {
			this.msg.show("playlist name cannot be empty");
		} else if (playlist_name.length < 5) {
			this.msg.show("playlist name length should be greater than 5 characters");
		} else if (new Date(to_be_played_to) <= new Date(to_be_played_from)) {
			this.msg.show("select valid duration");
		} else if (selected_screens.size === 0) {
			this.msg.show("Please select screen");
		} else if (playlistdata.length === 0) {
			this.msg.show("Please add some contents");
		} else {
			// createPlayList(playlist_name, playlistdata, new Date(to_be_played_from).toUTCString(),
			// 	new Date(to_be_played_to).toUTCString(), selected_screens, selected_screens_id).then(() => {
			// 		selected_screens.forEach(screen_name => {
			// 			mqttClient.publish(`cmd_${screen_name}_${this.props.user_id}`, JSON.stringify({ message: { cmd: "refresh" } }));
			// 		});
			// 		this.setState({ hideForm: true });
			// 	});

			createPlayList(
				playlist_name,
				playlistdata,
				new Date(to_be_played_from).toUTCString(),
				new Date(to_be_played_to).toUTCString(),
				selected_screens,
				selected_screens_id,
				() => {
					selected_screens.forEach((screen_name) => {
						mqttClient.publish(
							`cmd_${screen_name}_${this.props.user_id}`,
							JSON.stringify({ message: { cmd: "refresh" } })
						);
					});
					this.setState({ ...this.state, hideForm: true });
				}
			);
		}
	}

	handleUrlChange(e) {
		this.setState({ ...this.state, temp_url: e.target.value });
	}

	SortableItem = SortableElement(({ value }) => (
		<div className="col-sm-12 well well-sm">
			<div className="col-md-5  text-truncate">{value.original_name}</div>
			{value.type.search("video") === 0 ? (
				<input
					className="col-md-2"
					onChange={this.handleDurationChange.bind(this, value)}
					defaultValue={value.duration}
					type="number"
				/>
			) : (
				<input
					className="col-md-2"
					onChange={this.handleDurationChange.bind(this, value)}
					defaultValue={5.0}
					value={value.duration}
					min="1"
					type="number"
				/>
			)}
			<div className="col-md-1">
				<button
					className="auto_margin2 btn btn-danger"
					onClick={this.handleItemDelete.bind(this, value)}
				>
					<i className="fa fa-trash" />
				</button>
			</div>
		</div>
	));

	SortableList = SortableContainer(({ items }) => {
		return (
			<ul>
				{items.map((value, index) => (
					<this.SortableItem
						key={`item-${index}`}
						index={index}
						value={value}
					/>
				))}
			</ul>
		);
	});

	onSortEnd = ({ oldIndex, newIndex }) => {
		const { playlistdata } = this.state;
		if (oldIndex !== newIndex) {
			this.setState({
				...this.state,
				playlistdata: arrayMove(playlistdata, oldIndex, newIndex),
			});
		}
	};

	choosenElementsSortableHOC = (items) => {
		// These 2 lines are for debugging purposes
		// console.log("this.sort", this.SortableList);
		// console.log("items in sort", items);
		return (
			<div className="mt-3 playlist-choosen-scroll hoc-root">
				<this.SortableList items={items} onSortEnd={this.onSortEnd} />
			</div>
		);
	};

	render() {
		const { hideForm, role, campaign_screens, playlistdata } = this.state;
		const { campaign_list, screens_data } = this.props;
		// console.log(role, screens_data)

		console.log("screenlist_List", campaign_list);

		// console.log("Playlist Props :", this.props);

		return (
			<div>
				{hideForm ? (
					<PlayList />
				) : (
					<div className="col-md-12">
						<div className="col-md-8">
							<Row>
								<Col sm={12}>
									<form>
										<h2>Create New PlayList</h2>
										<Col sm={6}>
											<FormGroup>
												<ControlLabel>Enter PlayList Name</ControlLabel>
												<FormControl
													type="text"
													onChange={this.handleOnPlaylistNameChange.bind(this)}
													placeholder="Enter PlayList Name"
												/>
											</FormGroup>
											{role === "Advertiser" && (
												<FormGroup>
													<ControlLabel>Choose Campaign</ControlLabel>
													<FormControl
														componentClass="select"
														onChange={this.handleCampaignSelected.bind(this)}
													>
														<option value={-1}>Choose Campaign</option>
														{campaign_list.map((item, key) => (
															<option key={key} value={key}>
																{item.campaignName}
															</option>
														))}
													</FormControl>
												</FormGroup>
											)}
										</Col>
										<Col sm={6}>
											{role === "Media Owner" && (
												<FormGroup>
													<ControlLabel>Select Screens</ControlLabel>
													{screens_data.length !== 0 ? (
														<Select
															onChange={this.handleScreenSelected.bind(this)}
															isMulti={true}
															options={screens_data.map((item) => {
																const { screen_name, _id } = item;
																return { value: _id, label: screen_name };
															})}
														/>
													) : (
														<option value="invalid_screen">
															No Screen Available
														</option>
													)}
												</FormGroup>
											)}
											{role === "Advertiser" && (
												<FormGroup>
													<ControlLabel>Select Screens</ControlLabel>
													{campaign_screens.length !== 0 ? (
														<Select
															onChange={this.handleScreenSelected.bind(this)}
															isMulti={true}
															options={campaign_screens.map((item) => {
																const { screen_name, _id } = item;
																return { value: _id, label: screen_name };
															})}
														/>
													) : (
														<option value="invalid_screen">
															Choose Campaign
														</option>
													)}
												</FormGroup>
											)}
										</Col>
									</form>
								</Col>
								<div className="col-md-12">
									<div className="form-group col-md-6">
										<label
											htmlFor="schedule_from"
											className="col-form-label col-md-12"
										>
											Schedule From:
										</label>
										<input
											type="datetime-local"
											className="form-control col-md-12"
											name="schedule_from"
											value={this.state.to_be_played_from}
											onChange={this.handleFromChange.bind(this)}
										/>
									</div>
									<div className="form-group col-md-6">
										<label
											htmlFor="schedule_to"
											className="col-form-label col-md-12"
										>
											Schedule To:
										</label>
										<input
											type="datetime-local"
											name="schedule_to"
											className="form-control col-md-12"
											value={this.state.to_be_played_to}
											onChange={this.handleToChange.bind(this)}
										/>
									</div>
								</div>
								<Col
									onDragOver={this.handleDragOver.bind(this)}
									onDrop={this.handleDropEvent.bind(this)}
									sm={12}
								>
									{playlistdata.length === 0 && (
										<Well bsSize="large">Drag Content Here</Well>
									)}
									{playlistdata.length !== 0 &&
										this.choosenElementsSortableHOC(playlistdata)}
								</Col>
							</Row>
							<Row>
								<Col sm={12} className="mt-2">
									<Col sm={6}>
										<button
											className="btn btn-primary btn-block"
											onClick={this.handleFormSubmit.bind(this)}
										>
											Submit
										</button>
									</Col>
									<Col sm={6}>
										<button
											className="btn btn-danger btn-block"
											onClick={this.handleCancelForm.bind(this)}
										>
											Cancel
										</button>
									</Col>
								</Col>
							</Row>
						</div>
						<div className="col-md-4">
							<Tab.Container id="tabs-with-dropdown" defaultActiveKey="image">
								<Row className="clearfix">
									<Col sm={12}>
										<Nav bsStyle="tabs">
											<NavItem eventKey="image">Images </NavItem>
											<NavItem eventKey="video">Videos </NavItem>
											<NavItem eventKey="html">html</NavItem>
										</Nav>
									</Col>
									<Col sm={12}>
										<Tab.Content animation>
											<Tab.Pane eventKey="image">
												<Row>
													<Col sm={12} className="playlist-gallery-scroll">
														{this.props.content_data ? (
															this.props.content_data.map(
																(item, key) =>
																	item.file_type.search("image") === 0 && (
																		<Col
																			sm={12}
																			key={key}
																			style={{ cursor: "move", margin: "12px" }}
																		>
																			<Row
																				draggable="true"
																				onDragStart={this.handleDragStart.bind(
																					this,
																					item,
																					"image"
																				)}
																			>
																				<Col sm={8}>{item.original_name}</Col>
																			</Row>
																		</Col>
																	)
															)
														) : (
															<Col sm={12}>No Images</Col>
														)}
													</Col>
												</Row>
											</Tab.Pane>
											<Tab.Pane eventKey="video">
												<Row>
													<Col sm={12} className="playlist-gallery-scroll">
														{this.props.content_data ? (
															this.props.content_data.map(
																(item, key) =>
																	item.file_type.search("video") === 0 && (
																		<Col
																			sm={12}
																			key={key}
																			style={{ cursor: "move", margin: "12px" }}
																		>
																			<Row
																				draggable="true"
																				onDragStart={this.handleDragStart.bind(
																					this,
																					item,
																					"video"
																				)}
																			>
																				<Col sm={8}>
																					<div>{item.original_name}</div>
																				</Col>
																			</Row>
																		</Col>
																	)
															)
														) : (
															<Col sm={12}>No Videos</Col>
														)}
													</Col>
												</Row>
											</Tab.Pane>
											<Tab.Pane eventKey="html">
												<Row>
													<Col sm={12}>
														<div className="col-md-12">
															<FormGroup>
																<ControlLabel>Enter URL</ControlLabel>
																<FormControl
																	type="text"
																	onChange={this.handleUrlChange.bind(this)}
																	placeholder="Enter URL"
																/>
															</FormGroup>
															{this.state.temp_url && (
																<button
																	draggable="true"
																	className="btn btn-primary btn-block"
																	onDragStart={this.handleDragStart.bind(
																		this,
																		this.state.temp_url,
																		"html"
																	)}
																>
																	{" "}
																	Drag me to content{" "}
																</button>
															)}
														</div>
													</Col>
												</Row>
											</Tab.Pane>
										</Tab.Content>
									</Col>
								</Row>
							</Tab.Container>
						</div>
					</div>
				)}
				<AlertContainer ref={(a) => (this.msg = a)} {...this.alertOptions} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		content_data: state.content.get_all_files_data.filter(
			(c) => c.approval_status === "Approved"
		),
		screens_data: state.screen.get_all_screen_data,
		campaign_list: state.campaign.campaign_list,
		// user_id: state.auth.user.token.sub,
		msg: state.playlist.msg,
	};
}

export default connect(mapStateToProps, {
	getAllFiles,
	fetchAdvertiserCampaign,
	createPlayList,
	getAllScreens,
	clearPlaylistMessage,
})(CreateNewPlayList);
