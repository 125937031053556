import React, { Component } from 'react';
import Select from "react-select";
import { connect } from "react-redux";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { getAllFiles } from "../../actions/contentActions";
import { 
  fetchLiveStats, getScreenCreativeList, getImpressionCountForScreen
} from "../../actions/impressionsReportAction";
import { sub } from "date-fns";
import { secondsToTotalTime } from "../../utils/extra";


const timeDurations = [
  { value : "today" , label : "Today", type : "today" },
  { value : 15 , label : "Last 15 Min", type : "minute" },
  { value : 30 , label : "Last 30 Min", type : "minute" },
  { value : 1 , label : "Last 1 Hour", type : "hour" },
  { value : 2 , label : "Last 2 Hour", type : "hour" },
  { value : 6 , label : "Last 6 Hour", type : "hour" },
  { value : 12 , label : "Last 12 Hour", type : "hour" },
  { value : 24 , label : "Last 24 Hour", type : "hour" },
  { value : 7 , label : "Last 1 Week", type : "days" },
  { value : 14 , label : "Last 2 Weeks", type : "days" },
  { value : 21 , label : "Last 3 Weeks", type : "days" },
  { value : 30 , label : "Last 1 Month", type : "days" },
];

class LiveLogsReportTable extends Component {
  state = {
    time : "",
    start_date : "",
    end_date : ""
  }

  options = {
		// defaultSortName: "updated_at", // default sort column name
		defaultSortOrder: "desc", // default sort order
    paginationPosition: "top", // default is bottom, top and both is all available
    onlyOneExpanding : true
	};

  componentDidMount(){
    this.props.getScreenCreativeList();
  }

  onChange = (data) => {

    // console.log(data);
    const  { screenCreativeList, getImpressionCountForScreen } =  this.props

    const { value, type } = data;

    let start_date = "";
    let end_date = "";

    if(type === "today"){

      start_date = new Date(new Date().setHours(0,0,0,0)).toISOString();
      end_date = new Date().toISOString();

    } else if (type === "minute") {

      end_date = new Date().toISOString();
      start_date = sub(new Date(), { minutes : value }).toISOString();

    } else if(type === "hour") {

      end_date = new Date().toISOString();
      start_date = sub(new Date(), { hours : value }).toISOString();

    } else if(type === "days") {

      end_date = new Date().toISOString();
      start_date = sub(new Date(), { days : value }).toISOString();

    }

    this.setState({
      ...this.state,
      time : data,
      start_date,
      end_date
    });

    const items = {
      start_date,
      end_date,
      screenCreativeList
    }

    getImpressionCountForScreen(items);

    // const data = {
    //   content_name : value.original_name,
    //   start_date : sub(new Date(), { hours : 6 }).toISOString(),
    //   end_date : new Date().toISOString()
    // }

    // this.props.fetchLiveStats(data);
  }

  campaignNameFormat = (cell, row) => {
    return row.playlist_name.replace(`_${row.screen_name}`, "");
  }

  startDateFormat = (cell, row) => {
    return new Date(cell).toLocaleDateString();
  }

  endDateFormat = (cell, row) => {
    return new Date(cell).toLocaleDateString();
  }

  timestampFormat = (cell, row) => {
    if(cell === ""){
      return "-";
    } else {
      return secondsToTotalTime(cell);
    }
  }

  connectedFormat = (cell, row) => {
    return cell.toString();
  }

  footfallFormat = (cell, row) => {

    if(cell === "" || parseInt(cell) < 10){
      return "TBA";
    } else {
      return cell;
    }
  }

  impressionCountFormater = (cell, row) => {

    if(cell === ""){
      return '-';
    } else {
      return cell;
    }

  }

  subTable = (row) => {
    return (
      <BootstrapTable data={ row.creatives }>
          <TableHeaderColumn dataField='original_name' isKey={ true }>Campaign</TableHeaderColumn>
          <TableHeaderColumn dataField='impression_count'>Total Impressions</TableHeaderColumn>
          <TableHeaderColumn dataField='publisher_impression_count'>Publisher Impression </TableHeaderColumn>
          <TableHeaderColumn dataField='total_playtime' dataFormat={this.timestampFormat}>Total Playtime</TableHeaderColumn>
          <TableHeaderColumn dataField='publisher_playtime'dataFormat={this.timestampFormat}>Publisher Playtime</TableHeaderColumn>
        </BootstrapTable>
    );
  }

  isExpandableRow = (row) => {

    if(row.impression_count > 0 ){
      return true;
    } else {
      return false;
    }

  }

  render() {
    
    return (
      <div>
        <div className="row">
          <div className="col-md-4">

            <Select 

              value={this.state.time}
              options={timeDurations}
              // getOptionLabel={ option => option.label }
              // getOptionValue={ option => option.value }
              onChange={this.onChange}
              placeholder="Select Duration"

            />

          </div>
          {/* <div className="col-md-4">
            <strong>Total Impressions : {this.props.contentCount}</strong>
          </div> */}
        </div>
        <div>

          { this.state.start_date !== ""  ? 

          <BootstrapTable
            keyField="screen_name"
            data={this.props.screenCreativeList}
            options={this.options}
            pagination
            search
            expandComponent={this.subTable}
            expandColumnOptions={ { expandColumnVisible: true } }
            expandableRow={ this.isExpandableRow }
            
          >
            <TableHeaderColumn 
              dataField="screen_name" 
              dataSort
              
            >
              Screen Name
            </TableHeaderColumn>

            <TableHeaderColumn 
              dataField="impression_count" 
              dataSort 
              dataFormat={this.impressionCountFormater}
            >
              Impression Count
            </TableHeaderColumn>

            <TableHeaderColumn 
              dataField="footfall" 
              dataSort 
              dataFormat={this.footfallFormat}
            >
              Footfall
            </TableHeaderColumn>

            <TableHeaderColumn 
              dataField="publisher_impression_count" 
              dataSort 
              // dataFormat={this.endDateFormat}
            >
              Publisher Impression Count
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="total_playtime"
              dataSort
              dataFormat={this.timestampFormat}
            >
              Total Playtime
            </TableHeaderColumn>

            <TableHeaderColumn 
              dataField="publisher_playtime" 
              dataSort
              dataFormat={this.timestampFormat}
            >
              Publisher Playtime
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="paired"
              // dataFormat={this.connectedFormat}
            >
              Pairing Status
            </TableHeaderColumn>
            {/* <TableHeaderColumn
              dataField="_id"
              dataFormat={this.delete_report_fromatter}
            >
              Delete
            </TableHeaderColumn> */}
          </BootstrapTable>

          : <div>Select the Duration</div> }

        </div>
        
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    creatives : state.content.get_all_files_data,
    contentCount : state.userReports.contentCount,
    logs : state.userReports.liveLogs,
    screenCreativeList : state.userReports.screenCreativeList

  }
}

export default connect(mapStateToProps, { 
  getAllFiles, fetchLiveStats, getScreenCreativeList, getImpressionCountForScreen
 })(LiveLogsReportTable);
