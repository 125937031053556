import {NEW_PLAYLIST_CREATED, GET_ALL_PLAYLISTS, MSG, GET_ALL_APPROVAL_PLAYLISTS} from '../actions/types';

const initState = { new_play_list_created: false, get_all_playlists_data_array: '', msg : '' };

export default (state = initState , action) => {

    // console.log("Action Type :", action.type);

    switch(action.type){
        case NEW_PLAYLIST_CREATED: {
            return Object.assign({}, state, {new_play_list_created: true, new_play_list_data: action.data})
        }
        case GET_ALL_PLAYLISTS: {
            return Object.assign({}, state, {get_all_playlists_data_array: action.payload})
        }
        case GET_ALL_APPROVAL_PLAYLISTS: {
            return Object.assign({}, state, {get_all_approval_playlists_data_array: action.payload})
        }
         case MSG: 
            return Object.assign({}, state, {
                msg: action.payload
        });

        default: {
            return state;
        }
    }
}