import React, { Component } from 'react';
import { connect } from "react-redux";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { getAllFiles } from "../../actions/contentActions";
import ContentApprovalModal from "./ContentApprovalModal";

class ContentApprove extends Component {

  changeStatus = (cell, row) => {
    return <ContentApprovalModal data={row} />
  }

  returnStatus = (cell, row) => {

    const { approval_status : status } = row;
    if (status === "Approved") {
        return <span className="text-success"><strong>{status}</strong></span>
    } else if (status === "Disapproved") {
        return <span className="text-danger"><strong>{status}</strong></span>
    } else {
        return <span className="text-warning"><strong>{status}</strong></span>
    }

}

  componentDidMount(){
    this.props.getAllFiles();
  }

  render() {

    // console.log("Content :", this.props.contents);
    if(this.props.contents.length > 0) {
      const { contents } = this.props;
      return (
        <div>

          <BootstrapTable
            keyField="_id"
            data={contents}
            pagination
            search
          >
            <TableHeaderColumn dataField="original_name" dataSort>
              Content Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="file_type" dataSort>
              Type
            </TableHeaderColumn>
            <TableHeaderColumn dataField="file_size" dataSort>
              Size (KB)
            </TableHeaderColumn>
            <TableHeaderColumn dataField="duration" dataSort>
              Duration
            </TableHeaderColumn>
            <TableHeaderColumn dataField="approval_status" dataFormat={this.returnStatus}  dataSort>
              Approval Status
            </TableHeaderColumn>
            <TableHeaderColumn dataField="duration" dataFormat={this.changeStatus} dataSort>
              Change Status
            </TableHeaderColumn>

          </BootstrapTable>

        </div>
      )
    } else {
      return <div className="container"><h2>No Creatives for Approval</h2></div>
    }
  }
}

const mapStateToProps = (state) => {

  return {
    contents : state.content.get_all_files_data
  }
}


export default connect(mapStateToProps, { getAllFiles })(ContentApprove);