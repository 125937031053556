import React, { Component } from 'react';
import AddFile from './AddFile';
import ShowFiles from './ShowFiles';

class ContentLibrary extends Component {

  render() {
    return (
      <div className="row animated fadeIn" >
        <div className="col-md-12 padding_zero">
          <AddFile />
        </div>
        <div className="col-md-12 padding_zero">
          <br />
          <ShowFiles />
        </div>
      </div>
    )
  }
}

export default ContentLibrary;