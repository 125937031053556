import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAllScreens, removeScreen } from '../../actions/screenActions';

class ShowModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalData: ''
        }
    }

    componentWillReceiveProps() {
        this.setState({ modal: this.props.showModal })
    }

    handleScreenRemove() {
        let { screen_name } = this.props.modalData;
        this.props.removeScreen(screen_name).then(() => {
            this.props.getAllScreens();
            this.close();
        });
    }

    show() {
        this.setState({ modal: true })
    }

    close() {
        this.setState({ modal: false });
    }

    render() {
        return (
            <div>
                <button className="auto_margin2 btn btn-danger" onClick={this.show.bind(this)}>
                    <i className="fa fa-trash"></i>
                </button>
                <Modal show={this.state.modal} onHide={this.close.bind(this)}>
                    <Modal.Body>
                        <h4>
                            <img alt="alert" src="img/warning-sign.png" /> &nbsp;
                             Are you sure that you want to delete this screen?</h4>

                        <div className="row col-md-4 col-md-offset-4">
                            <button className="btn btn-danger mr-30" onClick={this.handleScreenRemove.bind(this)}>
                                <i className="glyphicon glyphicon-ok"></i>
                            </button>
                            <button type="button" className="btn btn-warning" onClick={this.close.bind(this)}>
                                <i className="glyphicon glyphicon-remove"></i>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
export default connect(null, { getAllScreens, removeScreen })(ShowModal);