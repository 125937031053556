import axios from 'axios';
import extra, { rootURL } from '../utils/extra';
import { SET_ADVERTISER_CAMPAIGN, SET_MANAGE_SCREEN_DATA, CAMPAIGN_FOR_INVOICE } from './types';


export function setAdvertiserCampaigns(payload) {
    return {
        type: SET_ADVERTISER_CAMPAIGN,
        payload
    }
}

export function setManageScreenData(payload) {
    return {
        type: SET_MANAGE_SCREEN_DATA,
        payload
    }
}

export function createAdvertiserCampaign(campaignName, startDate, endDate, screens) {
    return dispatch => {
        return axios.post(extra.rootURL + "/api/createAdvertiserCampaign", { campaignName: campaignName, startDate: startDate, endDate: endDate, screens: screens }, { headers: { authorization: localStorage.getItem('token') } })
    }
}

export function fetchAdvertiserCampaign() {
    return dispatch => {
        return axios.post(extra.rootURL + "/api/fetchAdvertiserCampaign", null, { headers: { authorization: localStorage.getItem('token') } }).then((result, error) => {
            if (error) {
                console.log(error);
            } else {
                if (result.data.data) {
                    dispatch(setAdvertiserCampaigns(result.data.data))
                }
            }
        })
    }
}

export function manageScreenData(item) {
    return dispatch => {
        dispatch(setManageScreenData(item));
    }
}

export function updateAdvertiserCampaign(data) {
    return dispatch => {
        return axios.post(extra.rootURL + "/api/updateAdvertiserCampaign", { campaignName: data.campaignName, campaign_id: data._id, startDate: data.startDate, endDate: data.endDate, screens: data.screens }, { headers: { authorization: localStorage.getItem('token') } })
    }
}

export function removeAdvertiserCampaign(campaign_id) {
    return dispatch => {
        return axios.post(extra.rootURL + "/api/removeAdvertiserCampaign", { campaign_id: campaign_id }, { headers: { authorization: localStorage.getItem('token') } })
    }
}

export const fetchUserCampaigns = () => async dispatch => {

    const campaigns = await axios.get(`${rootURL}/api/fetchUserCampaigns`, {
        headers : {
            authorization: localStorage.getItem('token')
        }
    });

    // console.log("Campaigns fetch :", campaigns.data);

    dispatch({
        type : CAMPAIGN_FOR_INVOICE,
        payload : campaigns.data.campaigns
    });

    try {

    } catch(err){   
        console.log("Err", err);
    }
}

export const uploadCampaignInvoice = (formData, cb) => async dispatch => {

    try {

        await axios.post(`${rootURL}/api/uploadCampaignInvoice`, formData ,{
            headers : {
                'Content-Type': 'multipart/form-data',
                authorization: localStorage.getItem('token')
            }
        });

        cb();
    } catch(err){
        console.log(err);
    }

    // cb()
}