import axios from "axios";
import { rootURL } from "../utils/extra";

export function approvePlaylist(playlistId, msg) {
	return axios.post(
		rootURL + "/api/approveplaylist",
		{ playlist_id: playlistId, msg: msg },
		{ headers: { authorization: localStorage.getItem("token") } }
	);
}

export function disapprovePlaylist(playlistId, msg) {
	return axios.post(
		rootURL + "/api/disapproveplayList",
		{ playlist_id: playlistId, msg: msg },
		{ headers: { authorization: localStorage.getItem("token") } }
	);
}
