import React, { Component } from "react";
import { connect } from "react-redux";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { getAllFiles, removeFile } from "../../actions/contentActions";
import ShowContentModal from "./showContentModal";
import ShowWarningModal from "./DeleteContentModal";
import _ from "lodash";

class ShowFiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			row: "",
			showContentModal: false,
			data: "",
			showWarningModal: false,
			original_name: "",
			search_text: "",
			searched_data: "",
		};
	}

	componentWillMount() {
		this.props.getAllFiles();
	}

	onSearchValueChanged(event) {
		let search_text = event.target.value.toLowerCase();
		let searched_data = _.filter(
			this.props.get_all_files_data_array,
			(data) => {
				return (
					data.original_name.toLowerCase().indexOf(search_text) > -1 ||
					data.file_type.toLowerCase().indexOf(search_text) > -1 ||
					data.file_size.toLowerCase().indexOf(search_text) > -1 ||
					data.created_at.toLowerCase().indexOf(search_text) > -1
				);
			}
		);
		this.setState({ ...this.state, searched_data: searched_data });
	}

	previewFormatter = (cell, row) => {
		return <ShowContentModal modaldata={row} />;
	};

	fileSizeFormatter = (cell, row) => {
		return <div>{parseInt(parseInt(cell, 10) / 1024, 10).toString()} KB</div>;
	};

	removeFormatter = (cell, row) => {
		return <ShowWarningModal modalData={cell} />;
	};

	invoiceFormatter = (cell, row) => {
		if (row.invoice_url !== "") {
			return (
				<a className="btn btn-primary" href={row.invoice_url} alt="Invoice URL">
					View
				</a>
			);
		} else {
			return (
				<button className="btn btn-primary" disabled>
					View
				</button>
			);
		}
	};

	createdAtFormatter = (cell, row) => {
		return new Date(row.created_at).toDateString();
	};

	updatedAtFormatter = (cell, row) => {
		// console.log("row", row);

		return new Date(row.updated_at).toDateString();
	};

	render() {
		const { get_all_files_data_array } = this.props;

		if (get_all_files_data_array) {
			return (
				<div>
					<div className="row">
						<div className="col-md-12 table-width mb-20 mt-10">
							<BootstrapTable
								keyField="id"
								data={get_all_files_data_array}
								options={this.options}
								pagination
								search
							>
								<TableHeaderColumn
									dataField="_id"
									dataSort
									dataFormat={this.previewFormatter}
								>
									Preview
								</TableHeaderColumn>
								<TableHeaderColumn dataField="original_name" dataSort>
									File Name
								</TableHeaderColumn>
								<TableHeaderColumn dataField="file_type" dataSort>
									File Type
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="file_size"
									dataSort
									dataFormat={this.fileSizeFormatter}
								>
									File Size
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="created_at"
									dataSort
									dataFormat={this.createdAtFormatter}
								>
									Created At
								</TableHeaderColumn>
								<TableHeaderColumn dataField="approval_status" dataSort>
									Approval Status
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="invoice_url"
									dataSort
									dataFormat={this.invoiceFormatter}
								>
									Invoice
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="updated_at"
									dataSort
									dataFormat={this.updatedAtFormatter}
								>
									Last Updated
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="impressions"
									dataSort
								>
									Impression
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="original_name"
									dataSort
									dataFormat={this.removeFormatter}
								>
									Remove
								</TableHeaderColumn>
							</BootstrapTable>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<img
					className="Nloader"
					height="40"
					alt="preloader"
					src="img/screeno_preloader.gif"
				/>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		get_all_files_data_array: state.content.get_all_files_data,
	};
}

export default connect(mapStateToProps, { getAllFiles, removeFile })(ShowFiles);
