import {GET_ALL_SCREENSHOT} from '../actions/types';

export default (state = {
    get_all_screenshot:[],
}, action = {}) => {
  switch(action.type) {
    case GET_ALL_SCREENSHOT: 
            return Object.assign({}, state, {
              get_all_screenshot: action.payload
    });
    default: return state;
  }
}