import React, { Component } from 'react';
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { getAllFiles, approveContent, disapproveContent } from "../../actions/contentActions";

class ContentApprovalModal extends Component {

  state = {
    show : false,
    msg: ""
  }

  handleDisapproveContent = () => {
    // const { data } = this.props;

    const data = {
      ...this.state,
      ...this.props.data
    }

    // console.log(this.props);
    
    this.props.disapproveContent(data, ()=>{
      this.props.getAllFiles();
      this.handleClose();
    });
  }

  handleApproveContent = () => {
    // const { data } = this.props;

    const data = {
      ...this.state,
      ...this.props.data
    }

    this.props.approveContent(data, ()=>{
      this.props.getAllFiles();
      this.handleClose();
    });
  }

  handleOpen = () => {
    this.setState({
      ...this.state,
      show : true
    });
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      show : false
    });
  }

  statusButtons() {
    if (this.props.data.approval_status === "Pending") {
      return (
        <div className="row">
          <div className="col-md-4">
            <button onClick={this.handleApproveContent} className="btn btn-block btn-success">Approve</button>
          </div>
          <div className="col-md-4 offset-md-4">
            <button type="button" onClick={this.handleDisapproveContent} className="btn btn-block btn-danger">Disapprove</button>
          </div>
        </div>
      );
    } else if(this.props.data.approval_status === "Approved"){
      return (
        <div className="row">
          <div className="col-md-4">
            <button type="button" onClick={this.handleDisapproveContent} className="btn btn-block btn-danger">Disapprove</button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-md-4">
            <button onClick={this.handleApproveContent} className="btn btn-block btn-success">Approve</button>
          </div>
        </div>
      );
    }
  }

  render() {

    // console.log("Props :", this.props);

    return (
      <div>
        <button className="btn btn-primary" onClick={this.handleOpen}>
          Edit
        </button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header className="bg-success">
            <Modal.Title>Change Approval Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {/* Test */}
            {/* <form> */}
              <div className="form-group">
                <label htmlFor="message"></label>
                <textarea onChange={(e) => this.setState({ ...this.state, msg: e.target.value })} id="message" className="form-control" placeholder="Please Add some comments"></textarea>
              </div>
              {this.statusButtons()}
            {/* </form> */}
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default connect(null, {getAllFiles, approveContent, disapproveContent })(ContentApprovalModal);
