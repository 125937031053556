import React,{Component} from 'react';
import {getAllScreenshots} from '../../actions/screenControllerAction';
import Lightbox from "react-image-lightbox";
import {connect} from 'react-redux';

class ScreenshotGallery extends Component {
    constructor(props){
        super(props);
        this.state={
            index:0,
            modal: false,
            images:[]
        }
    }

    componentWillMount(){
       
    }

    show(){
        this.props.getAllScreenshots(this.props.screen_name, this.props.user_id, ()=>{

            this.setState({modal:true});
        });
    }

    closeLightbox() {
        this.setState({ modal: false });
    }

    moveNext(){
        const { screenshots } = this.props;
        const { index } = this.state;
        this.setState({ index: (index + 1) % screenshots.length });
        // Old Code
        // this.setState({ index: this.state.index + 1 });
    }

    movePrev(){
        const { screenshots } = this.props;
        const { index } = this.state;
        this.setState({ index: (index + screenshots.length - 1) % screenshots.length});
        // Old Code
        // this.setState({ index: this.state.index - 1});
    }

    render(){
        const { screenshots } = this.props;
        const { index } = this.state;
        return (
            <div>
            <button className="auto_margin2 btn btn-primary" onClick={this.show.bind(this)}>
                <i className="fa fa-sliders"></i>
            </button>
            {this.state.modal?
                <Lightbox
                    // old code 
                    // mainSrc={this.props.screenshot_url_array[this.state.index]?this.props.screenshot_url_array[this.state.index]["screenshot_url"]:null}
                    // nextSrc={this.props.screenshot_url_array[this.state.index+1]?this.props.screenshot_url_array[this.state.index+1]["screenshot_url"]:this.props.screenshot_url_array[this.state.index][0]}
                    // prevSrc={this.props.screenshot_url_array[this.state.index-1]?this.props.screenshot_url_array[this.state.index-1]["screenshot_url"]:this.props.screenshot_url_array[this.state.index][0]}
                    
                    // Bug Fix

                    mainSrc={screenshots.length > 0 ? screenshots[index] : ""}
                    nextSrc={screenshots.length > 0 ? screenshots[(index + 1) % screenshots.length] : ""}
                    prevSrc={screenshots.length > 0 ? screenshots[(index + screenshots.length - 1) % screenshots.length] : ""}
                    onCloseRequest={this.closeLightbox.bind(this)}
                    onMovePrevRequest={this.movePrev.bind(this)}
                    onMoveNextRequest={this.moveNext.bind(this)}
                />
                 :null}
        </div>
        )
    }
}

function mapStateToProps(state){
    return {
        user_id: state.auth.user.token.sub,
        // screenshot_url_array : state.screenController.get_all_screenshot
        screenshots : [...state.screenController.get_all_screenshot.map(s => s.screenshot_url)]
  }
}

export default connect(mapStateToProps,{getAllScreenshots})(ScreenshotGallery);