/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps';
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { markerClickedAction, getAllInventoryScreens } from '../../actions/inventoryActions';

let timeout;

const InventoryGoogleMap = withGoogleMap(props => (
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: 28, lng: 77 }}
        ref={props.onMapMounted}
    >

        <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            minimumClusterSize={20}
        >
            {
                props.markers ? props.markers.map((positionData, index) => (
                    <Marker
                        key={index}
                        position={positionData.latlng}
                        onClick={() => props.onMarkerClick(positionData)}
                        icon={"img/marker.png"}
                    />
                )) : null
            }
        </MarkerClusterer>
    </GoogleMap>
));

class InventoryMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            loading: true,
            marker_selected: '',
        }
    }

    componentWillMount() {
        this.props.getAllInventoryScreens();
        timeout = setTimeout(() => {
            this.setState({ loading: false });
        }, 3000);
    }

    handleMapMounted(bounds, map) {
        this._map = map;
        if (map && bounds) {
            map.fitBounds(bounds)
            map.panToBounds(bounds);
        }
    }

    handleMarkerClicked(positionData) {
        this.props.markerClickedAction(positionData);
    }


    componentWillUnmount() {
        clearTimeout(timeout)
    }


    render() {

        const { loading } = this.state;
        const { filtered_screens, all_inventory_screens } = this.props;

        if (loading) {
            return (
                <div className="inventory_map_height">
                    <img className="Mloader" height="40" alt="preloader" src="img/screeno_preloader.gif" />
                </div>
            )
        } else {
            let inventory_array = [];
            let bounds = new google.maps.LatLngBounds();

            if (filtered_screens.length > 0) {
                inventory_array = filtered_screens.map((inventory_item, index) => {

                    // Below 2 lines are from the previous code 
                    // let latlngJson = JSON.parse(inventory_item.latlng);
                    // let latlng = new google.maps.LatLng(parseFloat(latlngJson.lat), parseFloat(latlngJson.lng));

                    //This is the fix for the map rendering after filters are applied
                    let latlng = new google.maps.LatLng(parseFloat(inventory_item.latlng.lat()), parseFloat(inventory_item.latlng.lng()));
                    
                    inventory_item.isAdded = false;
                    inventory_item.latlng = latlng;
                    bounds.extend(latlng);
                    return inventory_item;
                })
            } else {
                inventory_array = all_inventory_screens.map((inventory_item, index) => {
                    let latlngJson = JSON.parse(inventory_item.latlng);
                    let latlng = new google.maps.LatLng(parseFloat(latlngJson.lat), parseFloat(latlngJson.lng));
                    inventory_item.isAdded = false;
                    inventory_item.latlng = latlng;
                    bounds.extend(latlng);
                    return inventory_item;
                })
            }

            return (
                <InventoryGoogleMap
                    containerElement={
                        <div className="inventory_map_height" />
                    }
                    mapElement={
                        <div className="inventory_map_height" />
                    }
                    onMapMounted={this.handleMapMounted.bind(this, bounds)}
                    markers={inventory_array}
                    onMarkerClick={this.handleMarkerClicked.bind(this)}
                />
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        filtered_screens: state.inventory.get_filtered_screens,
        all_inventory_screens: state.inventory.get_all_inventory_screens,
    }
}

const InventroyMapWithRouter = withRouter(InventoryMap);

export default connect(mapStateToProps, { markerClickedAction, getAllInventoryScreens })(InventroyMapWithRouter);