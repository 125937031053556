import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter } from "react-router";
import { getAllFiles } from "../../actions/contentActions";
import { getAllScreens } from "../../actions/screenActions";
import { getAllPlayLists } from "../../actions/playlistActions";
import { playlistSyncUpdate } from "../../actions/ContentOperationActions";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import { connect } from "react-redux";
import  { Tabs, Tab } from "react-bootstrap";
import AlertContainer from 'react-alert'


const SortableItem = SortableElement(({value}) => {
    return <li className="list-group-item">
        {value}
    </li>
});

const SortableList = SortableContainer(({items}) => {
    // console.log("items.length :", items.length );
    return <ul style={{ cursor : "move"}}>
        { items.map((item, index) => {
            return <SortableItem key={index}  index={index} value={item.original_name}/>
        }) }
    </ul>
});

class ContentOperations extends Component {

    state = {
        showContentSelection: true,
        screenForPlaylist:"",
        lockScreenForPlaylist : false,
        selectedContents: [],
        selectedScreens: [],
        key: 1
    };

    // Default options for the Alert bar
    alertOptions = {
        offset: 14,
        position: 'top center',
        theme: 'dark',
        time: 5000,
        transition: 'scale'
    }

    // Alert Bar handler to show data 
    showAlert = (opts) => {

        this.msg.show(opts.msg, {
          time: 2000,
          type: opts.type,
        //   icon: <img src="path/to/some/img/32x32.png" />
        })

    }

    // Select content from list and add it to the state 
    handleContentChange = selectedContents => {
        // console.log("selectedContents :", selectedContents)
        this.setState(
            { selectedContents }
            // ,() => console.log(`Option selected:`, this.state.selectedContents)
        );
    };

    // Select screens from list and add it to the state
    handleScreenChange = selectedScreens => {
        this.setState({selectedScreens}
            // ,() => console.log(`Selected Screens:`, this.state.selectedScreens)
        );
    }

    // Select screen to fetch its Playlist data and display
    handleScreenForPlaylistChange = screenForPlaylist => {
        this.setState(
            {screenForPlaylist},
            () => {

                let  screen_data  = this.props.playlist.find( p => {
                    return p.screen_name === this.state.screenForPlaylist.screen_name;
                });

                

                if(typeof screen_data !== "undefined"){
                    // console.log("Playlist_data ", playlist_data);
                    let { playlist_data } = screen_data;
                    playlist_data = playlist_data.map( (d, i) => {
                        d.label = d.original_name;
                        d.value = `${d.original_name}-${i}`;
                        return d;
                    })
    
                    // console.log("playlist_data", playlist_data);
    
                    const playlist = [...playlist_data];
    
                    this.setState(
                        { selectedContents : playlist }
                        ,() => {
                            this.setState({lockScreenForPlaylist : !this.state.lockScreenForPlaylist});
                        }
                    );

                } else {
                    // console.log("No Playlist Data");
                    this.showAlert({type : "error", msg: `No Playlist in Screen : ${this.state.screenForPlaylist.screen_name}`});
                    return;
                }

            }
            );
         
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({selectedContents}) => ({
            selectedContents: arrayMove(selectedContents, oldIndex, newIndex),
        }));
    };

    // Acts as submit and fires the action for playlist update
    onClickScreenWithData = () => {
        let { selectedScreens , selectedContents } = this.state;

        if(selectedContents.length === 0){
            this.showAlert({type : "error", msg: "No Content Selected"});
            return;
        }

        selectedScreens = selectedScreens.map( s => s.screen_name);
        selectedContents = selectedContents.map( c => {
            delete c.label;
            delete c.value;

            return c;
        });

        const data = {
            selectedScreens,
            selectedContents
        };

        this.props.playlistSyncUpdate(data, () => {
            this.props.router.push("/playlistsNschedule");
        })

    }

    // Handle Tab Selection 
    handleTabSelect = (key) => {
        this.setState({ key });
    }


    componentDidMount(){
        this.props.getAllFiles();
        this.props.getAllScreens();
        this.props.getAllPlayLists();
    }

    render() {
        const { selectedContents, selectedScreens } = this.state;
        const { content, screens } = this.props;
        // console.log("this.props.screens :", this.props.playlist);

        const contents = content.map( d => {
            // d.value = d.original_name;
            d.label = d.original_name;
            d.value = d.original_name;
            d.content = d.original_name;
            d.type = d.file_type;

            delete d._id;
            delete d.updated_at;
            delete d.created_at;
            delete d.user_id;
            delete d.file_size;
            delete d.file_name;

            return d;
        });

        // console.log("contents :", contents);
        // console.log("selectedContents :", selectedContents);

        const screen_data = screens.map( d => {
            // d.value = d.original_name;
            d.label = d.screen_name;
            d.value = d.screen_name;
            return d
        });
        // console.log(res);

        // console.log(content);

        return (
            <div>
                <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
                <h2>My Demo Page </h2>
                <div className="row">

                    <div className="col-md-6">
                        <div className="well">
                            
                            { this.state.selectedContents.length > 0 ? 
                                <SortableList items={this.state.selectedContents} onSortEnd={this.onSortEnd} />
                                : <div>Select content</div>}
                        </div>
                    </div>

                    <div className="col-md-6">

                    <Tabs activeKey={this.state.key} animation={false} id="noanim-tab-example" onSelect={this.handleTabSelect}>
                        <Tab eventKey={1} title="Update">
                                
                            {   this.state.showContentSelection
                            ?
                            <div>
                                <div className="row">
                                    <div className="col-md-4"> <h4>Select Creatives </h4></div>
                                    <div className="col-md-2"> <h4>Or</h4></div>
                                    <div className="col-md-6"> 
                                        <button
                                            className="btn btn-info"
                                            onClick={() => this.setState({key : 2})} 
                                        >
                                            Fetch from existing screen
                                        </button>
                                    </div>

                                </div>
                                <Select
                                    isMulti
                                    value={selectedContents}
                                    onChange={this.handleContentChange}
                                    options={contents}
                                /><br/>
                                <button 
                                    onClick={ ()=> this.setState({ showContentSelection : !this.state.showContentSelection})} 
                                    className="btn btn-primary btn-lg btn-block">
                                    Done Selection
                                </button>
                                {/* <button onClick={this.showAlert.bind(this, {type : "success", msg:"Yeah !"})}>Show Alert</button> */}
                            </div>
                            :
                            <div> 
                                {/* <button 
                                    onClick={ ()=> this.setState({ showContentSelection : !this.state.showContentSelection})} 
                                    className="btn btn-primary">
                                    Back
                                </button> */}

                                <div className="row">
                                    <div className="col-md-10"><h4>Select Screens</h4></div>
                                    <div className="col-md-2">
                                        <button 
                                            onClick={ ()=> this.setState({ showContentSelection : !this.state.showContentSelection})} 
                                            className="btn btn-primary">
                                            Back
                                        </button>
                                    </div>
                                </div>
                                
                                <Select
                                    isMulti
                                    value={selectedScreens}
                                    onChange={this.handleScreenChange}
                                    options={screen_data}
                                /> <br/>

                                <button 
                                    onClick={this.onClickScreenWithData}
                                    className="btn btn-primary btn-lg btn-block"
                                    // { }
                                    disabled={this.state.selectedScreens <= 0 ? true : false}
                                >
                                    Save Changes to screens
                                </button>
                                
                            </div>
                            }
                                
                        </Tab>

                        <Tab eventKey={2} title="Tab 2">
                            {   screen_data.length > 0
                            ?
                            <div>

                                <Select
                                    value={this.state.screenForPlaylist}
                                    onChange={this.handleScreenForPlaylistChange}
                                    options={screen_data}
                                /> <br/>
                                <button
                                    className="btn btn-primary btn-lg btn-block"
                                    onClick={() => this.setState({key : 1})}
                                >
                                    Done
                                </button>
                                
                            </div>
                            :
                            <div> 
                                {/* <button 
                                    onClick={ ()=> this.setState({ showContentSelection : !this.state.showContentSelection})} 
                                    className="btn btn-primary">
                                    Back
                                </button>
                                
                                <Select
                                    isMulti
                                    value={selectedScreens}
                                    onChange={this.handleScreenChange}
                                    options={screen_data}
                                /> */}
                                
                                
                            </div>
                            }
                        </Tab>

                        <Tab eventKey={3} title="Tab 3" disabled>
                            Tab 3 content
                        </Tab>
                    </Tabs>



                        {/* {   this.state.showContentSelection
                        ?
                        <div>
                            <Select
                                isMulti
                                value={selectedContents}
                                onChange={this.handleContentChange}
                                options={contents}
                            />
                            <button 
                                onClick={ ()=> this.setState({ showContentSelection : !this.state.showContentSelection})} 
                                className="btn btn-primary">
                                Done Selection
                            </button>
                        </div>
                        :
                        <div> 
                            <button 
                                onClick={ ()=> this.setState({ showContentSelection : !this.state.showContentSelection})} 
                                className="btn btn-primary">
                                Back
                            </button>
                            
                            <Select
                                isMulti
                                value={selectedScreens}
                                onChange={this.handleScreenChange}
                                options={screen_data}
                            />
                            
                        </div>
                        } */}
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log("My State :", state);
    return {
        content : state.content.get_all_files_data,
        screens: state.screen.get_all_screen_data,
        playlist : state.playlist.get_all_playlists_data_array
    }
}

const ContentOperationsWithRouter = withRouter(ContentOperations);

export default connect(mapStateToProps, {getAllFiles, getAllScreens, getAllPlayLists, playlistSyncUpdate})(ContentOperationsWithRouter);
