import {GET_ALL_CAB_SCREEN } from '../actions/types';

export default (state = {
    
    get_all_cab_screen: ''
}, action = {}) => {
  switch(action.type) {
    
    case GET_ALL_CAB_SCREEN :
        return Object.assign({}, state, {
            get_all_cab_screen: action.payload
        });
    

    default: return state;
  }
}