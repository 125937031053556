import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import extra from '../../utils/extra';

export default class ShowPlaylistModal extends Component{

    constructor(props){
        super(props);
        this.state={
            dataurl : '',
            modal: false
        }
    }


    

    componentWillReceiveProps(){
        this.setState({modal: this.props.showModal})
    }

    show(){
        this.setState({modal: true})
    }

    close(){
        this.setState({modal: false});
    }

    render(){
        return (
            <div>
                <Modal className="playlist_modal" show={this.state.modal} onHide={this.close.bind(this)} >
                    <Modal.Header className="bg-success">
                         <Modal.Title><b>Playlist Detail</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h4><b>Playlist: </b>{this.props.modaldata.title}
                            <span className="pull-right"><b>Screen: </b>{this.props.modaldata.screen_name}</span></h4>
                            <table className="table table-inverse table-bordered">
                                <thead>
                                <tr className="bg-primary">
                                    <th>Preview</th>
                                    <th>Content Name</th>
                                    <th>Content Duration(sec)</th>
                                    <th>Content Type</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.props.modaldata.playlist_data?this.props.modaldata.playlist_data.map((item,index)=>(
                                        <tr className="table-info fcolor-black" key={index}>
                                            <td>
                                                {item.type==="image/jpeg" ||item.type==="image/jpg"||item.type==="image/png"?
                                                <img className="custom-thumbnail" alt="preview" src={extra.fileUrl+item.content}
                                                />:<div></div>}
                                                {item.type==="video/mp4"?
                                                <video className="custom-thumbnail">
                                                <source src={extra.fileUrl+item.content }  /></video>
                                                :<div></div>}
                                            </td>
                                            <td>{item.original_name}</td>
                                            <td>{item.duration}</td>
                                            <td>{item.type}</td>
                                        </tr>
                                    )):<tr><td>No Data</td></tr>}
                                </tbody>
                            </table>
                        </div>
                        <button type="button" className="btn btn-lg center-align2 btn-warning mt-15" onClick={this.close.bind(this)}><i className="glyphicon glyphicon-remove"></i></button>
                  </Modal.Body>
                </Modal>
            </div>
        )
    }
}