import React, { Component } from 'react';
import { connect } from 'react-redux';
// import CreateCampaign from './CreateCampaign';
import CampaignTable from './CampaignTable';
// import CampaignStats from './CampaignStats';
import CampaignScreens from './CampaignScreens';

class Campaign extends Component {
    render() {
        return (
            <div className="row animated fadeIn">

                <div className={this.props.manage_screen_data ? "col-md-12" : "hidden"}>
                    {this.props.manage_screen_data ? <CampaignScreens manage_screen_data={this.props.manage_screen_data} /> : null}
                </div>
                <div className={this.props.manage_screen_data ? "hidden" : "col-md-12"}>
                    {/* <div className="col-md-12 mb-30">
                        <div className="col-md-6">
                            <CreateCampaign />
                        </div>
                    </div> */}
                    <div className="col-md-12">
                        <div className="col-md-12">
                            <CampaignTable />
                        </div>
                        {/* <div className="col-md-2">
                            <CampaignStats />
                        </div> */}
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(state => {
    return {
        manage_screen_data: state.campaign.manage_screen_data
    }
}, {})(Campaign);