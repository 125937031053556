import React from "react";
import { Route, IndexRoute } from "react-router";

import App from "./components/App";
import SignupPage from "./components/signup/SignupPage";
import Dashboard from "./components/Dashboard";
import Screen from "./components/Screen/Screen";
import Inventory from "./components/Inventory/Inventory";
import Campaign from "./components/Campaign/Campaign";
// import AppStore from './components/AppStore/AppStore';
import ContentLibrary from "./components/ContentLibrary/ContentLibrary";
import Playlists from "./components/PlayLists/Playlists";
import ScreenController from "./components/screenController/screenController";
// import AppController from './components/appController/appController';
import Base from "./components/Base";
import Welcome from "./components/Welcome";
import PlaylistApprove from "./components/approvePlaylists/playlistApprove";
import Login from "./components/login/LoginPage";
import Reports from "./components/Reports/Reports_new";
import Register from "./components/signup/Register";
import Admin from "./components/Admin/Admin";
import ContentOperations from "./components/PlaylistContentOperations/ContentOperations";
import ContentApprove from "./components/ContentLibrary/ContentApprove";
// import RazerPay from "./components/Payments/RazorPay";
import CampaignInvoice from "./components/Admin/InvoiceUpload";
import DemoTest from "./components/Admin/DemoTest";

import requireAuth from "./utils/requireAuth";

export default (
	<Route path="/" name="Home" component={App}>
		<IndexRoute name="Welcome" component={Welcome} />
		<Route path="/login" name="Login" component={Login} />
		<Route path="/signup" name="Signup" component={SignupPage} />
		<Route path="/register" name="register" component={Register} />

		<Route
			path="/inventoryMap"
			name="public inventory map"
			component={Inventory}
		/>

		<Route name="User" component={requireAuth(Base)}>
			<Route
				path="/dashboard"
				name="Dashboard"
				component={requireAuth(Dashboard)}
			/>
			<Route path="/reports" name="Reports" component={requireAuth(Reports)} />
			<Route path="/screen" name="Screen" component={requireAuth(Screen)} />
			<Route
				path="/inventory"
				name="Inventory"
				component={requireAuth(Inventory)}
			/>
			<Route
				path="/campaigns"
				name="Campaign"
				component={requireAuth(Campaign)}
			/>
			{/* <Route path="/appstore" name="AppStore" component={requireAuth(AppStore)} /> */}
			<Route
				path="/contentlibrary"
				name="ContentLibrary"
				component={requireAuth(ContentLibrary)}
			/>
			<Route
				path="/contentApprove"
				name="ContentApprove"
				component={requireAuth(ContentApprove)}
			/>
			<Route
				path="/contentOps"
				name="ContentOperations"
				component={requireAuth(ContentOperations)}
			/>
			<Route
				path="/approve"
				name="Playlist Approve"
				component={requireAuth(PlaylistApprove)}
			/>
			<Route
				path="/playlistsNschedule"
				name="Playlists And Schedule"
				component={requireAuth(Playlists)}
			/>
			<Route
				path="/screenController"
				name="Screen Controller"
				component={requireAuth(ScreenController)}
			/>

			<Route
				path="/invoiceUpload"
				name="Campaign Invoice Upload"
				component={requireAuth(CampaignInvoice)}
			/>

			<Route path="/admin" name="admin" component={requireAuth(Admin)} />
		</Route>
	</Route>
);
