import React, { Component } from "react";
import { connect } from "react-redux";
import {
	dashboardscreencount,
	dashboardplaylistcount,
	dashboardinvantorycount,
	dashboardcontentcount,
	dashboardactivescreencount,
	dashboardLocationsCount,
	dashboardStats
	
} from ".././actions/dashboardcountActions";

var array = [
	"card-info",
	"card-primary",
	"card-success",
	"card-warning",
	"card-danger"
];

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			get_all_screen_count_data_array: "",
			get_all_playlist_count_data_array: "",
			get_all_invantory_count_data_array: "",
			get_all_content_count_data_array: "",
			get_all_active_screen_count_data_array: "",
			dashboardStats : {},
			state_one: "",
			state_two: "",
			state_three: "",
			state_four: "",
			state_five: "",
			state_six: ""
		};
	}

	componentWillMount() {
		this.props.dashboardscreencount();
		this.props.dashboardplaylistcount();
		this.props.dashboardinvantorycount();
		this.props.dashboardcontentcount();
		this.props.dashboardactivescreencount();
		this.props.dashboardLocationsCount();
		this.props.dashboardStats();

		this.setState({
			state_one:
				"custom-card " + array[Math.floor(Math.random() * array.length)]
		});
		this.setState({
			state_two:
				"custom-card " + array[Math.floor(Math.random() * array.length)]
		});
		this.setState({
			state_three:
				"custom-card " + array[Math.floor(Math.random() * array.length)]
		});
		this.setState({
			state_four:
				"custom-card " + array[Math.floor(Math.random() * array.length)]
		});
		this.setState({
			state_five:
				"custom-card " + array[Math.floor(Math.random() * array.length)]
		});
		this.setState({
			state_six:
				"custom-card " + array[Math.floor(Math.random() * array.length)]
		});
	}

	render() {
		const { company_name } = this.props;
	
		return (
			<div className="animated fadeIn dashboard">
				<div className="row">
					<div className="col-md-6">
						<div className={this.state.state_one}>
							<div className="card-block">
								<div className="col-xs-8">
									<div className="h2 m-0">
										<i className="fa fa-desktop" /> Media Players:{" "}
									</div>
								</div>

								<div className="col-xs-4 text-center">
									<h2 className="mt-0 mb-0">
										{this.props.get_all_screen_count_data_array ? (
											this.props.get_all_screen_count_data_array.count
										) : (
											<div>
												{" "}
												<img
													alt="preloader"
													height="30"
													src="img/preloader.gif"
												/>{" "}
											</div>
										)}
									</h2>
								</div>
							</div>
						</div>
					</div>

					{this.props.role === "Media Owner" ? null : (
						<div className="col-md-6">
							<div className={this.state.state_two}>
								<div className="card-block">
									<div className="col-xs-8">
										<div className="h2 m-0">
											{/* <i className="fa fa-th-large" /> Inventory:{" "} */}
											<i className="fa fa-th-large" /> Locations:{" "}
										</div>
									</div>

									<div className="col-xs-4 text-center">
										<h2 className="mt-0 mb-0">
											{/* {this.props.get_all_invantory_count_data_array ? (
												this.props.get_all_invantory_count_data_array.count
											) : (
												<div>
													{" "}
													<img
														alt="preloader"
														height="30"
														src="img/preloader.gif"
													/>{" "}
												</div>
											)} */}
											{this.props.dashboardStatsData.totalLocations > 0 ?  this.props.dashboardStatsData.totalLocations :
												(
													<div>
														{" "}
														<img
															alt="preloader"
															height="30"
															src="img/preloader.gif"
														/>{" "}
													</div>
												)
											}
										</h2>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="col-md-6">
						<div className={this.state.state_three}>
							<div className="card-block">
								<div className="col-xs-8">
									<div className="h2 m-0">
										<i className="fa fa-list" /> Playlist:{" "}
									</div>
								</div>

								<div className="col-xs-4 text-center">
									<h2 className="mt-0 mb-0">
										{this.props.get_all_playlist_count_data_array ? (
											this.props.get_all_playlist_count_data_array.count
										) : (
											<div>
												{" "}
												<img
													alt="preloader"
													height="30"
													src="img/preloader.gif"
												/>{" "}
											</div>
										)}
									</h2>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6">
						<div className={this.state.state_four}>
							<div className="card-block">
								<div className="col-xs-8">
									<div className="h2 m-0">
										<i className="fa fa-file-o" /> Content:{" "}
									</div>
								</div>

								<div className="col-xs-4 text-center">
									<h2 className="mt-0 mb-0">
										{this.props.get_all_content_count_data_array ? (
											this.props.get_all_content_count_data_array.count
										) : (
											<div>
												{" "}
												<img
													alt="preloader"
													height="30"
													src="img/preloader.gif"
												/>{" "}
											</div>
										)}
									</h2>
								</div>
							</div>
						</div>
					</div>

					{this.props.role === "Media Owner" ? 

					<div className="col-md-6">
						<div className={this.state.state_five}>
							<div className="card-block">
								<div className="col-xs-8">
									<div className="h2 m-0">
										<i className="fa fa-caret-square-o-right" /> Active Screens:{" "}
									</div>
								</div>

								<div className="col-xs-4 text-center">
									<h2 className="mt-0 mb-0">
										{this.props.get_all_active_screen_count_data_array ? (
											this.props.get_all_active_screen_count_data_array.count
										) : (
											<div>
												{" "}
												<img
													alt="preloader"
													height="30"
													src="img/preloader.gif"
												/>{" "}
											</div>
										)}
									</h2>
								</div>
							</div>
						</div>
					</div>

					:

					<div className="col-md-6">
						<div className={this.state.state_five}>
							<div className="card-block">
								<div className="col-xs-8">
									<div className="h2 m-0">
										<i className="fa fa-caret-square-o-right" /> Screens:{" "}
									</div>
								</div>

								<div className="col-xs-4 text-center">
									<h2 className="mt-0 mb-0">
										{this.props.dashboardStatsData.totalScreens > 0 ? (
											this.props.dashboardStatsData.totalScreens
										) : (
											<div>
												{" "}
												<img
													alt="preloader"
													height="30"
													src="img/preloader.gif"
												/>{" "}
											</div>
										)}
									</h2>
								</div>
							</div>
						</div>
					</div>


										}

					{company_name === "Nu Media" && (
						<div className="col-md-6">
							<div className={this.state.state_five}>
								<div className="card-block">
									<div className="col-xs-8">
										<div className="h2 m-0">
											<i className="fa fa-map" /> Locations :{" "}
										</div>
									</div>

									<div className="col-xs-4 text-center">
										<h2 className="mt-0 mb-0">
											{ this.props.get_all_locations_count ?

												this.props.get_all_locations_count
												:
												<div>
													{" "}
													<img
														alt="preloader"
														height="30"
														src="img/preloader.gif"
													/>{" "}
												</div>

											}
										</h2>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	// console.log("state", state);
	const { role, company_name } = state.auth.user;
	return {
		role,
		company_name,
		get_all_screen_count_data_array:
			state.dashboardcount.get_all_screen_count_data,
		get_all_playlist_count_data_array:
			state.dashboardcount.get_all_playlist_count_data,
		get_all_invantory_count_data_array:
			state.dashboardcount.get_all_invantory_count_data,
		get_all_content_count_data_array:
			state.dashboardcount.get_all_content_count_data,
		get_all_active_screen_count_data_array:
			state.dashboardcount.get_all_active_screen_count_data,
		get_all_locations_count :
			state.dashboardcount.get_all_locations_count,
		dashboardStatsData : state.dashboardcount.dashboard_stats

	};
}

export default connect(
	mapStateToProps,
	{
		dashboardscreencount,
		dashboardplaylistcount,
		dashboardinvantorycount,
		dashboardcontentcount,
		dashboardactivescreencount,
		dashboardLocationsCount,
		dashboardStats
	}
)(Dashboard);
