import axios from "axios";
import extra, { rootURLAppLogs } from "../utils/extra";
import { GET_ALL_FILES, NEW_FILE_ADDED, MSG, PROGRESS_PERCENT } from "./types";

export function generate_msg(payload) {
	return {
		type: MSG,
		payload,
	};
}

export function new_file_added(payload) {
	return {
		type: NEW_FILE_ADDED,
		payload,
	};
}

export function get_all_files(payload) {
	return {
		type: GET_ALL_FILES,
		payload,
	};
}

export function generate_progress_percent(payload) {
	return {
		type: PROGRESS_PERCENT,
		payload,
	};
}

export function uploadFile(file, resolution, duration, file_size, user_id) {
	return (dispatch) => {
		let formData = new FormData();
		formData.append("resolution", resolution);
		formData.append("file_feild", file);

		let { name, type } = file;

		name = name.replace(" ", "_");

		return axios
			.post(
				extra.rootURL + "/api/generateSignedUrlForUpload",
				{ file_name: name, file_type: type },
				{
					headers: { authorization: localStorage.getItem("token") },
				}
			)
			.then((res) => {
				if (res.data.error) {
					dispatch(
						generate_msg({ msg_data: res.data.error, msg_type: "error" })
					);
				} else {
					dispatch(
						generate_msg({
							msg_data: "Starting File Upload",
							msg_type: "success",
						})
					);

					const xhr = new XMLHttpRequest();
					xhr.open("PUT", res.data.data);

					xhr.upload.onprogress = (e) => {
						const progress = Math.round((e.loaded / e.total) * 100);
						dispatch(generate_msg({ msg_data: "", msg_type: "" }));
						dispatch(generate_progress_percent(progress));
					};

					xhr.onreadystatechange = async () => {
						if (xhr.readyState === 4) {
							if (xhr.status === 200) {
								dispatch(
									generate_msg({
										msg_data: "File Uploaded, Saving Data",
										msg_type: "success",
									})
								);

								axios
									.post(
										extra.rootURL + "/api/saveFile",
										{
											url: res.data.url,
											file_name: name,
											file_type: type,
											duration: duration,
											file_size: file_size,
										},
										{
											headers: { authorization: localStorage.getItem("token") },
										}
									)
									.then((res) => {
										if (res.data.error) {
											console.log("File Uploading Failed");
											console.log(res.data.error);
											// dispatch(generate_msg({ msg_data: "File uploading failed ", msg_type: "error" }));
											dispatch(
												generate_msg({
													msg_data: res.data.error,
													msg_type: "error",
												})
											);
										} else {
											console.log("Upload Success Content");
											dispatch(
												generate_msg({
													msg_data: "Success",
													msg_type: "success",
												})
											);
											localStorage.removeItem("file_content");
										}
									})
									.catch((error) => {
										console.log(error);
										dispatch(
											generate_msg({
												msg_data: "File uploading failed ",
												msg_type: "error",
											})
										);
									});
							}
						}
					};
					xhr.send(file);
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(
					generate_msg({
						msg_data: "File uploading failed ",
						msg_type: "error",
					})
				);
			});
	};
}

export function getAllFiles() {
	return (dispatch) => {
		axios
			.get(extra.rootURL + "/api/getAllFiles", {
				headers: { authorization: localStorage.getItem("token") },
			})
			.then((response) => {
				// console.log(response.data);

				let content_array = response.data;

				dispatch(get_all_files(content_array));

				axios
					.get(rootURLAppLogs + "/screeno_api/getImpressionCountsForCreative", {
						headers: { authorization: localStorage.getItem("token") },
					})
					.then((impression_response) => {
						// console.log(impression_response.data);
						let impression_array = impression_response.data;

						content_array = content_array.map((item) => {
							let obj = { ...item, impressions: 0 };
							impression_array.map((i_item) => {
								if (item.file_name === i_item.key) {
									// console.log(i_item);
									obj.impressions = i_item.doc_count;
								}
							});
							return obj;
						});

						dispatch(get_all_files(content_array));
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	};
}

export function removeFile(name) {
	return (dispatch) => {
		return axios
			.post(
				extra.rootURL + "/api/removeFile",
				{ name: name },
				{ headers: { authorization: localStorage.getItem("token") } }
			)
			.then((response) => {});
	};
}

export const approveContent = (data, cb) => async (dispatch) => {
	try {
		await axios.post(
			`${extra.rootURL}/api/approveContent`,
			{ data },
			{
				headers: {
					authorization: localStorage.getItem("token"),
				},
			}
		);
		// console.log(response.data.result);
		cb();
	} catch (err) {
		console.log(err);
	}
};

export const disapproveContent = (data, cb) => async (dispatch) => {
	try {
		await axios.post(
			`${extra.rootURL}/api/disapproveContent`,
			{ data },
			{
				headers: {
					authorization: localStorage.getItem("token"),
				},
			}
		);
		// console.log(response.data.result);
		cb();
	} catch (err) {
		console.log(err);
	}
};
