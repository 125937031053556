import React, {Component} from 'react';
import SignupForm from './SignUpForm';
import { connect } from 'react-redux';
import { userSignupRequest, isUserExists } from '../../actions/signupActions';

class SignupPage extends Component {
  
  render() {
    const { userSignupRequest, isUserExists } = this.props;
    return (
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <SignupForm
            isUserExists={isUserExists}
            userSignupRequest={userSignupRequest}
            history={this.props.history} />
        </div>
      </div>
    );
  }
}

export default connect(null, { userSignupRequest, isUserExists })(SignupPage);
