import axios from 'axios';
import extra from '../utils/extra';
import { NEW_PLAYLIST_CREATED, GET_ALL_PLAYLISTS, GET_ALL_APPROVAL_PLAYLISTS, MSG } from './types';

export function generate_msg(payload) {
    return {
        type: MSG,
        payload
    }
}

export function new_play_list_created(payload) {
    return {
        type: NEW_PLAYLIST_CREATED,
        payload
    }
}

export function get_all_playlists(payload) {
    return {
        type: GET_ALL_PLAYLISTS,
        payload
    }
}

export function get_all_approval_playlists(payload) {
    return {
        type: GET_ALL_APPROVAL_PLAYLISTS,
        payload
    }
}

export function createPlayLists(playlist_name, playlist_data, to_be_played_from, to_be_played_to, screen_name, screen_id) {
    return (dispatch => {
        return axios.post(extra.rootURL + '/api/createPlayList', {
            playlist_name: playlist_name, playlist_data: playlist_data,
            to_be_played_from: to_be_played_from, to_be_played_to: to_be_played_to, screen_name: screen_name, screen_id: screen_id
        },
            { headers: { authorization: localStorage.getItem('token') } }).then((data) => {

                if (data.data.msg === "PlayList Created Sucessfully") {
                    dispatch(new_play_list_created(data));
                    console.log("Success Playlist");
                } else {
                    console.log("Failed Playlist");
                    dispatch(generate_msg({ msg_data: data.data.msg, msg_type: "error" }));
                }
            }).catch((error) => {
                console.log(error);
            });
    })
}

export const createPlayList = (playlist_name, playlist_data, to_be_played_from, to_be_played_to, screen_name, screen_id, cb) => async dispatch => {

    try {
        const playlist = await axios.post(`${extra.rootURL}/api/createPlaylist`, {
            playlist_name,
            playlist_data,
            to_be_played_from,
            to_be_played_to,
            screen_name,
            screen_id
        }, {
            headers : { authorization : localStorage.getItem('token') }
        });

        // console.log("Playlist Data :", playlist.data);
        dispatch({
            type : MSG,
            payload : { data : playlist.data.msg,  type:"success"}
        });

        cb();

    } catch (err) {

        // console.log("Error", err);
        dispatch({
            type : MSG,
            payload : { data : err.response.data.error, type : "error" }
        });

    }

}

export const clearPlaylistMessage = () => dispatch => {
    dispatch({
        type : MSG,
        payload : ""
    });
}

export function getAllPlayLists() {
    return (dispatch => {
        return axios.get(extra.rootURL + '/api/getAllPlayLists', { headers: { authorization: localStorage.getItem('token') } }).then((data) => {
            // console.log("Data :", data.data);
            dispatch(get_all_playlists(data.data));
        })
    })
}

export function getApprovalPlaylists() {
    return (dispatch => {
        return axios.post(extra.rootURL + '/api/getApprovalPlaylists', null,
            { headers: { authorization: localStorage.getItem('token') } }).then((data) => {
                dispatch(get_all_approval_playlists(data.data));
            })
    })
}

export function removePlayList(name) {
    return (dispatch) => {
        return axios.post(extra.rootURL + '/api/removePlayList', { name: name }, { headers: { authorization: localStorage.getItem('token') } }).then((res) => {
        });
    }
}

export function updatePlayList(_id, playlist_name, to_be_played_from, to_be_played_to, playlist_data) {
    return (dispatch) => {
        return axios.post(extra.rootURL + '/api/updatePlaylist', { _id: _id, playlist_name: playlist_name, to_be_played_from: to_be_played_from, to_be_played_to: to_be_played_to, playlist_data: playlist_data }, { headers: { authorization: localStorage.getItem('token') } });
    }
}

export function getPlaylistsForScreen(screen_id) {
    return (dispatch) => {
        return axios.post(extra.rootURL + '/api/getPlaylistsForScreen', { screen_id: screen_id }, { headers: { authorization: localStorage.getItem('token') } });
    }
}