import { GET_CLIENTS , ADD_CLIENT, EDIT_CLIENT, DELETE_CLIENT} from "../actions/types";

const initialState = {
  clients : []
}

export default (state = initialState, action) => {

  switch(action.type){
    case GET_CLIENTS:{
      return {
        ...state,
        clients : action.payload
      }
    }

    case ADD_CLIENT: {
      return {
        ...state,
        clients: [...state.clients, action.payload]
      }
    }

    case EDIT_CLIENT: {
      return {
        ...state,
        clients : state.clients.map(client => {
          return client._id === action.payload._id ? action.payload : client
        })
      }
    }

    case DELETE_CLIENT: {
      return {
        ...state,
        clients : state.clients.filter(client => client._id !== action.payload._id)
      }
    }

    default:
      return state;
  }

}
