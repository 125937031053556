import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllScreens, removeScreenFromInventory } from '../../actions/screenActions';
import { Modal } from 'react-bootstrap';

class RemoveScreenFromInventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
    }

    show() {
        this.setState({ modal: true })
    }

    close() {
        this.setState({ modal: false });
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.removeScreenFromInventory(this.props.screen._id).then((data)=>{
            if(data.data){
                if(data.data.msg){
                    this.props.getAllScreens();
                    this.props.alert.show(data.data.msg, { time: 2000, type: 'success' })
                } else if(data.data.error){
                    this.props.alert.show(data.data.error, { time: 2000, type: 'error' })
                } else {
                    this.props.alert.show("Unknown Error", { time: 2000, type: 'error' })
                }
            }
            this.close();
        }).catch((error)=>{
            console.log(error)
        });
    }

    render() {
        return (
            <div>
                <button className="auto_margin2 btn btn-danger" onClick={this.show.bind(this)}>
                    <i className="fa fa-close"></i>
                </button>
                <Modal show={this.state.modal} onHide={this.close.bind(this)}>
                    <Modal.Body>
                        <h3>Are you sure to remove from inventory ?</h3>
                        <div className="row">
                            <div className="col-md-2">
                                <button className="btn btn-success" onClick={this.close.bind(this)}>Cancel</button>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-danger" onClick={this.onSubmit.bind(this)}>Remove</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}


export default connect(null, { getAllScreens, removeScreenFromInventory })(RemoveScreenFromInventory);