import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import * as actions from "../../actions/AdminActions";


class Admin extends Component {

  state = {
    showAdd: false,
    showEdit: false,
    userData: {
      name: "",
      company: "",
      email: "",
      password: ""
    },
    errors: {}
  }

  closeAddUser = () => {
    this.setState({ showAdd: false });
  }

  showAddUser = () => {
    this.setState({ showAdd: true });
  }

  closeEditUser = () => {
    this.setState({ showEdit: false });
  }

  showEditUser = () => {
    this.setState({ showEdit: true });
  }

  onChange = (e) => {

    const userData = { ...this.state.userData };

    userData[e.target.name] = e.target.value;

    this.setState({ userData });
  }

  onClickEdit = (user) => {
    const { name, company_name, role, email } = user;


    this.setState({
      userData: {
        name,
        company: company_name,
        email,
        password: "",
        role
      }
    })
    this.showEditUser();
    // console.log(user);

    // this.setState({ userData : {
    //   name : "",
    //   company: "",
    //   email: "",
    //   password: ""
    // }})
  }

  handleClientEdit = (cell, row) => {
    // console.log(row);
    return <button
      className="btn btn-success"
      onClick={this.onClickEdit.bind(this, row)}
    >
      <i className="fa fa-user-edit"></i>Edit
    </button>
  }


  addOnSumbit = (e) => {
    e.preventDefault();
    const { name, company, email, password } = this.state.userData;

    const user = {
      id: 1337,
      name,
      company_name: company,
      email,
      password
    }

    this.props.addClient(user, () => {
      this.closeAddUser();
    });

  }

  editOnSumbit = (e) => {
    e.preventDefault();

    const { name, company, email, password } = this.state.userData;

    const user = {
      id: 1337,
      name,
      company_name: company,
      email,
      password
    }

    this.props.editClient(user, () => {
      this.closeEditUser()
    })
    // console.log(this.state.userData);

  }

  componentDidMount() {
    this.props.fetchClients();
  }
  render() {
    const { errors } = this.state;
    // console.log(this.props.clients);
    const { clients } = this.props;
    return (
      <div>

        <button className="btn btn-primary" onClick={this.showAddUser}>
          <i className="fa fa-user-plus"></i> Add
        </button>

        {/* Add client modal */}
        <Modal show={this.state.showAdd} onHide={this.closeAddUser}>
          <Modal.Header>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <form onSubmit={this.addOnSumbit}>

              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  onChange={this.onChange}
                />
                {errors.name && <div className="alert alert-danger">{errors.name}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="company">Company Name</label>
                <input
                  type="text"
                  name="company"
                  id="company"
                  className="form-control"
                  onChange={this.onChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  onChange={this.onChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  onChange={this.onChange}
                />
              </div>

              <input type="submit" className="btn btn-primary" value="Add" />

            </form>


          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-default" onClick={this.closeAddUser}>
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal for the Clients  */}
        <Modal show={this.state.showEdit} onHide={this.closeAddUser}>
          <Modal.Header>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <form onSubmit={this.editOnSumbit}>

              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  onChange={this.onChange}
                  value={this.state.userData.name}
                />
                {errors.name && <div className="alert alert-danger">{errors.name}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="company">Company Name</label>
                <input
                  type="text"
                  name="company"
                  id="company"
                  className="form-control"
                  onChange={this.onChange}
                  value={this.state.userData.company}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  onChange={this.onChange}
                  value={this.state.userData.email}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  onChange={this.onChange}
                  value={this.state.userData.password}
                />
              </div>

              <input type="submit" className="btn btn-primary" value="Add" />

            </form>


          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-default" onClick={this.closeEditUser}>
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {clients.length > 0 ?
          <BootstrapTable data={clients} bordered={false}>
            {/* <TableHeaderColumn dataField='_id' isKey={ true }>Id</TableHeaderColumn> */}
            <TableHeaderColumn dataField="name"> Name</TableHeaderColumn>
            <TableHeaderColumn dataField='email' isKey={true}>Email</TableHeaderColumn>
            <TableHeaderColumn dataField='company_name'>Company</TableHeaderColumn>
            <TableHeaderColumn dataField='role'>Role</TableHeaderColumn>
            <TableHeaderColumn dataField='_id' dataFormat={this.handleClientEdit}>Role</TableHeaderColumn>
          </BootstrapTable>
          : <h3>No Clients</h3>}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    auth: state.auth,
    clients: state.clients.clients
  }
}

export default connect(mapStateToProps, actions)(Admin);
