import React, { Component } from 'react';
import { getApprovalPlaylists } from '../../actions/playlistActions';
import { connect } from 'react-redux';
import ShowPlaylistContent from "../PlayLists/previewModal";
import ChangeAppovalStatus from "./ChangeApprovalStatus";


class PlaylistApprove extends Component {

    componentDidMount() {
        this.props.getApprovalPlaylists();
    }

    toDate(date_string) {
        let date = new Date(date_string).toLocaleString();
        return (
            <div>{date}</div>
        )
    }

    render() {
        const returnStatus = (status) => {
            if (status === "Approved") {
                return <span className="text-success"><strong>{status}</strong></span>
            } else if (status === "Disapproved") {
                return <span className="text-danger"><strong>{status}</strong></span>
            } else {
                return <span className="text-warning"><strong>{status}</strong></span>
            }

        }

        // console.log("Approve Playlist", this.props.approvalplaylists);

        return (
            <div>
                <div className="row">

                    <table className="table mrgn table-hover table-bordered">
                        <thead>
                            <tr className="bg-success">
                                <th>Screen Name</th>
                                <th>Playlist Name</th>
                                <th>Preview</th>
                                <th>To be played from</th>
                                <th>To be played to</th>
                                <th>Approval Status</th>
                                <th>Change Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.approvalplaylists ? this.props.approvalplaylists.map((data, index) => {
                                // console.log("Data P :", data)
                                return (
                                    <tr className="table-info fcolor-black" key={index}>
                                        <td>{data.screen_name}</td>
                                        <td> {data.playlist_name}</td>
                                        <td><ShowPlaylistContent previewData={data.playlist_data} key={data._id} screen={data.screen_name} pCode={data.screen_pairing_code}/></td>
                                        <td>{this.toDate(data.to_be_played_from)}</td>
                                        <td>{this.toDate(data.to_be_played_to)}</td>
                                        <td>
                                            {returnStatus(data.is_approved)}
                                        </td>
                                        <td><ChangeAppovalStatus  userId={this.props.user_id} playlist={data} /></td>
                                    </tr>
                                )
                            }) : <tr><td>No Playlist</td></tr>}
                        </tbody>
                    </table>

                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return { 
        approvalplaylists: state.playlist.get_all_approval_playlists_data_array, 
        // user_id: state.auth.user.token.sub, 
    }
}

export default connect(mapStateToProps, { getApprovalPlaylists })(PlaylistApprove)