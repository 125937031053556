import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Tab, Tabs, Row, Col, Button } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Select from "react-select";
import { DateRange } from "react-date-range";
import { getAllScreens } from "../../actions/screenActions";
import { getAllFiles } from "../../actions/contentActions";
import { getReports, generateReportV2, handleReportTabChange, deleteReport } from "../../actions/impressionsReportAction";
import LiveLogStat from "./LiveLogsReportTable";

class Reports extends Component {
	state = {
		stepOneOptions: [
			{ value: "screen", label: "Screen" },
			{ value: "content", label: "Content" }
		],
		stepTwoOptions: [],
		report_type: "",
		parsedStartDate: "",
		parsedEndDate: "",
		selectedStepTwoOptions: []
	};

	componentDidMount() {
		const { getReports, getAllFiles, getAllScreens } = this.props;
		getReports();
		getAllFiles();
		getAllScreens();
	}

	options = {
		defaultSortName: "updated_at", // default sort column name
		defaultSortOrder: "desc", // default sort order
		paginationPosition: "top" // default is bottom, top and both is all available
	};

	updated_at_fromatter = (cell, row) => {
		return new Date(cell).toLocaleString();
	};

	download_formatter = (cell, row) => {
		return (
			<Link
				to={`${cell}`}
				target="_blank"
				className={`btn btn-success ${row.report_status === "Report Generated" ? "" : "disabled"}`}
			>
				<i className="fa fa-download" />
			</Link>
		);
	};

	handleReportDelete = (id) => {
		const { deleteReport } = this.props;
		deleteReport(id);
	}

	delete_report_fromatter = (cell, row) => {
		return (
			<Button className="btn btn-danger" onClick={this.handleReportDelete.bind(this, cell)}><i className="fa fa-trash" /></Button>
		)
	}

	selectRowProp = {
		mode: "checkbox"
	};

	handleReportTypeChangeListener = selectedOption => {
		// console.log("Selected Type :", selectedOption);
		const { value } = selectedOption;
		const { contents, screens } = this.props;
		let options = [];
		if (value === "screen") {
			options = screens.map(item => {
				const { screen_name } = item;
				return { label: screen_name, value: screen_name };
			});
		} else {
			options = contents.map(item => {
				const { original_name } = item;
				return { label: original_name, value: original_name };
			});
		}

		this.setState({
			stepTwoOptions: options,
			report_type: value,
			selectedStepTwoOptions: []
		});
	};


	onDateRangeChange = dateRange => {
		const { startDate, endDate } = dateRange;
		const parsedStartDate = startDate.format("YYYY-MM-DD");
		const parsedEndDate = endDate.format("YYYY-MM-DD");
		this.setState({ parsedStartDate, parsedEndDate });
	};

	handleStepTwoSelectedOptionsChange = selectedStepTwoOptions => {

		// console.log("Option 2 :", selectedStepTwoOptions);
		
		this.setState({ selectedStepTwoOptions });
	};

	handleReportGenerateClick = () => {
		const {
			report_type,
			selectedStepTwoOptions,
			parsedStartDate,
			parsedEndDate
		} = this.state;

		const selectedStepTwoOptionsValues = selectedStepTwoOptions.map(
			item => item.value
		);

		const { generateReportV2 } = this.props;

		generateReportV2(
			report_type,
			selectedStepTwoOptionsValues,
			parsedStartDate,
			parsedEndDate
		);
	};

	handleTabChange = selected_tab => {
		const { handleReportTabChange, getReports } = this.props;
		handleReportTabChange(selected_tab);
		if (selected_tab === "list-report") {
			getReports();
		}
	};

	render() {
		const { reports, selected_tab } = this.props;
		const {
			stepOneOptions,
			stepTwoOptions,
			report_type,
			parsedStartDate,
			parsedEndDate,
			selectedStepTwoOptions
		} = this.state;

		return (
			<div className="row">
				<div className="col-md-12">
					<Tabs
						activeKey={selected_tab}
						id="generate-report"
						onSelect={this.handleTabChange.bind(this)}
					>
						<Tab eventKey="list-report" title="Reports">
							<BootstrapTable
								keyField="id"
								data={reports}
								options={this.options}
								pagination
								search
							>
								<TableHeaderColumn dataField="report_name" dataSort>
									Name
								</TableHeaderColumn>
								<TableHeaderColumn dataField="start_date" dataSort>
									Start Date
								</TableHeaderColumn>
								<TableHeaderColumn dataField="end_date" dataSort>
									End Date
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="updated_at"
									dataSort
									dataFormat={this.updated_at_fromatter}
								>
									Updated At
								</TableHeaderColumn>
								<TableHeaderColumn dataField="report_status" dataSort>
									Status
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="report_url"
									dataFormat={this.download_formatter}
								>
									Download
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="_id"
									dataFormat={this.delete_report_fromatter}
								>
									Delete
								</TableHeaderColumn>
							</BootstrapTable>
						</Tab>
						<Tab eventKey="generate-report" title="New Report">
							<Row>
								<Col md={3}>
									<Row>
										<Col md={12} className="m-2">
											<Select
												placeholder="Select Report Type"
												options={stepOneOptions}
												onChange={this.handleReportTypeChangeListener.bind(
													this
												)}
											/>
										</Col>
										<Col md={12} className="m-2">
											<Select
												isDisabled={stepTwoOptions.length === 0}
												placeholder={
													report_type === ""
														? "Select Report Type"
														: report_type === "screen"
															? "Choose Screen"
															: "Choose Contents"
												}
												options={stepTwoOptions}
												isMulti={true}
												onChange={this.handleStepTwoSelectedOptionsChange.bind(
													this
												)}
												value={selectedStepTwoOptions}
											/>
										</Col>
										<Col md={12} className="m-2 text-center">
											{parsedStartDate} - {parsedEndDate}
										</Col>
									</Row>
								</Col>
								<Col md={6} className="text-center">
									<DateRange
										calendars={1}
										onInit={this.onDateRangeChange.bind(this)}
										onChange={this.onDateRangeChange.bind(this)}
									/>
								</Col>
								<Col md={3}>
									{selectedStepTwoOptions.length > 0 && (
										<Button
											onClick={this.handleReportGenerateClick.bind(this)}
											className="btn btn-success"
										>
											Generate Report
										</Button>
									)}
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="live-logs" title="Real Time Stats">
							<LiveLogStat />
						</Tab>
					</Tabs>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		contents: state.content.get_all_files_data,
		screens: state.screen.get_all_screen_data,
		reports: state.userReports.reports_array,
		selected_tab: state.userReports.selected_tab
	};
};

export default connect(
	mapStateToProps,
	{
		getAllScreens,
		getAllFiles,
		getReports,
		generateReportV2,
		handleReportTabChange,
		deleteReport
	}
)(Reports);
