import axios from 'axios';
import extra from '../utils/extra';
import {GET_ALL_SCREEN_COUNT, GET_ALL_PLAYLIST_COUNT, GET_ALL_INVANTORY_COUNT , GET_ALL_CONTENT_COUNT, GET_ALL_ACTIVE_SCREEN_COUNT, GET_ALL_LOCATIONS_COUNT, DASHBOARD_STATS} from './types';

export function get_all_screen_count(payload){
    return {
        type: GET_ALL_SCREEN_COUNT,
        payload
    }
}

export function get_all_playlist_count(payload){
    return {
        type: GET_ALL_PLAYLIST_COUNT,
        payload
    }
}

export function get_all_invantory_count(payload){
    return {
        type: GET_ALL_INVANTORY_COUNT,
        payload
    }
}

export function get_all_content_count(payload){
    return {
        type: GET_ALL_CONTENT_COUNT,
        payload
    }
}

export function get_all_active_screen_count(payload){
    return {
        type: GET_ALL_ACTIVE_SCREEN_COUNT,
        payload
    }
}

export function dashboardscreencount(){
    return (dispatch=>{
        return axios.get(extra.rootURL+'/api/dashboardscreencount', {headers: { authorization: localStorage.getItem('token')}}).then((response)=>{
            dispatch(get_all_screen_count(response.data));    
            }).catch((error)=>{
                console.log(error);
            });
        })

}

export function dashboardplaylistcount(){
    return (dispatch=>{        
            return axios.get(extra.rootURL+'/api/dashboardplaylistcount', {headers: { authorization: localStorage.getItem('token')}}).then((response)=>{
                dispatch(get_all_playlist_count(response.data));
            }).catch((error)=>{
                console.log(error);
            });
        })

}

export function dashboardinvantorycount(){
    return (dispatch=>{
            return axios.get(extra.rootURL+'/api/dashboardinvantorycount', {headers: { authorization: localStorage.getItem('token')}}).then((response)=>{
                dispatch(get_all_invantory_count(response.data));
            }).catch((error)=>{
                console.log(error);
            });
        })

}

export function dashboardcontentcount(){
    return (dispatch=>{        
            return axios.get(extra.rootURL+'/api/dashboardcontentcount', {headers: { authorization: localStorage.getItem('token')}}).then((response)=>{              
                dispatch(get_all_content_count(response.data));                
            }).catch((error)=>{
                console.log(error);
            });
        })
}

export function dashboardactivescreencount(){
    return (dispatch=>{
        return axios.get(extra.rootURL+'/api/dashboardactivescreencount', {headers: { authorization: localStorage.getItem('token')}}).then((response)=>{
            dispatch(get_all_active_screen_count(response.data));    
            }).catch((error)=>{
                console.log(error);
            });
        })

}

export const dashboardLocationsCount = () => dispatch => {
    axios.get(`${extra.rootURL}/api/dashboardLocationsCount`,{
        headers : {
            authorization: localStorage.getItem("token")
        }
    })
    .then(response => {
        dispatch({
            type: GET_ALL_LOCATIONS_COUNT,
            payload : response.data.locationCount
        })
    })
    .catch(err => console.log(err));
}

export const dashboardStats = () => async dispatch => {
    const response = await axios.get(`${extra.rootURL}/api/dashboardStats`, {
        headers : {
            authorization : localStorage.getItem("token")
        }
    });

    // console.log("Dash", response.data);

    dispatch({
        type : DASHBOARD_STATS,
        payload : response.data
    })
}