import { SET_ADVERTISER_CAMPAIGN, SET_MANAGE_SCREEN_DATA, CAMPAIGN_FOR_INVOICE } from '../actions/types';

export default (state = {
  campaign_list: [],
  manage_screen_data: '',
  campaignForInvoice : []
}, action = {}) => {
  switch (action.type) {
    case SET_ADVERTISER_CAMPAIGN:
      return Object.assign({}, state, {
        campaign_list: action.payload,
      });
    case SET_MANAGE_SCREEN_DATA:
      return Object.assign({}, state, {
        manage_screen_data: action.payload
      })

    case CAMPAIGN_FOR_INVOICE :
      return {
        ...state,
        campaignForInvoice : action.payload
      }
    default: return state;
  }
}