import React, { Component } from 'react';
import TextFieldGroup from '../common/TextFieldGroup';
import validateInput from '../../shared/validations/login';
import { connect } from 'react-redux';
import { login } from '../../actions/authActions';
import AlertContainer from 'react-alert';
import { browserHistory } from 'react-router';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      role: '',
      errors: '',
      loginError: false,
      isLoading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  isValid() {
    const { isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors: '' });
      this.msg.show('Check Input Values', {
        time: 2000,
        type: 'error'
      })
    }

    return isValid;
  }

  alertOptions = {
    offset: 14,
    position: 'bottom left',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ errors: '', isLoading: true });
      this.props.login(this.state).then(
        (res) => {
          browserHistory.push('/dashboard')
        },
        (err) => {
          console.log(err.message);
          this.setState({ errors: err.message, isLoading: false })
          if (err.message === "Request failed with status code 401") {
            this.msg.show("Check Email or Password", { type: "error" });
          }
          if (err.message === "Network Error") {
            this.msg.show("Check Your Internet Connection", { type: "error" });
          }
        })
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors, email, password, isLoading } = this.state;
    return (

      <form onSubmit={this.onSubmit}>
        {errors.form && <div className="alert alert-danger">{errors.form}</div>}

        <TextFieldGroup
          field="email"
          label="Email"
          value={email}
          error={errors.email}
          onChange={this.onChange}
        />



        <TextFieldGroup
          field="password"
          label="Password"
          value={password}
          error={errors.password}
          onChange={this.onChange}
          type="password"
        />
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
        <div className="form-group"><button className="btn btn-raised btn-info btn-lg" disabled={isLoading}>Login</button></div>
      </form>
    );
  }
}

export default connect(null, { login })(LoginForm);
