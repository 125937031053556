import React, { Component } from 'react';
import { Modal, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { uniqBy } from 'lodash';
import AlertContainer from 'react-alert';
import { createAdvertiserCampaign, fetchAdvertiserCampaign, updateAdvertiserCampaign } from '../../actions/campaignActions';

var myDate = new Date();
var date = myDate.getDate();
var month = myDate.getMonth();
var year = myDate.getFullYear();
var hour = myDate.getHours();
var minute = myDate.getMinutes();

function pad(n) {
    return n < 10 ? '0' + n : n
}

var formated_date = year + "-" + pad(month + 1) + "-" + pad(date) + "T" + pad(hour) + ":" + pad(minute);


class CreateCampaignFromInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            campaignName: '',
            startDate: formated_date,
            endDate: formated_date,
            selectedScreens: new Set(),
            selectedUpdateScreens : [],
            campaignNameType: "text",
            campaignDropDownIndex : 0
        }
    }

    componentWillMount(){
        this.props.fetchAdvertiserCampaign();
    }

    openModal() {
        this.setState({ showModal: true, campaignName: '', startDate: formated_date, endDate: formated_date, selectedPlaylists: new Set(), selectedScreens: new Set() });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    handleCampaignNameType(campaignNameType) {
        this.setState({ campaignNameType: campaignNameType, selectedScreens: [] })
    }

    handleCampaignNameChange(e) {
        this.setState({ campaignName: e.target.value })
    }

    handleStartDateChange(e) {
        this.setState({ startDate: e.target.value });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e.target.value });
    }

    handleScreenSelected(item, index) {
        this.setState({ selectedScreens: this.state.selectedScreens.add(item) });
    }

    handleCampaignDropDownChange(e){
        let index = e.target.value;
        if(index==="-1"){
            this.setState({selectedScreens: []});
        } else {
            let previous_screens = this.props.campaign_list[index].screens;
            let current_screens = Array.from(this.props.cart).map(item=>{return item.screen});
            let temp = previous_screens.concat(current_screens);
            let final_set_screeens = uniqBy(temp, (item)=>{
                return item._id;
            });
            this.setState({selectedUpdateScreens : final_set_screeens , campaignDropDownIndex: index})
        }
    }

    onCreateSubmit(e) {
        e.preventDefault();
        if (this.state.campaignName === "") {
            this.msg.show("Check Campaign Name");
        } else {
            // This is an Important Line that we need to fix 
            let current_screens = Array.from(this.props.cart).map(item=>{return item._id});

            this.props.createAdvertiserCampaign(this.state.campaignName, this.state.startDate, this.state.endDate, current_screens).then(() => {
                this.closeModal();
                this.props.fetchAdvertiserCampaign();
                browserHistory.push("/campaigns");
            });
        }
    }

    onUpdateSubmit(e) {
        e.preventDefault();
        let campaign_data = this.props.campaign_list[this.state.campaignDropDownIndex]
        campaign_data.screens = this.state.selectedUpdateScreens;
        this.props.updateAdvertiserCampaign(campaign_data).then(() => {
            this.closeModal();
            this.props.fetchAdvertiserCampaign();
            browserHistory.push("/campaigns");
        });
    }

    render() {

        let cart_array = Array.from(this.props.cart);
        return (
            <div>
                {this.props.cart_size === 0 ? null : <button onClick={this.openModal.bind(this)} type="button" className="btn btn-primary">Checkout and Create Campaign &nbsp;<span className="badge">{this.props.cart_size}</span></button>}
                <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)}>
                    <Modal.Header className="bg-success">
                        <Modal.Title>
                            <div className="text-center mtb-0">Enter details to create a Campaign</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <FormGroup>
                                {this.state.campaignNameType === "text" ? <btn className="btn btn-block btn-primary" onClick={this.handleCampaignNameType.bind(this, "dropdown")}>Choose Existing Campaign</btn> : null}
                                {this.state.campaignNameType === "dropdown" ? <btn className="btn btn-block btn-primary" onClick={this.handleCampaignNameType.bind(this, "text")}>Enter Campaign Name</btn> : null}
                            </FormGroup>
                            {this.state.campaignNameType === "text" ?
                                <FormGroup>
                                    <ControlLabel>Campaign Name</ControlLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.campaignName}
                                        placeholder="Enter Campaign Name"
                                        onChange={this.handleCampaignNameChange.bind(this)}
                                    />
                                </FormGroup> : null
                            }
                            {this.state.campaignNameType === "dropdown" ?
                                <FormGroup>
                                    <ControlLabel>Choose Campaign</ControlLabel>
                                    <FormControl
                                        componentClass="select" onChange={this.handleCampaignDropDownChange.bind(this)}>
                                        <option value={-1}>Choose Campaign</option>
                                        {this.props.campaign_list ? this.props.campaign_list.map((item, index) => (
                                            <option value={index} key={index} >{item.campaignName}</option>
                                        )) : <option>No Campaign</option>}
                                    </FormControl>
                                </FormGroup> : null
                            }
                            { this.state.campaignNameType === "text" ? <FormGroup>
                                <ControlLabel>Start Date</ControlLabel>
                                <FormControl
                                    type="datetime-local"
                                    value={this.state.startDate}
                                    onChange={this.handleStartDateChange.bind(this)}
                                />
                            </FormGroup> : null}
                            { this.state.campaignNameType === "text" ? <FormGroup>
                                <ControlLabel>End Date</ControlLabel>
                                <FormControl
                                    type="datetime-local"
                                    value={this.state.endDate}
                                    onChange={this.handleEndDateChange.bind(this)}
                                />
                            </FormGroup>: null}
                            <FormGroup>
                                <ControlLabel>Selected Screen</ControlLabel>
                                {this.state.campaignNameType === "text" ? <FormControl componentClass="select" multiple>
                                    {cart_array.map((item, index) => (
                                        <option disabled key={index} >{item.screen_name}</option>
                                    ))}
                                </FormControl> : null}
                                {this.state.campaignNameType === "dropdown" ? <FormControl componentClass="select" multiple>
                                    {this.state.selectedUpdateScreens ? this.state.selectedUpdateScreens.map((item, index) => (
                                        <option disabled key={index} >{item.screen_name}</option>
                                    )) : <option>No Screens</option>}
                                </FormControl> : null}
                            </FormGroup>
                            <FormGroup>
                                {this.state.campaignNameType === "text" ? <button type="button" className="col-md-4 btn btn-success" onClick={this.onCreateSubmit.bind(this)}>Create</button> : null}
                                {this.state.campaignNameType === "dropdown" ? <button type="button" className="col-md-4 btn btn-success" onClick={this.onUpdateSubmit.bind(this)}>Update</button> : null}
                                <button type="button" className="col-md-offset-4 col-md-4 btn btn-danger" onClick={this.closeModal.bind(this)}>Cancel</button>
                            </FormGroup>
                        </form>
                    </Modal.Body>
                    {/* Adding alert i was not working */}
                    <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        cart: state.inventory.cart,
        cart_size: state.inventory.cart.size,
        campaign_list: state.campaign.campaign_list
    }
}


export default connect(mapStateToProps, { createAdvertiserCampaign, fetchAdvertiserCampaign, updateAdvertiserCampaign })(CreateCampaignFromInventory);