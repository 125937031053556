import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router";
import { fetchAdvertiserCampaign, manageScreenData } from '../../actions/campaignActions';
import RemoveCampaign from './RemoveCampaign';
import UpdateCampaign from './UpdateCampaign';

class CampaignTable extends Component {
    componentDidMount() {
        this.props.fetchAdvertiserCampaign();
    }

    handleManageScreenClick(item, e){
        this.props.manageScreenData(item);
    }

    invoice_formatter = (row) => {
		return (
			<Link
				to={`${row.invoiceURL}`}
				target="_blank"
				className={`btn btn-success ${row.invoiceURL !== "" ? "" : "disabled"}`}
			>
				<i className="fa fa-download" />
			</Link>
		);
	};

    render() {
        return (
            <div className="table-width mb-20">
                <table className="table mrgn table-hover table-bordered">
                    <thead>
                        <tr className="bg-success">
                            <th className="text-center">Campaign Name</th>
                            <th className="text-center">Start Date</th>
                            <th className="text-center">End Date</th>
                            <th className="text-center">Manage</th>
                            <th className="text-center">Invoice</th>
                            <th className="text-center">Update</th>
                            <th className="text-center">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.campaign_list ? this.props.campaign_list.map((item, index) => (
                            <tr key={index} className="table-info fcolor-black">
                                <td className="text-center">{item.campaignName}</td>
                                <td className="text-center">{new Date(item.startDate).toLocaleDateString()}</td>
                                <td className="text-center">{new Date(item.endDate).toLocaleDateString()}</td>
                                <td><button type="button" className="auto_margin2 btn btn-primary" onClick={this.handleManageScreenClick.bind(this, item)}><i className="fa fa-tasks"></i></button></td>
                                <td>{this.invoice_formatter(item)}</td>
                                <td><UpdateCampaign campaign_data={item}/></td>
                                <td><RemoveCampaign campaign_data={item}/></td>
                            </tr>
                        )) : <tr>
                                <td>No data Please Add Campaign</td>
                            </tr>}

                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        campaign_list: state.campaign.campaign_list
    }
}, { fetchAdvertiserCampaign, manageScreenData })(CampaignTable);