import React, {Component} from 'react';
import LoginForm from './LoginForm';

class LoginPage extends Component {

  render() {
    return (
      <div className="login_background login-page">
          <div className="row">
            <div className="text-center col-md-12">
              <div className="custom-text-color">
                <h2 className="p-10">Login To Your <img height="32" alt="screeno" src="img/screeno_logo_design.png"/> Account</h2>
              </div>
            </div>
          </div>

          <div className="row mt-15">
            <div className="col-md-4 display-none hidden-sm-down">
              <img className="img-responsive center-block" alt="" src="img/loginPage/ad1.png"/>
            </div>
            <div className="col-md-4 p-10 custom-form">
                <LoginForm />
              </div>
              <div className="col-md-4 hidden-sm-down">
              <img className="img-responsive center-block" alt="" src="img/loginPage/twitter.png"/>
            </div>
          </div>

          <div className="row mt-15 hidden-sm-down">
            <div className="col-md-4">
              <img className="img-responsive center-block" alt="" src="img/loginPage/fb.png"/>
            </div>
            <div className="col-md-4">
              <img className="img-responsive center-block" alt="" src="img/loginPage/weather.png"/>
            </div>
            <div className="col-md-4">
              <img className="img-responsive center-block" alt="" src="img/loginPage/ad2.png"/>
            </div>
          </div>         
     
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-4"></div>
              <div className="col-md-4 center-align2 text-white">
                <h5 className="mtb-0">&copy; 2020 Sector 6 Technologies LLP LOCAD PTE .LTD. 
                  <img className="pull-right" height="40" alt="screeno" src="img/locad-seal.png"/>
                  <br/>All rights reserved
                </h5>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
    </div>
    );
  }
}

export default LoginPage;
