import React ,{Component} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {getAllPlayLists, removePlayList} from '../../actions/playlistActions';
// import extra from '../../utils/extra';
import mqttClient from "../../utils/mqtt_config";

 class ShowModal extends Component{
    constructor(props){
        super(props);
        this.state={
             modal: false,
             modalData:''
        }
    }

    handleScreenRemove(){
        let playlist_name= this.props.modalData;
        this.props.removePlayList(playlist_name).then(()=>{
            // extra.socket.emit("from_user_to_api", {data: {user_id: this.props.user_id, screen_name: this.props.screen_name, cmd:"refresh"}});            
            mqttClient.publish(`cmd_${this.props.screen_name}_${this.props.user_id}`, JSON.stringify({"message":{"cmd": "refresh"}} ));
            this.props.getAllPlayLists();
            this.close();
        });
    }

    show(){
        this.setState({modal: true})
    }

    close(){
        this.setState({modal: false});
    }

    render(){
        return(
                <div>
                <button className="auto_margin2 btn btn-danger" onClick={this.show.bind(this)}>
                    <i className="fa fa-trash"></i>
                    </button>
                    <Modal show={this.state.modal} onHide={this.close.bind(this)}>
                        <Modal.Body>
                             <h4>
                                <img alt="alertfordelete" src="img/warning-sign.png" /> &nbsp;
                                  Are you sure that you want to delete this item?</h4>
                                   <div>
                                 <div className="col-md-2"></div>
                                 <div className="col-md-2"></div>
                                 <div className="col-md-2">
                            <button className="btn btn-danger" 
                            onClick={this.handleScreenRemove.bind(this)}>
                            <i className="glyphicon glyphicon-ok"></i>
                            </button>     
                               </div>
                            <div className="col-md-2">   
                            <button type="button" className="btn btn-warning pull-right" 
                            onClick={this.close.bind(this)}>
                             <i className="glyphicon glyphicon-remove"></i>
                            </button>
                             </div>
                            </div>
                       </Modal.Body> 
                    </Modal>
                </div>
        )
    }
}

function mapStateToProps(state){
    return {
        user_id: state.auth.user.token.sub,
    }
}

export default connect(mapStateToProps, {getAllPlayLists, removePlayList})( ShowModal);