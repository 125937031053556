export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const NEW_SCREEN_ADDED = 'NEW_SCREEN_ADDED';
export const GET_ALL_SCREEN = 'GET_ALL_SCREEN';
export const GET_ALL_FILES = 'GET_ALL_FILES';
export const NEW_FILE_ADDED = 'NEW_FILE_ADDED';
export const NEW_PLAYLIST_CREATED = 'NEW_PLAYLIST_CREATED';
export const GET_ALL_PLAYLISTS = 'GET_ALL_PLAYLISTS';
export const GET_ALL_INVENTORY_SCREENS = 'GET_ALL_INVENTORY_SCREENS';
export const GET_FILTERED_INVENTORY = 'GET_FILTERED_INVENTORY';
export const MARKER_CLICKED = 'MARKER_CLICKED';
export const GET_ALL_SCREEN_COUNT = 'GET_ALL_SCREEN_COUNT';
export const GET_ALL_PLAYLIST_COUNT = 'GET_ALL_PLAYLIST_COUNT';
export const GET_ALL_INVANTORY_COUNT = 'GET_ALL_INVANTORY_COUNT';
export const GET_ALL_CONTENT_COUNT = 'GET_ALL_CONTENT_COUNT';
export const GET_ALL_LOCATIONS_COUNT = 'GET_ALL_LOCATIONS_COUNT';
export const GET_ALL_ACTIVE_SCREEN_COUNT = 'GET_ALL_ACTIVE_SCREEN_COUNT';
export const GET_ALL_USER_INVENTORY_SCREENS='GET_ALL_USER_INVENTORY_SCREENS';
export const GET_ALL_CAB_SCREEN='GET_ALL_CAB_SCREEN';
export const UPLOAD_BULK_SCREENS='UPLOAD_BULK_SCREENS';
export const GET_ALL_APP_DETAILS='GET_ALL_APP_DETAILS';
export const GET_ALL_SCREENSHOT ='GET_ALL_SCREENSHOT';
export const SET_ADVERTISERS_USER_SCREEN = 'SET_ADVERTISERS_USER_SCREEN';
export const SET_ADVERTISER_CAMPAIGN = 'SET_ADVERTISER_CAMPAIGN';
export const SET_MANAGE_SCREEN_DATA = 'SET_MANAGE_SCREEN_DATA';
export const ADD_TO_CART = 'ADD_TO_CART';
export const GET_ALL_APPROVAL_PLAYLISTS = 'GET_ALL_APPROVAL_PLAYLISTS';
export const MSG = 'MSG';
export const PROGRESS_PERCENT = 'PROGRESS_PERCENT';

export const GET_REPORTS = "GET_REPORTS";
export const GET_ERRORS = "GET_ERRORS";
export const GET_LIVE_LOGS_REPORT = 'GET_LIVE_LOGS_REPORT';
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_CLIENTS = "GET_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const PLAYLIST_SYNC_UPDATE = "PLAYLIST_SYNC_UPDATE";
export const INVENTORY_ENTRY_UPDATE = "INVENTORY_ENTRY_UPDATE";
export const DASHBOARD_STATS = 'DASHBOARD_STATS';
export const SET_INVENTORY_FILTER_OPTIONS = 'SET_INVENTORY_FILTER_OPTIONS';
export const SET_FILTER_LOCATION_STRUCT = 'SET_FILTER_LOCATION_STRUCT';
export const FETCH_INVENTORY_FILTER_OPTIONS = 'FETCH_INVENTORY_FILTER_OPTIONS';
export const SET_CITY_FILTER_OPTIONS = 'SET_CITY_FILTER_OPTIONS';
export const SET_STATE_FILTER_OPTIONS = 'SET_STATE_FILTER_OPTIONS';

export const CAMPAIGN_FOR_INVOICE = 'CAMPAIGN_FOR_INVOICE';

export const SET_SCREEN_CREATIVE_LIST = 'SET_SCREEN_CREATIVE_LIST';
export const SET_CREATIVE_SCREEN_LIST = 'SET_CREATIVE_SCREEN_LIST';

export const UPDATE_SCREEN_IMPRESSION_COUNT = "UPDATE_SCREEN_IMPRESSION_COUNT";
export const UPDATE_CREATIVE_IMPRESSION_COUNT = "UPDATE_CREATIVE_IMPRESSION_COUNT";

export const UPDATE_CREATIVE_COUNT_IN_SCREEN = 'UPDATE_CREATIVE_COUNT_IN_SCREEN';