import React, { Component } from 'react'

class DemoTest extends Component {
  render() {

    console.log("refs :", this.refs);
    return (
      <div>

        <h1 ref="h1">H1 tag</h1>
        <h2 ref="h2">H2 tag</h2>
      </div>
    )
  }
}
export default DemoTest;
