import { NEW_SCREEN_ADDED, GET_ALL_SCREEN, MSG } from '../actions/types';

export default (state = {
    new_screen_added: false,
    new_pairing_code: '',
    get_all_screen_data: [],
    msg:''
}, action = {}) => {
  switch(action.type) {
    case NEW_SCREEN_ADDED:
      return Object.assign({}, state, {
        new_screen_added:true,
        new_pairing_code: action.payload
      });
    case GET_ALL_SCREEN:
        return Object.assign({}, state, {
            get_all_screen_data: action.payload
        });
    case MSG: 
            return Object.assign({}, state, {
                msg: action.payload
        });
    default: return state;
  }
}