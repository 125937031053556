import React from 'react';
import classnames from 'classnames';

const TextFieldGroup = ({ field, value, label, error, type, onChange, checkUserExists }) => {
  return (
    <div className={classnames('form-group label-floating', { 'has-error': error })}>
      <label className="" htmlFor="addon2">{label}</label>
      <div className="input-group">  
        <input
          onChange={onChange}
          onBlur={checkUserExists}
          value={value}
          type={type}
          name={field}
          className="form-control"
        />
      </div>
    {error && <span className="help-block">{error}</span>}
    </div>  );
}

export default TextFieldGroup;
