import React from 'react';
import { Modal } from 'react-bootstrap';
import { getApprovalPlaylists } from "../../actions/playlistActions"
import { approvePlaylist } from "../../actions/approvePlaylistAction"
import { disapprovePlaylist } from "../../actions/approvePlaylistAction"
import mqttClient from "../../utils/mqtt_config";

import { connect } from 'react-redux'

class ShowPlaylistContent extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleDisapprove = this.handleDisapprove.bind(this);

    this.state = {
      show: false,
      msg: ""
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleApprove(e) {
    e.preventDefault();
    approvePlaylist(this.props.playlist._id, this.state.msg).then(() => {
      this.setState({ show: false });
      
      let topic = `cmd_${this.props.playlist.screen_name}_${this.props.userId}`;
      console.log(topic)
      mqttClient.publish(topic, JSON.stringify({"message":{"cmd": "refresh"}}));
      this.props.getApprovalPlaylists();
    });
  }

  handleDisapprove(e) {
    e.preventDefault();
    disapprovePlaylist(this.props.playlist._id, this.state.msg).then(() => {
      this.setState({ show: false });
      
      let topic = `cmd_${this.props.playlist.screen_name}_${this.props.userId}`;
      mqttClient.publish(topic, JSON.stringify({"message":{"cmd": "refresh"}}));
      this.props.getApprovalPlaylists();
    });
  }

  handleShow() {
    this.setState({ show: true });
  }

  statusButtons() {
    if (this.props.playlist.is_approved === "Pending") {
      return (
        <div className="row">
          <div className="col-md-4">
            <button onClick={this.handleApprove} className="btn btn-block btn-success">Approve</button>
          </div>
          <div className="col-md-4 offset-md-4">
            <button type="button" onClick={this.handleDisapprove} className="btn btn-block btn-danger">Disapprove</button>
          </div>
        </div>
      );
    } else if(this.props.playlist.is_approved === "Approved"){
      return (
        <div className="row">
          <div className="col-md-4">
            <button type="button" onClick={this.handleDisapprove} className="btn btn-block btn-danger">Disapprove</button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-md-4">
            <button onClick={this.handleApprove} className="btn btn-block btn-success">Approve</button>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <button className="btn btn-primary" onClick={this.handleShow}>
          Edit
          </button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header className="bg-success">
            <Modal.Title>{this.props.playlist.playlist_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label htmlFor="message"></label>
                <textarea onChange={(e) => this.setState({ msg: e.target.value })} id="message" className="form-control" placeholder="Please Add some comments"></textarea>
              </div>
              {this.statusButtons()}
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(null, { getApprovalPlaylists })(ShowPlaylistContent);