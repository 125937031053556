import React, { Component } from "react";
import { Modal } from "react-bootstrap";
// import extra from '../../utils/extra';

export default class ShowContentModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
		};
	}

	componentWillMount() {
		const { modaldata } = this.props;
		this.setState({ modaldata: {} }, () => {
			this.setState({ modaldata });
		});
	}

	componentWillReceiveProps(nextProps) {
		const { modaldata } = nextProps;
		this.setState({ modaldata: {} }, () => {
			this.setState({ modaldata });
		});
	}

	show() {
		this.setState({ modal: true });
	}

	close() {
		this.setState({ modal: false });
	}

	render() {
		const { modaldata } = this.state;
		if (modaldata) {
			const { file_type, file_url } = modaldata;
			return (
				<div>
					{file_type === "image/jpeg" ||
					file_type === "image/jpg" ||
					file_type === "image/png" ? (
						<img
							className="object-fit-cover pointer custom-thumbnail"
							alt="preview"
							src={file_url}
							onClick={this.show.bind(this)}
						/>
					) : (
						<div></div>
					)}
					{file_type === "video/mp4" ? (
						<video
							className="pointer videothumbnail"
							muted={this.props.muted}
							onClick={this.show.bind(this)}
						>
							<source src={file_url} />
						</video>
					) : (
						<div></div>
					)}
					{file_type === "html" ? <div>URL</div> : <div></div>}

					{file_type !== "html" ? (
						<Modal
							className="showContent_modal"
							show={this.state.modal}
							onHide={this.close.bind(this)}
						>
							<Modal.Body className="padding_zero">
								{file_type === "image/jpeg" ||
								file_type === "image/jpg" ||
								file_type === "image/png" ? (
									<img
										className="showContent_modal_img responsive100pcnt"
										alt="preview"
										src={file_url}
									/>
								) : (
									<div></div>
								)}
								{file_type === "video/mp4" ? (
									<video
										className="showContent_modal_img"
										loop
										muted={this.props.muted}
										controls="true"
										autoPlay="true"
									>
										<source src={file_url} />
									</video>
								) : (
									<div></div>
								)}
							</Modal.Body>
							<button
								type="button"
								className="btn center-align2 btn-warning mt-15"
								onClick={this.close.bind(this)}
							>
								<i className="glyphicon glyphicon-remove"></i>
							</button>
						</Modal>
					) : null}
				</div>
			);
		} else {
			return <div></div>;
		}
	}
}
